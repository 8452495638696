<template lang="pug">
section(class=['patient-card', 'profile-card'] :class="classes")
  .row.align-items-center.g-4.gy-xl-0
    .col-1
      span.idDoc {{data.exams_data_id}}
    .d-none.d-xl-block.col-2
      span(v-if="data.created_at !== null") {{$moment(data.created_at).format('DD.MM.YYYY')}}&nbsp;
      span(v-if="data.created_at !== null") {{$moment(data.created_at).format('HH:mm:ss')}}
      span(v-else) &#8212;
    .col-auto.col-2
      span(v-if="data.doc_type && data.doc_type === 'med'") Справка
      span(v-if="data.doc_type && data.doc_type === 'alco'") Направление на МО

    .col-14.col-md-2.col-xl-2
      span.word-wrap {{data.doctor_full_name}}

    .col-7.col-md-3.col-xl-3
      span.word-wrap {{data.patient_full_name}}
    .col-7.col-md-2.col-xl-4
      .d-flex.align-items-center
        .d-flex.align-items-center
          span {{data.pre_diagnosis}}

        span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='download(data.link_files)')
          span
            img(src="@/assets/img/notice.svg" )

</template>

<script>
import {mapState} from 'vuex';

import {toLocalDate, declOfNum} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';

export default {
  name: 'PatientNotice',
  components: {
    AppAvatar,
    AppSvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    download(urls) {
      console.log(urls);
      console.log(this.settings.s3_host);
      for (let i = 0; i < urls.length; i++) {
        if (urls[i]) {
          var frame = document.createElement('iframe');
          frame.src = `${this.settings.s3_host}${urls[i]}`;
          frame['download'] = 1;
          document.body.appendChild(frame);
          // window.open(frame.src);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.idDoc {
  min-width: 30px;
  display: block;
}
.patient-card {
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
.pointer {
  cursor: pointer;
}
// iframe{
//   display: none;
// }
</style>
