<template lang="pug">
  .profile-exams__inner.is-filterable.w-100(:class="{'filter-is-open': isFilterOpen}")
    .profile-exams__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="loading || $store.state.chart.loading")
        app-error-message(v-if="error" :message="error")
        div(v-if="exams")
          template(v-if="$store.state.chart.chartTable.total>0")
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table.is-exams-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      v-if="column.caption || column.icon"
                      type="button"
                      @click="sortingExams(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span.flex-shrink-0(v-else-if="column.icon")
                          app-svg-icon(:name="column.icon")
                        span.flex-shrink-0.d-flex.ms-3
                          app-svg-icon(name="down-arrow")


              ul.profile-table__body(id="client-exams")
                li.profile-table__row(v-for="exam in exams.result" :key="exam.id")
                  app-exam-card(
                    :data="exam"
                    :parent-id="'client-exams'"
                    :columns="columns"
                    :classes="'d-none d-xl-block'"
                    @openModal="openExamDetailModal"
                    @openCertificate="openCertificate"
                    @openGallery="openGallery"
                  )
                  app-exam-card-mobile(
                    :data="exam"
                    :classes="'d-xl-none'"
                    @openModal="openExamDetailMobile"
                    @openGallery="openGallery"
                  )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="$store.state.chart.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )
          template(v-else)
            .text-color--blue.text-size--small Осмотров не найдено



    //- // filter
    //- app-filter(
    //-   ref="filter"
    //-   :options="filterOptions"
    //-   :is-open="isFilterOpen"
    //-   :is-filtered="isFiltered"
    //-   :count="examsCount"
    //-   @open="openFilter"
    //-   @close="closeFilter"
    //-   @clear="clearFilter"
    //-   @submit="submitFilter"
    //-   @getCount="getCountFilter"
    //- )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
//import {createLogger, mapGetters, mapState} from 'vuex';
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import is from 'is_js';
import variables from '@/helpers/variables';
import {blobToFile} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/profile/common/Filter';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';
import {filterMixin} from '@/mixins/filter';

export default {
  name: 'AppClientExams',
  components: {
    AppTitle,
    AppSvgIcon,
    AppGallery,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppSelect,
    AppFilter,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin, filterMixin],
  data() {
    return {
      loading:false,
      v: variables,
      filterOptions: {
        patients: true,
        examParams: true,
        date: true,
        time: true,
        bodyParams: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате',
          value: 'exam_date_time',
          selected: true,
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          value: 'patient_full_name',
          selected: false,
          inverse: false,
        },
         {
          caption: 'Тип осмотора',
          value: 'exam_type_id',
          selected: false,
          inverse: true,
        },
        {
          caption: 'давлению',
          value: 'bp_high',
          selected: false,
          inverse: true,
        },
        {
          caption: 'пульсу',
          value: 'heart_rate',
          selected: false,
          inverse: true,
        },
        {
          caption: 'температуре',
          value: 'body_temp',
          selected: false,
          inverse: true,
        },
        {
          caption: 'наличию алкоголя',
          value: 'alcohol',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
         {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-1',
          key: 'health_complaint'
        },
        {
          class: 'col-2',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.exams.isLoading,
      exams: (state) => state.chart.chartTable,
      examsCount: (state) => state.exams.count,
      error: (state) => state.exams.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [this.currentUser.id],
          },
          patients: {
            negative: this.$store.state.chart.chartFilter.filters.patients.negative,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    async currentPage() {
             const data = {
                    "filters": {
                        'date_from': this.$store.state.chart.chartFilter.filters.date_from,
                        'date_to':  this.$store.state.chart.chartFilter.filters.date_to,
                        'time_from': this.$store.state.chart.chartFilter.filters.time_from,
                        'time_to': this.$store.state.chart.chartFilter.filters.time_to,
                        'type': this.$store.state.chart.chartFilter.filters.type,
                        'admission_to_work': this.$store.state.chart.chartFilter.filters.admission_to_work,
                        "users": {
                            "negative": false,
                            "payload": this.$store.state.chart.chartFilter.filters.users.payload,
                        },
                        "patients": {
                            "negative": this.$store.state.chart.chartFilter.filters.patients.negative,
                            "payload":this.$store.state.chart.chartFilter.filters.patients.payload
                        }
                    },
                    "paging_params": {
                        "limit": this.pagination.limit,
                        "offset": this.offset
                    },
                    "sorting_params": {
                        "desc": false,
                        "field": ""
                    }
                }


        this.loading = true;
        //await this.$store.dispatch("actionGetDiagramPressuareTable", data);
        if(this.$store.state.chart.chartFilter.filters.type){
          await this.$store.dispatch("actionGetDiagramPressuareTable", data);
        } else if(this.$store.state.chart.chartFilter.filters.admission_to_work){
          await this.$store.dispatch("actionGetChartDataTable", data);
        } else {
           await this.$store.dispatch("actionGetChartData", data);
        }

        this.loading = false;


     // this.getExams(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
   // this.getExams(this.filters, this.pagination.limit, this.offset);
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
   async getExams() {

         const data = {
                    "filters": {
                        'date_from': this.$store.state.chart.chartFilter.filters.date_from,
                        'date_to':  this.$store.state.chart.chartFilter.filters.date_to,
                        'time_from': this.$store.state.chart.chartFilter.filters.time_from,
                        'time_to': this.$store.state.chart.chartFilter.filters.time_to,
                        'type': this.$store.state.chart.chartFilter.filters.type,
                        'admission_to_work': this.$store.state.chart.chartFilter.filters.admission_to_work,
                        "users": {
                            "negative": false,
                            "payload": this.$store.state.chart.chartFilter.filters.users.payload,
                        },
                        "patients": {
                            "negative": this.$store.state.chart.chartFilter.filters.patients.negative,
                            "payload":this.$store.state.chart.chartFilter.filters.patients.payload
                        }
                    },
                    "paging_params": {
                        "limit": this.pagination.limit,
                        "offset": this.offset
                    },
                    "sorting_params": {
                        "desc": this.settings.sorting_params.desc,
                        "field": this.settings.sorting_params.field=='exam_type_name'?'exam_type_id':this.settings.sorting_params.field
                    }
                }


        this.loading = true;
        //await this.$store.dispatch("actionGetDiagramPressuareTable", data);
        // if(this.$store.state.chart.chartFilter.filters.type){
        //   await this.$store.dispatch("actionGetDiagramPressuareTable", data);
        // } else {
        //    await this.$store.dispatch("actionGetChartData", data);
        // }

         if(this.$store.state.chart.chartFilter.filters.type){
          await this.$store.dispatch("actionGetDiagramPressuareTable", data);
        } else if(this.$store.state.chart.chartFilter.filters.admission_to_work){
          await this.$store.dispatch("actionGetChartDataTable", data);
        } else {
           await this.$store.dispatch("actionGetChartData", data);
        }

        this.loading = false;



      console.log('sprt',this.settings.sorting_params.desc)
        console.log('sprt',this.settings.sorting_params.field)

      //  if (this.isTouchDevice) {
      //     this.$refs.filter.setBtnPosition();
      //     this.closeFilter('submit');
      //   }
       // this.pagination.total = response.total;
        //this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();

    },
    getCountExams(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    exportExams(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(examsActionTypes.exportExams, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    exportExams835(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(examsActionTypes.exportExams835, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingExams(value, inverse);
    },
    sortingExams(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.profile-exams {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
