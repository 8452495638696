<template lang="pug">
  .multiple-select(v-click-outside="close")
    .multiple-select__search(:class="[{'is-open': isOpen}, {'is-disabled': !searchDataLocal.length}]")
      label.multiple-select__search-label.d-flex.align-items-center
        template(v-if="searchDataLocal.length")
          button(
            type="button"
            @click="close"
            class=['d-flex']
          )
            app-svg-icon(name="plus-thin")
          input(
            v-if="multiple===false && initialValues.length > 0"
            type="text"
            :title="placeholder"
            class=['multiple-select__search-field', 'mx-3', 'flex-grow-1']
            :placeholder="placeholder"
            v-model="selected[0].text"
            @input="search"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          )
          input(
            v-else
            type="text"
            :title="placeholder"
            class=['multiple-select__search-field', 'mx-3', 'flex-grow-1']
            :placeholder="placeholder"
            v-model="searchInput"
            @input="search"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          )
          span.d-flex
            app-svg-icon(name="search")

        .text-size--small(v-else) У выбранных клиентов нет пользователей

      transition(name="fade")
        .multiple-select__search-dropdown(v-if="isOpen")
          simplebar(:click-on-track="false" class="multiple-select__search-dropdown__inner")
            ul.multiple-select__search-dropdown-list(v-if="filtered.length")
              li.multiple-select__search-dropdown-list-el.d-flex.align-items-center(
                :class="{'is-selected': option.selected}"
                v-for="(option, index) in filtered" :key="index"
                @click="toggleSelect(option)"
              )
                span.multiple-select__search-dropdown-list-el-value.flex-grow-1 {{ option.text }}

                span.d-flex.flex-shrink-0.ms-3
                  app-svg-icon(v-if="option.selected" name="remove-circle")
                  app-svg-icon(v-else name="plus-circle")

            .text-family--italic.text-size--small.text-color--blue(v-else) Ничего не найдено

          button(
            class=['button button--small button--accent', 'mt-4 w-100']
            @click="close"
            type="button"
            v-if='multiple'
          )
            span.button__content Готово


    // selected list
    ul(
      v-if="selected.length && multiple===true"
      class=['multiple-select__selected-list', 'mt-3']
      :class="`multiple-select__selected-list--${resultsView}`"
    )
      template(v-if="resultsView === 'detail'")
        li(
          class=['multiple-select__selected-list-el', 'd-flex align-items-center']
          v-for="(option, index) in selected" :key="index"
        )
          span.text-size--small {{ option.text }}
          button(
            type="button"
            class=['ms-auto', 'text-color--danger']
            @click="toggleSelect(option)"
          )
            app-svg-icon(name="close")

      template(v-else-if="resultsView === 'simple'")
        li(
          class=['multiple-select__selected-list-el', 'd-flex align-items-center']
          v-for="(option, index) in selected" :key="index"
          @click="toggleSelect(option)"
          v-if='multiple'
        )
          span.me-2
            app-svg-icon(name="close")
          span.text-size--small {{ option.text }}
        li(
          class=['multiple-select__selected-list-el', 'd-flex align-items-center']
          v-for="(option, index) in selected" :key="index"
          @click="toggleSelectMultiple(option)"
          v-else
        )
          span.me-2
            app-svg-icon(name="close")
          span.text-size--small {{ option.text }}

      template(v-else)
        slot(name="body")

</template>

<script>
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';
import {uniqueArray} from '@/helpers/utils';

import ClickOutside from 'vue-click-outside';
import simplebar from 'simplebar-vue';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppMultipleSelect',
  components: {
    simplebar,
    AppSvgIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    initialValues: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    resultsView: {
      type: String,
      required: false,
      default: 'simple',
    },
    searchType: {
      type: String,
      required: true,
      default: null,
    },
    searchParams: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Введите ФИО',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    clean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v: variables,
      isOpen: false,
      isDirty: false,
      searchInput: '',
      selected: [],
      filtered: [],
    };
  },
  computed: {
    ...mapState({
      searchData: (state) => state.filters.data,
    }),
    searchDataLocal() {
      if (this.searchData) {
        let data = this.searchData[this.searchType];
        if (this.searchType === 'users') {
          data = data.filter((el) => el.role_code === 'USER');
        }

        if (this.searchParams.length) {
          let filteredData = [];
          this.searchParams.forEach(
            (param) =>
              (filteredData = filteredData.concat(
                data.filter((el) => Number(el.user_id) === param)
              ))
          );

          return uniqueArray(filteredData);
        } else return uniqueArray(data);
      } else return [];
    },
  },
  watch: {
    searchInput() {
      this.$emit('dirty');
    },
    selected(data) {
      this.$emit('update', data);
    },
    clean() {
      this.selected = [];
    },
    initialValues: function () {
      console.log(this.initialValues);
      this.setInitialValues();
      return;
    },
  },
  methods: {
    search() {
      this.searchInput = this.searchInput.replace(this.v.regexes.search, ' ').replace(/^\s/g, '');

      if (this.searchInput.length) {
        this.filtered = this.searchDataLocal.filter((el) => {
          if (!el.text) return false;

          const textMatch = el.text.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
          const valueMatch = String(el.value).indexOf(this.searchInput) > -1;

          return this.searchType === 'patients' ? textMatch || valueMatch : textMatch;
        });
        this.toggleSelectFiltered();
      }

      this.isOpen = this.searchInput.length > 0;
    },
    toggleSelectFiltered() {
      this.filtered.forEach((item) => {
        item.selected = Boolean(this.selected.find((el) => el.value === item.value));
      });
    },
    toggleSelect(element) {
      if (this.multiple == false) {
        this.selected = [];
        this.filtered = [];
      }

      const index = this.selected.map((el) => el.value).indexOf(element.value);
      index !== -1 ? this.selected.splice(index, 1) : this.selected.push(element);

      this.toggleSelectFiltered();
      if (this.multiple == false) {
        this.searchInput = element.text;
        this.isOpen = false;
      }
    },
    toggleSelectMultiple(element) {
      const index = this.selected.map((el) => el.value).indexOf(element.value);
      index !== -1 ? this.selected.splice(index, 1) : this.selected.push(element);
      this.toggleSelectFiltered();
    },
    externalToggle(value) {
      if (is.not.nan(value)) {
        const element = this.searchDataLocal.find((el) => el.value === value);
        if (element) this.toggleSelect(element);
      }
    },
    setInitialValues() {
      this.initialValues.forEach((value) => {
        const element = this.searchDataLocal.find((el) => el.value === value);
        if (element) {
          this.selected.push(element);
          if (this.multiple == false) {
            this.searchInput = '123';
          }
        }
      });
    },
    clear() {
      this.searchInput = '';
      this.isOpen = false;
      this.selected = [];
      this.filtered = [];
      this.searchDataLocal.map((el) => (el.selected = false));
    },
    close() {
      if (this.multiple !== false) {
        this.searchInput = '';
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">
.multiple-select {
  width: 100%;

  &__search {
    position: relative;
    width: 100%;

    &.is-open {
      .multiple-select__search {
        &-label {
          border-color: transparent;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: get($shadows, default);

          .icon {
            &__plus {
              fill: get($colors, danger);
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    &-label {
      width: 100%;
      height: $input-height;
      background-color: get($colors, white);
      border: $border-width solid get($colors, blue);
      border-radius: $border-radius-sm;
      padding: 0 $spacer * 1.6;
      @include transition();

      &.is-error {
        border-color: get($colors, danger);
      }
      @include interaction('mouse') {
        &:hover {
          cursor: pointer;
          border-color: get($colors, blue-dark);
        }
      }
      .icon {
        &__plus {
          @include transition();
          width: $spacer * 1.2;
          height: $spacer * 1.2;
        }
        &__search {
          width: $spacer * 1.4;
          height: $spacer * 1.4;
        }
      }
    }
    &-field {
      transition: background-color 9999999s;
      box-shadow: none;
      border: none;
      color: get($colors, black);
      cursor: inherit;

      &::-webkit-input-placeholder {
        color: get($colors, blue);
        font-weight: 300;
        @include transition();
      }
      &::-moz-placeholder {
        color: get($colors, blue);
        font-weight: 300;
        @include transition();
      }
      &:focus {
        cursor: text;

        &::-webkit-input-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
      }
    }
    &-dropdown {
      position: absolute;
      z-index: get($index, xs);
      top: 100%;
      left: 0;
      width: 100%;
      background-color: get($colors, white);
      color: get($colors, black);
      padding: $spacer $spacer * 1.6 $spacer * 2 $spacer * 1.6;
      border-top: none;
      margin-top: -$border-width;
      box-shadow: get($shadows, down);
      border-radius: 0 0 $border-radius-sm $border-radius-sm;

      &__inner[data-simplebar] {
        width: 100%;
        max-height: $spacer * 15;
        margin-left: 0;

        .simplebar-content-wrapper {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &-list {
        width: 100%;

        &-el {
          width: 100%;
          cursor: pointer;
          @include transition(color);

          &.is-selected {
            color: get($colors, blue);
          }
          & + .multiple-select__search-dropdown-list-el {
            margin-top: $spacer;
          }
          &-value {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            width: $spacer * 2;
            height: $spacer * 2;

            &__plus-circle {
              fill: get($colors, orange-hover);
            }
            &__remove-circle {
              fill: get($colors, gray-hover);
            }
          }
        }
      }
    }
  }
  &__selected-list {
    &-el {
      & + .multiple-select__selected-list-el {
        margin-top: $spacer * 0.5;
      }
    }
    &--simple {
      .multiple-select__selected-list-el {
        @include transition();

        @include interaction('mouse') {
          &:hover {
            cursor: pointer;
            color: get($colors, danger);
          }
        }
      }
    }
    &--custom {
      .range {
        &.slider-horizontal .slider-tooltip {
          background-color: #f1f4fd;
        }
      }
      .multiple-select__selected-list-el {
        background: get($gradients, blue-light);
        padding: $spacer $spacer $spacer * 2.5 $spacer;
        border-radius: $border-radius-lg/2;
      }
    }
    &--detail {
      .multiple-select__selected-list-el {
        color: get($colors, black);
        background: get($gradients, blue-light);
        padding: $spacer * 0.8 $spacer;
        border-radius: $border-radius-sm;
      }
    }
  }
}
</style>
