import devicesApi from '@/api/devices';

const state = {
  data: null,
  count: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getDevicesStart: '[devices] get data start',
  getDevicesSuccess: '[devices] get data success',
  getDevicesFailure: '[devices] get data failure',

  getCountDevicesStart: '[devices] get count start',
  getCountDevicesSuccess: '[devices] get count success',
  getCountDevicesFailure: '[devices] get count failure',

  exportDevicesStart: '[devices] export data start',
  exportDevicesSuccess: '[devices] export data success',
  exportDevicesFailure: '[devices] export data failure',

  deleteDeviceStart: '[devices] delete start',
  deleteDeviceSuccess: '[devices] delete success',
  deleteDeviceFailure: '[devices] delete failure',
};

export const actionTypes = {
  getDevices: '[devices] get data',
  filterDevices: '[devices] filter',
  getCountDevices: '[devices] get count',
  exportDevices: '[devices] export',
  deleteDevice: '[devices] delete',
  deleteRejectedDevice: '[devices] rejected delete',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getDevicesStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getDevicesSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getDevicesFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getCountDevicesStart](state) {
    state.count = null;
  },
  [mutationTypes.getCountDevicesSuccess](state, payload) {
    state.count = payload;
  },
  [mutationTypes.getCountDevicesFailure]() {},

  [mutationTypes.exportDevicesStart]() {},
  [mutationTypes.exportDevicesSuccess]() {},
  [mutationTypes.exportDevicesFailure]() {},

  [mutationTypes.deleteDeviceStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deleteDeviceSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteDeviceFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.getDevices](context, payload={device_verification_owner: null}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDevicesStart);
      devicesApi
        .getDevices(payload)
        .then((response) => {
          context.commit(mutationTypes.getDevicesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDevicesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getCountDevices](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCountDevicesStart);
      devicesApi
        .getCountDevices(credentials)
        .then((response) => {
          context.commit(mutationTypes.getCountDevicesSuccess, response.data.total);
          resolve(response.data.total);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCountDevicesFailure);
          reject(result);
        });
    });
  },
  [actionTypes.filterDevices](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDevicesStart);
      devicesApi
        .filterDevices(credentials)
        .then((response) => {
          context.commit(mutationTypes.getDevicesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDevicesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.exportDevices](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.exportDevicesStart);
      devicesApi
        .exportDevices(credentials)
        .then((response) => {
          context.commit(mutationTypes.exportDevicesSuccess);
          resolve(response.data);
        })
        .catch(() => {
          context.commit(mutationTypes.exportDevicesFailure);
        });
    });
  },
  [actionTypes.deleteDevice](context, {deviceId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteDeviceStart);
      devicesApi
        .deleteDevice(deviceId)
        .then(() => {
          context.commit(mutationTypes.deleteDeviceSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteDeviceFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.deleteRejectedDevice](context, {requestId, deviceId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteDeviceStart);
      devicesApi
        .deleteRejectedDevice(requestId, deviceId)
        .then(() => {
          context.commit(mutationTypes.deleteDeviceSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteDeviceFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
