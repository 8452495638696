<template lang="pug">
div
  .profile-doctors(v-show="!examInWork")
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-10
          .d-flex.align-items-center
            button(
                v-show="!shiftStarted"
                type="button"
                @click="startShift"
                class=['button button--border-blue']
              )
                span.button__content
                  span  Начать рабочую смену
            .button.button--medium.button--white.grab-to-work-box.w-100(v-show="shiftStarted && exam.body !==''")
              .d-flex.align-items-left
                p.grab-to-work-box__title Вам назначен осмотр
                button(
                  type="button"
                  @click="getExamToWork"
                  class=['button', 'button--accent', 'grab-to-work-box__button']
                )
                  span.button__content
                    span  Взять в работу
                p.grab-to-work-box__seconds осталось:
                  span.orange {{ remainingTime }} секунд
      .d-flex.system-setup-container(v-if="shiftStarted && exam.body === ''")
        ul.list-group.col-10
          li.profile-sidebar__menu-item
              .button.button--medium.button--white.button--shadow.w-100.noHover
                .d-flex.align-items-left Осмотров в очереди нет. Ожидайте назначения осмотра
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7.subtitle
          .d-flex.align-items-center
            app-title(:type="'large'") Информация о смене
      .d-flex.system-setup-container
        ul.list-group.col-12.d-flex.shift-buttons
          li.profile-sidebar__menu-item
            .button.button--medium.button--white.button--shadow.w-100.noHover
              .d-flex.align-items-left Текущий статус:
                span.green(v-if="shiftStarted") онлайн
                span.orange(v-if="!shiftStarted") офлайн
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7.subtitle
          .d-flex.align-items-center
            app-title(:type="'large'") Информация об осмотрах
      .d-flex.system-setup-container
        ul.list-group.col-12.d-flex.shift-buttons
          li.profile-sidebar__menu-item
            .button.button--medium.button--white.button--shadow.w-100.noHover
              .d-flex.align-items-left Осмотров в очереди: {{ tasksCount }}
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7.subtitle
          .d-flex.align-items-center
            app-title(:type="'large'") Доступные действия
      .d-flex.system-setup-container
        ul.list-group.col-12.d-flex.action-buttons
          li.profile-sidebar__menu-item
            .button.button--medium.button--white.button--shadow.w-100
              .d-flex.align-items-left(@click="pauseShift") пауза
          li.profile-sidebar__menu-item
            .button.button--medium.button--white.button--shadow.w-100
              .d-flex.align-items-left(@click="stopShift") окончить смену
  <div v-show="examInWork">
    doctor-exams-detail(
      :showed="examInWork"
      :uuid="exam.body"
      :examProcessed="examProcessed"
      :examReturned="examReturned"
      :sendExamAndStop="sendExamAndStop"
    )
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';

import DoctorExamsDetail from '@/components/profile/doctor/exams/DoctorExamsDetail';

import Stomp from 'webstomp-client';

import doctorsApi from '@/api/doctors';

export default {
  name: 'SystemSetupDashboard',
  components: {
    AppTitle,
    AppMultipleSelect,
    DoctorExamsDetail,
  },
  data() {
    return {
      getTaskCountTimer: null,
      shiftStarted: false,
      exam: {body: ''},
      tasksCount: 0,
      remainingTime: 25,
      timer: null,
      examInWork: false,
      stompClient: null,
      subscription: null,
      passedExamsInRow: 0,
    };
  },
  watch: {
    exam(oldVal, newVal) {
      console.log(oldVal);
      console.log(newVal);
      if (oldVal.body === '' && newVal !== '') {
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    passedExamsInRow(value) {
      console.log(value);
      if (value === 25) {
        this.stopShift();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  created() {
    this.getTaskCountTimer = setInterval(this.fetchTasksCount, 5000);
  },
  methods: {
    async fetchTasksCount() {
      // Отправка запроса на сервер
      let tasksCount = await doctorsApi.getExamsCount();
      this.tasksCount = tasksCount.data.messages;
    },
    async startShift() {
      let token = await doctorsApi.getToken();
      doctorsApi.setDoctorStatus({
        status: 'ONLINE',
        start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      token = token.data.access_token;
      this.shiftStarted = true;
      let websocetProtocol = 'ws';
      if (window.location.protocol === 'https:') {
        websocetProtocol = 'wss';
      }
      // используй эту строчку для тестирования
      this.stompClient = Stomp.client(`${websocetProtocol}://` + window.location.hostname + '/ws');
      // this.stompClient = Stomp.client(`${websocetProtocol}://` + '10.17.7.55' + '/ws')
      this.stompClient.connect(
        {
          passcode: token,
          'client-id': 'my-client-id',
        },
        (frame) => {
          this.connected = true;
          console.log(frame);
          this.subscription = this.stompClient.subscribe(
            '/queue/incoming-exams',
            (tick) => {
              this.play();
              this.exam = tick;
              if (this.exam) {
                if (this.exam.body === '') {
                  this.exam.ack();
                }
                let uuidData = JSON.parse(this.exam.body);
                let examId = uuidData['id'];
                doctorsApi.doctorStatsEvent({
                  exam_id: examId,
                  event_type: 'time_to_med',
                });
              }
              this.timer = setInterval(() => {
                if (this.remainingTime <= 0) {
                  clearInterval(this.timer);
                  if (this.exam !== null) {
                    this.exam.nack();
                  }
                  this.remainingTime = 25;
                  this.exam = {body: ''};
                  this.passedExamsInRow += 1;
                  return;
                }
                this.remainingTime = this.remainingTime - 1;
              }, 1000);
            },
            {'x-max-priority': 5, ack: 'client', 'prefetch-count': '1', id: this.currentUser.id}
          );
        },
        (error) => {
          console.log(error);
          alert(
            'Не получилось подсоединиться к очереди, перезагрузите страницу и попробуйте еще раз'
          );
          this.connected = false;
        }
      );
    },
    play() {
      let audio = new Audio('/zvuk-kapli.mp3');
      audio.play();
    },
    getExamToWork() {
      clearInterval(this.timer);
      this.remainingTime = 25;
      this.examInWork = true;
    },
    examProcessed() {
      this.exam.ack();
      this.exam = {body: ''};
      this.examInWork = false;
    },
    sendExamAndStop() {
      this.examProcessed();
      this.stopShift();
    },
    examReturned() {
      this.exam.nack();
      this.exam = {body: ''};
      clearInterval(this.timer);
      this.remainingTime = 25;
      this.examInWork = false;
    },
    stopShift() {
      doctorsApi.setDoctorStatus({
        status: 'OFFLINE',
        start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      this.subscription.unsubscribe();
      this.stompClient.disconnect(() => {
        console.log('disconnect');
      });
      this.shiftStarted = false;
      clearInterval(this.timer);
      this.exam = {body: ''};
    },
    pauseShift() {
      doctorsApi.setDoctorStatus({
        status: 'PAUSE',
        start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      this.subscription.unsubscribe();
      this.stompClient.disconnect(() => {
        console.log('disconnect');
      });
      this.shiftStarted = false;
      clearInterval(this.timer);
      this.exam = {body: ''};
    },
  },
  beforeDestroy() {
    clearInterval(this.getTaskCountTimer);
    this.stopShift();
  },
};
</script>

<style scoped>
.system-setup-container {
  margin-top: 50px;
}
.button--white:hover {
  color: #6d78a2;
}
.subtitle {
  margin-top: 40px;
}

.shift-buttons {
  justify-content: flex-start;
}

.action-buttons {
  justify-content: flex-start;
}

.action-buttons > li {
  width: 350px;
  margin-right: 20px;
}

.shift-buttons > li {
  width: 350px;
  margin-right: 20px;
}

.noHover {
  pointer-events: none;
}

.grab-to-work-box {
  height: 90px;
  box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
}

.grab-to-work-box__title {
  margin-top: 22px;
}

.grab-to-work-box__button {
  margin-left: 30px;
  margin-top: 10px;
}

.grab-to-work-box__seconds {
  margin-top: 22px;
  margin-left: 30px;
}
</style>
