<template lang="pug">
.profile-requests#profile-requests
      .profile-requests__header.col-14.col-md-auto
        app-title(:type="'large'") Заявки на регистрацию
                //- button(
                //-   type="button"
                //-   @click="getRequests"
                //-   class=['d-flex align-items-center ms-4', 'text-color--blue text-size--small link--accent']
                //- )
                //-   span.flex-shrink-0.me-2
                //-     app-svg-icon(name="reload")
                //-   span Обновить

      //- .col-12.d-xl-none.text-center.mb-4.mb-md-5
      //-   p.text-color--blue.text-size--small.text-family--light(v-if="requestsPending") {{ requestsPending.length }} заявки на регистрацию
      //-   app-title(:type="'large'") Заявки

      .position-relative
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

      transition(name="translucent")
        div(v-if="requests" :class="{'is-loading': isLoading}")
          .d-flex.align-items-center.mb-5
            app-tab-nav(
              :tabs="tabs"
              :selected="activeTab"
              :theme="'separate'"
              @selected="setActiveTab"
            )

          app-tab(v-for="tab in tabs" :key="tab.id" :is-selected="activeTab === tab.id")
            .devices-tab(v-if="(activeTab === allId || activeTab === v.types.device) && requestsPendingDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Устройства (+{{requestsPendingDevices.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1.mb-5
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsPendingDevices" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")


            .pending-users-tab(v-if="(activeTab === allId || activeTab === v.types.user) && requestsPendingUsers.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Клиенты (+{{requestsPendingUsers.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.users.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsPendingUsers" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

          app-tab(:is-selected="activeTab === v.statuses.rejected")
            .mt-5(v-if="requestsRejectedDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Отказаные заявки устройств
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.rejected.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsRejectedDevices.slice(0, pagination.offset.devices)" :key="index")
                  app-admin-request-card(:data="request")

              button(
                v-if="pagination.offset.devices < requestsRejectedDevices.length"
                type="button"
                @click="pagination.offset.devices += pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
              ) Показать еще (+{{ (requestsRejectedDevices.length - pagination.offset.devices) >= pagination.limit ? pagination.limit : requestsRejectedDevices.length - pagination.offset.devices}})

              button(
                v-if="pagination.offset.devices > pagination.limit"
                type="button"
                @click="pagination.offset.devices = pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4']
              ) Свернуть

            .mt-5(v-if="requestsRejectedUsers.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Отказаные заявки клиентов
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.rejected.users.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsRejectedUsers.slice(0, pagination.offset.users)" :key="index")
                  app-admin-request-card(:data="request")

              button(
                v-if="pagination.offset.users < requestsRejectedUsers.length"
                type="button"
                @click="pagination.offset.users += pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
              ) Показать еще (+{{ (requestsRejectedUsers.length - pagination.offset.users) >= pagination.limit ? pagination.limit : requestsRejectedUsers.length - pagination.offset.users}})

              button(
                v-if="pagination.offset.users > pagination.limit"
                type="button"
                @click="pagination.offset.users = pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4']
              ) Свернуть
          app-tab(v-for="tab in tabs" :key="tab.id" :is-selected="activeTab === tab.id")
            .mt-5(v-if="(activeTab === allId || activeTab === v.types.device_update) && requestsUpdateDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Поверка (+{{requestsUpdateDevices.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsUpdateDevices" :key="index")
                  app-admin-update-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

      // reject modal
      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.reject" @close="closeModal('reject')")
          template(v-slot:body)
            .row.justify-content-center
              .col-14.col-md-10
                .text-center
                  app-title(:type="'large'") Отказать в регистрации
                  .mt-3.text-uppercase
                    ul(v-if="currentRequest.type_code === v.types.device")
                      li(v-for="num in currentRequest.additional_params.device_serials") Серийный номер {{ num }}
                    p(v-if="currentRequest.type_code === v.types.user") {{ currentRequest.user_full_name }}

                .mt-5
                  app-validation-errors(
                    v-if="validationErrors"
                    :classes="'mb-3'"
                    :validation-errors="validationErrors"
                  )
                  form(@submit.prevent="onSubmitRejectForm" :class="['form', {'is-submitting': isLoading}]")
                    .row.flex-column.align-items-center
                      .col.mb-5
                        label.form__label
                          textarea(
                            :class="['form__field', { 'is-error': v$.form.message.$errors.length }]"
                            v-model="v$.form.message.$model"
                            @focus="v$.form.message.$touch"
                            placeholder="Причина отказа"
                          )
                      .col
                        button(
                          type="submit"
                          :disabled="v$.$invalid"
                          class=['button', 'button--accent', 'form__button']
                        )
                          span.button__content Отправить


      // success modal
      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.success" @close="closeModal('success')" :classes="'d-flex flex-column justify-content-center'")
          template(v-slot:body)
            .text-center
              app-title(:type="'large'") {{ successMessage }}
              p.text-size--small.text-color--blue.mt-3 {{ currentRequest.user_full_name }} будет уведомлен о принятом решении
      //Выбрать ответственного при нажатии
      app-confirm-check-modal(
        :is-visible="isModalVisible.confirm"
        :currentRequest="currentRequest"
        @openModal="openExamDetailModal"
        @close="closeModal('confirm')"
      )
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/requests';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import is from 'is_js';
import variables from '@/helpers/variables';
import {sortBy} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppTabNav from '@/components/common/TabNav';
import AppTab from '@/components/common/Tab';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';

import AppAdminRequestCard from '@/components/profile/admin/requests/AdminRequestCard';
import AppAdminUpdateCard from '@/components/profile/admin/requests/AdminUpdateCard';
import AppConfirmCheckModal from '@/components/profile/admin/devices/AppConfirmCheckModal';
export default {
  name: 'AppAdminRequests',
  components: {
    AppSvgIcon,
    AppErrorMessage,
    AppValidationErrors,
    AppLoading,
    AppTitle,
    AppTabNav,
    AppTab,
    AppSelect,
    AppModal,
    AppAdminRequestCard,
    AppConfirmCheckModal,
    AppAdminUpdateCard,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables.requests,
      activeTab: 'all',
      allId: 'all',
      isModalVisible: {
        reject: false,
        success: false,
        confirm: false,
      },
      form: {
        message: '',
      },
      currentRequest: {},
      fullName: '',
      successMessage: '',
      validationErrors: '',
      sorting: {
        pending: {
          users: {
            sortKey: 'creation_date',
            sortOptions: [
              {
                caption: 'дате заявки',
                value: 'creation_date',
                status: 'pending',
                type: 'users',
                selected: true,
              },
              {
                caption: 'имени клиента',
                value: 'user_full_name',
                status: 'pending',
                type: 'users',
              },
            ],
          },
          devices: {
            sortKey: 'creation_date',
            sortOptions: [
              {
                caption: 'дате заявки',
                value: 'creation_date',
                status: 'pending',
                type: 'devices',
                selected: true,
              },
              {
                caption: 'имени клиента',
                value: 'user_full_name',
                status: 'pending',
                type: 'devices',
              },
            ],
          },
        },
        rejected: {
          users: {
            sortKey: 'creation_date',
            sortOptions: [
              {
                caption: 'дате заявки',
                value: 'creation_date',
                status: 'rejected',
                type: 'users',
                selected: true,
              },
              {
                caption: 'имени клиента',
                value: 'user_full_name',
                status: 'rejected',
                type: 'users',
              },
            ],
          },
          devices: {
            sortKey: 'creation_date',
            sortOptions: [
              {
                caption: 'дате заявки',
                value: 'creation_date',
                status: 'rejected',
                type: 'devices',
                selected: true,
              },
              {
                caption: 'имени клиента',
                value: 'user_full_name',
                status: 'rejected',
                type: 'devices',
              },
            ],
          },
        },
      },
      pagination: {
        limit: 3,
        offset: {
          users: 3,
          devices: 3,
        },
      },
    };
  },
  validations() {
    return {
      form: {
        message: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      requests: (state) => state.requests.data,
      error: (state) => state.requests.error,
    }),
    requestsPending() {
      if (this.requests) {
        return this.requests.filter((el) => el.status_code === this.v.statuses.pending);
      } else return false;
    },
    requestsRejected() {
      if (this.requests) {
        return this.requests.filter((el) => el.status_code === this.v.statuses.rejected);
      } else return false;
    },
    requestsPendingUsers() {
      const filteredReq = this.requestsPending.filter((el) => el.type_code === this.v.types.user);

      if (is.not.empty(this.sorting.pending.users.sortKey))
        return sortBy(filteredReq, this.sorting.pending.users.sortKey);
      else return filteredReq;
    },
    requestsPendingDevices() {
      const filteredReq = this.requestsPending.filter((el) => el.type_code === this.v.types.device);

      if (is.not.empty(this.sorting.pending.devices.sortKey))
        return sortBy(filteredReq, this.sorting.pending.devices.sortKey);
      else return filteredReq;
    },
    requestsRejectedUsers() {
      const filteredReq = this.requestsRejected.filter((el) => el.type_code === this.v.types.user);

      if (is.not.empty(this.sorting.rejected.users.sortKey))
        return sortBy(filteredReq, this.sorting.rejected.users.sortKey);
      else return filteredReq;
    },
    requestsRejectedDevices() {
      const filteredReq = this.requestsRejected.filter(
        (el) => el.type_code === this.v.types.device
      );

      if (is.not.empty(this.sorting.rejected.devices.sortKey))
        return sortBy(filteredReq, this.sorting.rejected.devices.sortKey);
      else return filteredReq;
    },
    requestsUpdateDevices() {
      const filteredReq = this.requestsPending.filter(
        (el) => el.type_code === this.v.types.device_update
      );

      if (is.not.empty(this.sorting.pending.devices.sortKey))
        return sortBy(filteredReq, this.sorting.pending.devices.sortKey);
      else return filteredReq;
    },
    tabs() {
      let tabs = [];

      if (this.requestsPending.length) {
        tabs.push({
          id: this.allId,
          caption: 'Все',
          add: `(+${this.requestsPending.length})`,
        });
      }

      if (this.requestsPendingDevices.length) {
        tabs.push({
          id: this.v.types.device,
          caption: 'Устройства',
          add: `(+${this.requestsPendingDevices.length})`,
        });
      }

      if (this.requestsPendingUsers.length) {
        tabs.push({
          id: this.v.types.user,
          caption: 'Клиенты',
          add: `(+${this.requestsPendingUsers.length})`,
        });
      }

      if (this.requestsRejected.length) {
        tabs.push({
          id: this.v.statuses.rejected,
          caption: 'Отказаные заявки',
          icon: 'wrong',
        });
      }
      //////////New
      if (this.requestsUpdateDevices.length) {
        tabs.push({
          id: this.v.types.device_update,
          caption: 'Поверка',
          add: `(+${this.requestsUpdateDevices.length})`,
        });
      }

      return tabs;
    },
  },
  methods: {
    openExamDetailModal() {
      console.log('123');
    },
    sortBy({value, status, type}) {
      this.sorting[status][type].sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sorting[status][type].sortKey = value;
    },
    goToFirstPage() {
      this.pagination.offset.users = this.requestsRejectedUsers.length;
      this.pagination.offset.devices = this.requestsRejectedDevices.length;
    },
    getRequests() {
      this.$store.dispatch(actionTypes.getRequests);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    openRejectModal(data) {
      this.currentRequest = data;
      this.isModalVisible.reject = true;
    },
    closeModal(slug) {
      this.v$.$reset();
      this.form.message = '';

      this.isModalVisible[slug] = false;
      this.getRequests();
    },
    successCallback(slug) {
      this.closeModal(slug);
      this.successMessage = `Заявка успешно ${slug === 'reject' ? 'отклонена' : 'одобрена'}`;
      this.isModalVisible.success = true;
      this.getRequests();

      if (slug === 'reject') this.activeTab = this.v.statuses.rejected;
    },
    onSubmitRejectForm() {
      if (!this.v$.$invalid) {
        console.log(this.currentRequest);
        if (this.currentRequest.type_id === 1) {
          this.$store
            .dispatch(actionTypes.changeStatusRegistration, {
              id: this.currentRequest.id,
              body: {
                reason: this.form.message,
                status_code: this.v.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'))
            .catch(() => (this.validationErrors = this.error));
        } else if (this.currentRequest.type_id === 5) {
          console.log('update device request');
          this.$store
            .dispatch(actionTypes.changeDeviceUpdateRequest, {
              id: this.currentRequest.id,
              body: {
                device_serial: this.currentRequest.additional_params.device_serial,
                reason: this.form.message,
                status_code: this.v.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'))
            .catch(() => (this.validationErrors = this.error));
        } else if (this.currentRequest.type_id === 6) {
          console.log('обновление поверки');
          let request = null;
          for (let req of this.requests) {
            console.log(req.additional_params);
            if (req.id === this.currentRequest.id) {
              request = req;
            }
          }
          console.log(request.additional_params);
          this.$store
            .dispatch(actionTypes.changeDeviceUpdateRequest, {
              id: this.currentRequest.id,
              body: {
                device_serial: this.currentRequest.additional_params.device_serial,
                reason: this.form.message,
                status_code: this.v.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'))
            .catch(() => (this.validationErrors = this.error));
        } else {
          this.$store
            .dispatch(actionTypes.changeStatusDevice, {
              id: this.currentRequest.id,
              body: {
                reason: this.form.message,
                status_code: this.v.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'))
            .catch(() => (this.validationErrors = this.error));
        }
      }
    },
    approveRequest(data) {
      console.log(data);
      this.isModalVisible.confirm = true;
      this.currentRequest = data;
      if (this.currentRequest.type_code != 'device') {
        if (this.currentRequest.type_id === 1) {
          this.$store
            .dispatch(actionTypes.changeStatusRegistration, {
              id: this.currentRequest.id,
              body: {
                reason: '',
                status_code: this.v.statuses.approved,
              },
            })
            .then(() => this.successCallback('approve'));
        } else if (this.currentRequest.type_id === 5) {
          console.log('device_update');
          this.$store
            .dispatch(actionTypes.changeDeviceUpdateRequest, {
              id: this.currentRequest.id,
              body: {
                reason: '',
                status_code: this.v.statuses.approved,
                device_serial: this.currentRequest.additional_params.device_serial,
                device_attestation_end:
                  this.currentRequest.additional_params.device_attestation_end,
                device_attestation_start:
                  this.currentRequest.additional_params.device_attestation_start,
                device_attestation: this.currentRequest.additional_params.device_attestation,
              },
            })
            .then(() => this.successCallback('approve'));
        }
      } else {
        console.log('device check update');
        this.isModalVisible.confirm = true;
      }
    },
  },
};
</script>

<style lang="scss">
.profile-requests {
  .profile-requests__header {
    margin-bottom: 20px;
  }
  .tabs-nav {
    &__button {
      .button__content-add {
        color: get($colors, orange);
      }
      &.is-active {
        .button__content-add {
          color: get($colors, white);
        }
      }
    }
  }
}
</style>
