<template lang="pug">
  section(:class="['profile-card', 'profile-card-device', {'no-hover': noHover}]")
    .row.align-items-center.g-4.flex-xl-nowrap
      div(class=['col-7 col-md-5 col-xl-3'])
        .d-flex.align-items-center
          .me-3.flex-shrink-0
            app-status(
              :type="'device'"
              :data="data"
              :line="false"
              :id="data.serial"
            )
          .d-none.d-md-flex.flex-shrink-0.profile-card-device__icon
            app-svg-icon(name="smartwatch" view-box="0 0 17 24")

          .ms-md-4
            .text-color--blue.text-color-xxl-gray-light.mb-2 Серийный номер
            span {{ data.serial }}


      div(class=['col-auto col-md-3 col-xl-2', 'ms-auto ms-xl-0'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Дата регистрации
        span(v-if="data.creation_date").text-color--blue-dark {{ toLocalDate(data.creation_date) }}
        span(v-else) &#8212;

      //- div(class=['col-14 col-md-4 col-xl-2 col-xxl-2'])
      //-   .text-color--blue.text-color-xxl-gray-light.mb-2 Владелец устройства
        span {{ data.full_name }}
      div(class=['col-14 col-md-4 col-xl-1 col-xxl-1'])
        .text-color--blue.text-color-xxl-gray-light.mb-2 Корпус
        span(v-if="data.case_opened === null ? '---' : data.case_opened === false") Не вскрыт
        span.text-color--orange(v-else) Вскрыт
        span.text-color--blue-dark(v-else) &#8212;
      .div(class=['col-auto col-md-3 col-xl-3'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Контроль целостности ПО
        span.text-color--blue-dark(v-if='data.device_integrity === true') Пройден
        span.text-color--orange(v-else-if='data.device_integrity === false') Не пройден
        span.text-color--blue-dark(v-else) &#8212;
      div(class=['col-2', 'd-none d-xl-block'])
        .text-color--blue.text-color-xxl-gray-light.mb-2 Осмотры
        span(v-if="data.exams_count") {{ data.exams_count }}&nbsp;{{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
        span.text-color--blue(v-else) нет осмотров

      div(class=['col-auto col-xl-2 col-xxl-2', 'me-auto me-xl-0'])
        button(
          class=['profile-card-device__document-button', 'link--accent']
          type="button"
          @click.prevent="getFile(data.document)"
        )
          .d-flex.align-items-center
            span.flex-shrink-0
              app-svg-icon(name="document")
            span.ms-3 Документ покупки

      div(class=['col-auto', 'd-none d-md-block'])
        button(
          type="button"
          @click.prevent="deleteModal(data)"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
            app-svg-icon(name="trash")
    .row
     .col-14 <hr>
    .row.g-4.flex-xl-nowrap
     .div(class=['col-auto col-md-3 col-xl-3 paddingLeft'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Поверка устройства
        span(v-if="data.attestation === 'attestation'") {{ getAlarmByDays(data.attestation) }}
        span.text-color--orange(v-else) {{ getAlarmByDays(data.attestation) }}
        
     .div(class=['col-auto col-md-3 col-xl-3'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Номер свидетельства
        span {{data.device_attestation}}
     .div(class=['col-14 col-md-4 col-xl-3 col-xxl-3'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Дата поверки
        span {{ toLocalDate(data.device_attestation_start) }}
     .div(class=['col-2'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Действует до
        span {{ toLocalDate(data.device_attestation_end) }}
     .div(class=['col-auto col-md-3 col-xl-2'])
        .text-color--blue.text-color-xxl-gray-light.mb-2.d-none.d-md-block Ответственный
        span.text-color--blue-dark {{data.device_verification_owner }}
     div(class=['col-auto', 'd-none d-md-block'])
        button(
          type="button"
          @click.prevent="changeModal(data)"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
            app-svg-icon(name="edit")




</template>

<script>
import filesApi from '@/api/files';
import {declOfNum, toLocalDate} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppStatus from '@/components/profile/common/Status';
import variables from '@/helpers/variables';

export default {
  name: 'AppAdminDeviceCard',
  components: {
    AppSvgIcon,
    AppStatus,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    noHover: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      declOfNum,
      v: variables,
    };
  },
  methods: {
    getAlarmByDays(status) {
      return status === 'attestation'
        ? 'Поверено'
        : status === 'attestation_ends'
        ? 'Заканчивается'
        : 'Не поверено';
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    getFile(hash) {
      filesApi.getFile(hash);
    },
    deleteModal(data) {
      this.$emit('delete', data);
    },
    changeModal(data) {
      this.$emit('change', data);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-card-device {
  @include mediaToDown($xxl) {
    padding-left: $spacer * 1.6;
    padding-right: $spacer * 1.6;
  }
  @include mediaToDown($xl) {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: $border-width solid get($colors, gray-hover);
    border-bottom: $border-width solid get($colors, gray-hover);
  }
  @include mediaToDown($md) {
    padding-left: 0;
    padding-right: 0;
  }
  &__icon {
    @include mediaToDown($xl) {
      background: get($colors, white);
    }
  }
}
.paddingLeft {
  padding-left: 98px;
}
</style>
