<template lang="pug">
  simplebar.home
    .container-fluid
      .row
        .col
          .d-flex.flex-column.align-items-center.flex-grow-1
            
            app-logo(:caption="'Выберите, что вас интересует'")
            
            .d-flex.flex-column.align-items-center.justify-content-center.flex-grow-1.w-100
              ul.d-xl-flex.flex-column.align-items-center.w-100
                li.mb-5
                  router-link(
                    tag="button"
                    :to="{name: 'login'}"
                    class=['button button--extra-large button--blue button--shadow']
                  )
                    .button__content Войти в личный кабинет

                li.mb-5
                  router-link(
                    tag="button"
                    :to="{name: 'register'}"
                    class=['button button--large button--white button--shadow']
                  )
                    .button__content Зарегистрироваться
                li
                  router-link(
                    tag="button"
                    :to="{name: 'registerStatus'}"
                    class=['button button--large button--white button--shadow']
                  )
                    .button__content Проверить статус заявки

              app-tooltip(:content="'Веб-портал для просмотра и анализа данных, поступающих от устройств для проведения телемедицинских осмотров'" :classes="'text-color--accent text-decoration--underline text-size--small mt-5 pt-2'")
                template(v-slot:body)
                  span.ms-3 Куда я попал или что за портал?

</template>

<script>
import simplebar from 'simplebar-vue';
import SvgIcon from '@/components/common/SvgIcon';
import AppLogo from '@/components/common/Logo';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppHome',
  components: {
    simplebar,
    AppTooltip,
    AppSvgIcon,
    AppLogo,
    SvgIcon,
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  padding-top: $spacer * 7;
  padding-bottom: $spacer * 4;

  .logo {
    margin-bottom: $spacer * 5;
  }
  @include mediaToDown($md) {
    padding-top: $spacer * 4;
  }
}
</style>
