import is from 'is_js';
import moment from 'moment';
import variables from '@/helpers/variables';
import _ from 'lodash';

export const range = (start, end) => {
  return [...Array(end).keys()].map((el) => el + start);
};

export const isEmptyObj = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const removeSpaces = (str) => {
  return str ? String(str).replace(/\s+/g, '') : null;
};

export const declOfNum = (number, words) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};

export const convertDate = (date) => {
  if (!date) return null;

  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
};

export const convertPhone = (phone) => {
  return phone ? phone.replace(/[^0-9,+]/g, '') : null;
};

export const toLocalDate = (date) => {
  if (String(date).substr(date.length - 1, 1) === 'Z') date = date.slice(0, -1);
  let time = new Date(new Date(date).getTime());
  return time.toLocaleDateString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$2.$1.$3');
};

export const toLocalDateExam = (date) => {
  var local_date= moment.utc(date).local().format('DD.MM.YYYY');
  return local_date;
};

export const toLocalTime = (date) => {
  if (String(date).substr(date.length - 1, 1) === 'Z') date = date.slice(0, -1);
  let offset = new Date().getTimezoneOffset();
  let time = new Date(new Date(date).getTime() + offset*1000*60);
  return time.toLocaleTimeString();
};

export const toLocalTimeExam = (date) => {
  var local_date= moment.utc(date).local().format('HH:mm:ss');
  return local_date;
};

export const stringToBoolean = (value) => {
  return value === 'true';
};

export const insert = (str, index, value) => {
  return str.substr(0, index) + value + str.substr(index);
};

export const getFileExtention = (str) => {
  return str.substr(str.lastIndexOf('.'));
};

export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, {type: mimeType}));
};

export const blobToFile = (data, extension, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.download = `${fileName || 'Report'}.${extension || 'txt'}`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFile = (url) => {
  const link = document.createElement('a');

  link.href = url;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportCSVFile = (array) => {
  const csv = convertToCSV(array);
  const encodedUri = encodeURI(csv);
  const link = document.createElement('a');

  link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri;
  link.download = 'Report.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  function convertToCSV(arr) {
    let csvContent = '';
    arr.forEach((row) => (csvContent += row + '\r\n'));

    return csvContent;
  }
};

export const isItemInArray = (array, item) => {
  let counter = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i][0] === item[0] && array[i][1] === item[1]) ++counter;
  }
  return counter;
};

export function isFunction(functionToCheck) {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

export const uniqueArray = (array) => {
  return array
    .map((cur) => JSON.stringify(cur))
    .filter((curr, index, self) => self.indexOf(curr) === index)
    .map((cur) => JSON.parse(cur));
};

export const sortBy = (array, sortKey) => {
  function isDate(date) {
    return moment(date, moment.ISO_8601, true).isValid();
  }
  function isShortDate(date) {
    return moment(date, 'DD.MM.YYYY', true).isValid();
  }
  function toFullDate(date) {
    return moment(date, 'DD.MM.YYYY').toString();
  }

  return array.slice().sort((a, b) => {
    if (is.number(a[sortKey]) && is.number(b[sortKey])) return b[sortKey] - a[sortKey];
    else {
      if (is.falsy(a[sortKey])) a[sortKey] = '';
      if (is.falsy(b[sortKey])) b[sortKey] = '';

      if (isDate(a[sortKey]) && isDate(b[sortKey])) {
        return new Date(b[sortKey]) - new Date(a[sortKey]);
      } else if (isShortDate(a[sortKey]) && isShortDate(a[sortKey])) {
        return new Date(toFullDate(b[sortKey])) - new Date(toFullDate(a[sortKey]));
      } else {
        return String(a[sortKey]).toLowerCase().localeCompare(String(b[sortKey]).toLowerCase());
      }
    }
  });
};

export const blockScroll = (action) => {
  switch (action) {
    case 'enable':
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
      document.body.style.paddingRight = `${variables.scrollbarWidth}px`;
      break;
    case 'disable':
      document.body.style.height = 'auto';
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
      break;
    default:
      console.warn('Missing argument in blockScroll function');
  }
};

export const debounce = (fn, delay) => {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const convertSVGtoBase64 = (svg) => {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const arrayDifference = (a, b) => {
  if (a.length > b.length) {
    return _.difference(a, b);
  } else {
    return _.difference(b, a);
  }
};
