<template lang="pug">
.inspection-card__patient-avatar
  app-avatar(
  :type="'doctor'"
  :localUrl="url"
  :viewPhoto="true"
  @openGallery="openGallery"
  :classes="'square extra-large'"
)
  .w-100.text-color--dark-blue.text-center.pointer.underline.mt-2 ПРОСМОТРЕТЬ ФОТО
  //- / АРХИВИРОВАТЬ ФОТО

</template>

<script>
import AppAvatar from '@/components/profile/common/Avatar';
import AppModal from '@/components/common/Modal';
import {galleryMixin} from '@/mixins/gallery';


export default {
  name: 'DoctorExamCardAvatar',
  components: {
    AppAvatar,
    AppModal,
  },
  mixins: [galleryMixin],
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    openGallery(data) {
      console.log(data);
      this.$emit('openGallery', data);
    },
  },
};
</script>

<style lang="scss">
.inspection-card__patient-avatar {
  figure {
    width: 100% !important;
    height: 300px !important;
  }
  .profile-avatar.extra-large .profile-avatar__img {
    border-radius: 2px;
  }
  @include font-size(get($text-sizes, normal));
}
.profile-avatar__img img {
  object-fit: contain;
}
</style>
