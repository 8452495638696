import {modalsMixin} from '@/mixins/modals';

export const galleryMixin = {
  mixins: [modalsMixin],
  data() {
    return {
      isModalVisible: {
        gallery: false,
      },
      gallery: {
        sources: [],
        type: 'gallery',
        preview: null,
      },
      currentPatient: {},
    };
  },
  methods: {
    openGallery(data, slug) {
      console.log(data);
      if (slug) this.closeModal(slug);

      if (data.patient) {
        this.currentPatient = data.patient;
        this.gallery = data.gallery;
      } else this.gallery = data;

      this.openModal('gallery');
    },
    closeGallery() {
      this.gallery.sources = [];
      this.closeModal('gallery');
    },
  },
};
