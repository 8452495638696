<template lang="pug">
div
    transition(name="fade" mode="out-in")
        app-modal(v-if="isVisible" @close="close")
            template(v-slot:body)
                .text-center.mb-5
                    app-title(:type="'large'") Добавление пользователя
                    p.text-size--small.text-color--blue.mt-3 Для добавления одного пользователя воспользуйтесь формой ниже, если необходимо добавить больше одного пользователя, можете заполнить файл и загрузить для автоматического добавления

                .px-4
                    form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
                        app-tab-nav(
                            :tabs="[{id:'one', caption:'Один пользователь'}, {id:'several', caption: 'Несколько пользователей'}]"
                            :selected="activeTab"
                            :theme="'jointly'"
                            @selected="setActiveTab"
                            :classes="'mb-20'"
                        )
                        app-tab(:is-selected="activeTab === 'one'")
                            app-validation-errors(
                                v-if="localValidationErrors"
                                :validation-errors="localValidationErrors"
                                :classes="'mb-3'"
                            )
                            .row.g-4
                                .col-14.col-md-7(v-if="v$.form.firstName")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                                            type="text"
                                            v-model="v$.form.firstName.$model"
                                            placeholder="Имя"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7(v-if="v$.form.lastName")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }]"
                                            type="text"
                                            v-model="v$.form.lastName.$model"
                                            placeholder="Фамилия"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7
                                    label.form__label
                                        input(
                                            :class="['form__field']"
                                            type="text"
                                            v-model="form.middleName"
                                            placeholder="Отчество"
                                        )
                                .col-14.col-md-7(v-if="v$.form.snils")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.snils.$errors.length }]"
                                            type="text"
                                            v-mask="'###-###-###-##'"
                                            v-model="v$.form.snils.$model"
                                            placeholder="СНИЛС"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7(v-if="v$.form.drivingLicense")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.drivingLicense.$errors.length }]"
                                            type="text"
                                            v-mask="'## ## ######'"
                                            v-model="v$.form.drivingLicense.$model"
                                            placeholder="Серия и номер ВУ"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.drivingLicense.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="v$.form.drivingLicense.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}
                                .col-14.col-md-7(v-if="v$.form.licenseDate")
                                    label(:class="['form__label', { 'is-error': v$.form.licenseDate.$errors.length }]")
                                        date-picker(
                                            v-model="v$.form.licenseDate.$model"
                                            prefix-class="app"
                                            placeholder="Дата выдачи"
                                            format="DD.MM.YYYY"
                                            :lang="datepicker.lang"
                                            :editable="true"
                                            :default-panel="datepicker.defaultValue.panel"
                                            :default-value="new Date()"
                                            :disabled-date="disableAfterToday"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.licenseDate.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                .col-14.col-md-7(v-if="v$.form.birthdate")
                                    label(:class="['form__label', { 'is-error': v$.form.birthdate.$errors.length }]")
                                        date-picker(
                                            v-model="v$.form.birthdate.$model"
                                            prefix-class="app"
                                            placeholder="Дата рождения"
                                            format="DD.MM.YYYY"
                                            :lang="datepicker.lang"
                                            :editable="true"
                                            :default-panel="datepicker.defaultValue.panel"
                                            :default-value="defaultDate"
                                            :disabled-date="disableBeforeAdult"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.birthdate.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7
                                    .row.align-items-center.g-4
                                        .col-7
                                            label(:class="['form__radio', { 'is-checked': form.gender === '1'}]")
                                                input(
                                                    type="radio"
                                                    name="gender"
                                                    value="1"
                                                    v-model="form.gender"
                                                )
                                                .d-flex.align-items-center
                                                    i.form__radio-icon.me-3.flex-shrink-0
                                                    span.form__radio-caption Мужской
                                        .col-7
                                            label(:class="['form__radio', { 'is-checked': form.gender === '2'}]")
                                                input(
                                                    type="radio"
                                                    name="gender"
                                                    value="2"
                                                    v-model="form.gender"
                                                )
                                                .d-flex.align-items-center
                                                    i.form__radio-icon.me-3.flex-shrink-0
                                                    span.form__radio-caption Женский

                                .col-14.col-md-14
                                    label.form__label
                                        input(
                                            :class="['form__field']"
                                            type="text"
                                            v-model="form.personal_number"
                                            placeholder="Табельный номер"
                                        )
                                .col-14.col-md-7(v-if="v$.form.email")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                                            type="email"
                                            v-model="v$.form.email.$model"
                                            placeholder="E-mail"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                                .col-14.col-md-7(v-if="v$.form.phone")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.phone.$errors.length }]"
                                            type="tel"
                                            v-model="v$.form.phone.$model"
                                            v-mask="'+7 (###) ###-##-##'"
                                            placeholder="Телефон"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="v$.form.phone.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}

                                .col-14.col-md-7(v-if="v$.form.password")
                                    .position-relative
                                        label.form__label
                                            input(
                                                :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                                                :type="showPassword ? 'text' : 'password'"
                                                v-model="v$.form.password.$model"
                                                placeholder="Пароль"
                                                autocomplete="nope"
                                            )
                                            span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                                                app-svg-icon(v-if="showPassword" :name="'eye-open'")
                                                app-svg-icon(v-else :name="'eye-close'")

                                    ul.form__error
                                        li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                            template(v-if="v$.form.password.$model")
                                                template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                                                template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                                                template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                                                template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                                                template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                                .col-14.col-md-7(v-if="v$.form.confirmPassword")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                                            :type="showPassword ? 'text' : 'password'"
                                            v-model="v$.form.confirmPassword.$model"
                                            placeholder="Повторите пароль"
                                            autocomplete="nope"
                                        )
                                        span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                                            app-svg-icon(v-if="showPassword" :name="'eye-open'")
                                            app-svg-icon(v-else :name="'eye-close'")

                                    ul.form__error
                                        li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}
                                //new

                                //- .col-14.col-md-7(v-if="v$.form.snils")
                                //-     label.form__label
                                //-         input(
                                //-             :class="['form__field', { 'is-error': v$.form.snils.$errors.length }]"
                                //-             type="text"
                                //-             v-mask="'###-###-###-##'"
                                //-             v-model="v$.form.snils.$model"
                                //-             placeholder="СНИЛС"
                                //-         )
                                //-     ul.form__error
                                //-         li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                                //-             template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                .col-14.col-md-7
                                    .form__file.d-flex.align-items-center
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onAvatarSelected"
                                            @click="v$.$touch()"
                                            ref="avatarInput"
                                            accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                                        )

                                        div(
                                            v-if="form.avatar"
                                            class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                                        )
                                            app-loading(
                                                v-if="avatar.isCropping"
                                                :type="'inline'"
                                                :classes="'flex-shrink-0 me-3'"
                                            )
                                            span.flex-shrink-0.me-3(v-else)
                                                app-svg-icon(name="image")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.avatar.name }}
                                                li.mt-1 {{ Number(form.avatar.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearAvatarInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                app-svg-icon(name="trash")

                                        .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите фотографию<br>пользователя

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                                            @click="$refs.avatarInput.click()"
                                        )
                                            app-svg-icon(name="clip")

                                .col-14.col-md-7
                                    .form__file.d-flex.align-items-center
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onSignatureSelected"
                                            @click="v$.$touch()"
                                            ref="signatureInput"
                                            accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                                        )

                                        div(
                                            v-if="form.signature"
                                            class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                                        )
                                            span.flex-shrink-0.me-3
                                                app-svg-icon(name="image")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.signature.name }}
                                                li.mt-1 {{ Number(form.signature.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearSignatureInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                app-svg-icon(name="trash")

                                        .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите образец<br>подписи пользователя

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                                            @click="$refs.signatureInput.click()"
                                        )
                                            app-svg-icon(name="clip")

                        app-tab(:is-selected="activeTab === 'several'")
                            app-error-message(
                                v-if="localValidationErrors"
                                :message="'Не удалось разобрать csv файл. Обратите внимание, что кодировка должна быть UTF-8'"
                                :classes="'mb-3'"
                            )
                            .row.align-items-center.justify-content-center.g-5
                                //.col-auto
                                    button(
                                        type="button"
                                        class=['link--accent', 'text-size--small text-decoration--underline']
                                    )
                                        span Скачать файл для заполнения.csv

                                .col-14(v-if="v$.form.file")
                                    .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onFileSelected"
                                            @click="v$.$touch()"
                                            ref="fileInput"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        )
                                        div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                                            span.flex-shrink-0.me-3
                                                app-svg-icon(name="document")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.file.name }}
                                                li.mt-1 {{ Number(form.file.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearFileInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                app-svg-icon(name="trash")

                                        .text-color--blue.text-size--small(v-else) Загрузите заполненный файл формата csv

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-center']
                                            @click="$refs.fileInput.click()"
                                        )
                                            app-svg-icon(name="clip")

                                    ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                                        li {{ v.errors.required }}

                        .col-14.mt-25
                            button(
                                type="submit"
                                @click="onSubmit"
                                :disabled="v$.$invalid || avatar.isCropping"
                                class=['button', 'button--accent', 'form__button']
                            )
                                span.button__content {{ isOnePatientTab ? 'Добавить пользователя' : 'Загрузить пользователей' }}

    // cropper modal
    transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.cropper" @close="closeAvatarCropper" :classes="'full-content'")
            template(v-slot:body)
                cropper(
                    class=['profile-avatar__cropper', 'mt-4']
                    :class="{'is-loading': avatar.isCropping}"
                    :src="avatar.cropperImg"
                    :stencil-props="{aspectRatio: 1/1}"
                    :stencil-component="$options.components.CircleStencil"
                    ref="cropper"
                )
                .row.align-items-center.justify-content-center.mt-4.g-4
                    .col-auto
                        button(
                            type="button"
                            class=['button button--blue']
                            :disabled="avatar.isCropping"
                            @click="onAvatarCropping"
                        )
                            span.button__content Сохранить

                    .col-auto
                        button(
                            type="button"
                            class=['button button--border-blue']
                            :disabled="avatar.isCropping"
                            @click="closeAvatarCropper"
                        )
                            span.button__content Отмена
    // success modal
    transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
            template(v-slot:body)
                template(v-if="isOnePatientTab")
                    .text-center.pt-4
                        app-title(:type="'large'") Пользователь добавлен

                        .row.justify-content-center.mt-3
                            .col-14.col-xl-10
                                p.text-color--blue.text-size--small Пользователь может авторизоваться в приложении с помощью номера телефона или e-mail и указанного пароля.

                    .modal__patient-card.mt-10.mb-20
                        .modal__patient-card-status
                            span.text-color--success
                                app-svg-icon(name="allright")

                        .row.g-5
                            .col-auto
                                .d-flex.flex-column
                                    app-avatar(
                                        :localUrl="avatar.croppedImg"
                                        :classes="'middle'"
                                        :viewPhoto="true"
                                        @openGalley="openGallery"
                                    )
                                    app-signature(
                                        v-if="form.signature"
                                        :localUrl="signature.localUrl"
                                        :theme="'shadow'"
                                        :classes="'mt-4'"
                                        @openGalley="openGallery"
                                    )
                            .col
                                .row.g-4
                                    .col-7
                                        .text-color--blue.mb-2 Ф.И.О.
                                        span {{ form.lastName }} {{ form.firstName }} {{ form.middleName }}
                                    .col-7
                                        .text-color--blue.mb-2 Водительское удостоверение
                                        span {{ form.drivingLicense }} от {{ toLocalDate(form.licenseDate) }}
                                    .col-7
                                        .text-color--blue.mb-2 Дата рождения
                                        span {{ toLocalDate(form.birthdate) }}
                                    .col-7
                                        .text-color--blue.mb-2 Пол
                                        span {{ form.gender == '1' ? 'Мужской' : 'Женский' }}
                                    .col-7
                                        .text-color--blue.mb-2 Телефон
                                        span {{ form.phone }}
                                    .col-7
                                        .text-color--blue.mb-2 Почта
                                        span {{ form.email }}
                                    .col-7
                                        .text-color--blue.mb-2 СНИЛС
                                        span {{ form.snils }}
                                    .col-7
                                        .text-color--blue.mb-2 Табельный номер
                                        span {{ form.personal_number }}

                template(v-else)
                    button(
                        @click="backToForm"
                        type="button"
                        class=['d-flex', 'modal__back-button']
                    )
                        app-svg-icon(name="back-arrow")

                    template(v-if="successData")
                        .row.justify-content-center.pt-4.mb-5
                            .col-14.col-xl-10
                                .text-center
                                    app-title(:type="'large'") {{ declOfNum(successData.upload, ['Загружен', 'Загружено', 'Загружено']) }}&nbsp;{{ successData.upload }} {{ declOfNum(successData.upload, ['пользователь', 'пользователя', 'пользователей']) }}&nbsp;из&nbsp;{{ successData.total }}

                                    p.text-size--small.text-color--blue.my-20 Подробности о том, какие пользователи не были загружены, содержатся в файле по ссылке ниже

                                    ul.text-size--small.text-color--danger.my-20(v-if="successData.critical_error")
                                        li.mb-1(v-for="(error, i) in successData.critical_error" :key="i")
                                            span {{ error }}

                                    button(
                                        type="button"
                                        @click="exportReport"
                                        class=['link--accent', 'text-decoration--underline']
                                    )
                                        span.text-size--small Скачать и посмотреть отчет о добавлении

                .d-flex
                    button(
                        @click="closeSuccessModal"
                        type="button"
                        class=['button button--border-blue', 'w-100']
                    )
                        span.button__content Перейти ко всем пользователям
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import {eventBus} from '@/main';
import variables from '@/helpers/variables';
import {
  declOfNum,
  convertDate,
  convertPhone,
  toLocalDate,
  urlToFile,
  exportCSVFile,
} from '@/helpers/utils';

import useVuelidate from '@vuelidate/core';
import {required, sameAs, email, minLength} from '@vuelidate/validators';

import {EXIF} from 'exif-js';
import is from 'is_js';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import {CircleStencil, Cropper} from 'vue-advanced-cropper';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTabNav from '@/components/common/TabNav.vue';
import AppTab from '@/components/common/Tab.vue';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';

import AppAvatar from '@/components/profile/common/Avatar';
import AppSignature from '@/components/profile/common/Signature';

import {mask} from 'vue-the-mask';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppCreatePatientsModal',
  directives: {
    mask,
  },
  components: {
    AppSignature,
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppAvatar,
    AppTab,
    AppTabNav,
    AppModal,
    AppTitle,
    AppSvgIcon,
    DatePicker,
    Cropper,
    CircleStencil,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      declOfNum,
      exportCSVFile,
      v: variables,
      isModalVisible: {
        cropper: false,
        success: false,
      },
      form: {
        firstName: '',
        lastName: '',
        middleName: '',
        drivingLicense: '',
        birthdate: '',
        gender: '1',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        avatar: null,
        signature: null,
        file: null,
        snils: '',
        licenseDate: '',
        personal_number: '',
      },
      activeTab: 'one',
      showPassword: false,
      localValidationErrors: null,
      avatar: {
        uploadFile: null,
        cropperImg: null,
        croppedImg: null,
        isCropping: false,
        exifdata: {},
      },
      signature: {
        localUrl: null,
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      successData: null,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
    isOnePatientTab() {
      return this.activeTab === 'one';
    },
    defaultDate() {
      return new Date().setYear(this.datepicker.defaultValue.year);
    },
  },
  validations() {
    if (this.isOnePatientTab) {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          drivingLicense: {
            required,
            minLength: minLength(12),
          },
          birthdate: {
            required,
          },
          licenseDate: {
            required,
          },
          email: {
            required,
            email,
          },
          phone: {
            required,
            minLength: minLength(18),
          },
          snils: {
            required,
            validateSnils(snils) {
              snils = snils.replace(/-/g, '');
              console.log('sniiil', snils);
              var result = false;
              if (typeof snils === 'number') {
                snils = snils.toString();
              } else if (typeof snils !== 'string') {
                snils = '';
              }
              if (!snils.length) {
                console.log('СНИЛС пуст');
              } else if (/[^0-9]/.test(snils)) {
                console.log('СНИЛС может состоять только из цифрт');
                //error.message = 'СНИЛС может состоять только из цифр';
              } else if (snils.length !== 11) {
                console.log('СНИЛС может состоять только из 11 цифр');
                //error.message = 'СНИЛС может состоять только из 11 цифр';
              } else {
                var sum = 0;
                for (var i = 0; i < 9; i++) {
                  sum += parseInt(snils[i]) * (9 - i);
                }
                var checkDigit = 0;
                if (sum < 100) {
                  checkDigit = sum;
                } else if (sum > 101) {
                  checkDigit = parseInt(sum % 101);
                  if (checkDigit === 100) {
                    checkDigit = 0;
                  }
                }
                if (checkDigit === parseInt(snils.slice(-2))) {
                  result = true;
                } else {
                  //error.code = 4;
                  console.log('Неправильное контрольное число');
                  //	error.message = 'Неправильное контрольное число';
                }
              }
              console.log('true');
              return result;
            },
          },
          password: {
            required,
            // valid: function (value) {
            //     return this.v.regexes.password.test(value);
            // },
            // containsUppercase: function (value) {
            //     return /[A-Z]/.test(value);
            // },
            // containsLowercase: function (value) {
            //     return /[a-z]/.test(value);
            // },
            // containsNumber: function (value) {
            //     return /[0-9]/.test(value);
            // },
            // minLength: minLength(6),
          },
          confirmPassword: {
            required,
            sameAsPassword: sameAs(this.form.password),
          },
        },
      };
    } else {
      return {
        form: {
          file: {
            required,
          },
        },
      };
    }
  },
  mounted() {
    eventBus.$on('openGallery', (data) => {
      this.openGallery(data);
    });
  },
  methods: {
    getMetaDataImage(file, variable) {
      if (file) {
        EXIF.getData(file, () => {
          this[variable].exifdata = file.exifdata;
        });
      }
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.resetForm();
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    onAvatarSelected({target}) {
      const reader = new FileReader();

      this.avatar.uploadFile = target.files[0];
      this.getMetaDataImage(this.avatar.uploadFile, 'avatar');

      reader.readAsDataURL(this.avatar.uploadFile);
      reader.onload = () => {
        this.avatar.cropperImg = reader.result;
        this.openModal('cropper');
      };
    },
    onAvatarCropping() {
      const {canvas} = this.$refs.cropper.getResult();

      this.avatar.croppedImg = canvas.toDataURL();
      this.avatar.isCropping = true;

      urlToFile(
        this.avatar.croppedImg,
        this.avatar.uploadFile.name,
        this.avatar.uploadFile.type
      ).then((file) => {
        file.exifdata = this.avatar.exifdata;
        this.form.avatar = file;
        this.avatar.isCropping = false;
        this.closeModal('cropper');
      });
    },
    closeAvatarCropper() {
      this.closeModal('cropper');
      this.clearAvatarInput();
    },
    onSignatureSelected({target}) {
      const reader = new FileReader();

      this.form.signature = target.files[0];

      reader.readAsDataURL(this.form.signature);
      reader.onload = () => (this.signature.localUrl = reader.result);
    },
    clearAvatarInput() {
      this.form.avatar = null;
      if (this.$refs.avatarInput) this.$refs.avatarInput.value = '';
    },
    clearSignatureInput() {
      this.form.signature = null;
      if (this.$refs.signatureInput) this.$refs.signatureInput.value = '';
    },
    clearFileInput() {
      this.form.file = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = '';
    },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableBeforeAdult(date) {
      const today = new Date();

      today.setFullYear(today.getFullYear() - 18);
      // today.setMonth(8);
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableAfterTodayDrive(date) {
      const start = new Date(1930, 1, 1);
      // const end = new(Date)
      start.setHours(0, 0, 0, 0);

      return date < start;
    },
    close() {
      this.resetForm();
      this.$emit('close');
    },
    success(data) {
      if (!this.isOnePatientTab) this.successData = data;

      this.openModal('success');
      this.$emit('success');
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.resetForm();
      this.$toast.success('Пользователь добавлен');
    },
    resetForm() {
      this.v$.$reset();

      for (let key in this.form) {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          this.form[key] = '';
        }
      }
      this.form.gender = '1';
      this.localValidationErrors = null;

      this.clearFileInput();
      this.clearAvatarInput();
      this.clearSignatureInput();
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();

        if (this.isOnePatientTab) {
          formData.append('first_name', this.form.firstName);
          formData.append('last_name', this.form.lastName);
          formData.append('middle_name', this.form.middleName);

          formData.append('gender', this.form.gender);
          formData.append('serial_number_driving_license', this.form.drivingLicense);
          formData.append('password', this.form.password);
          formData.append('date_birth', convertDate(this.form.birthdate));
          formData.append('email', this.form.email);
          formData.append('phone', convertPhone(this.form.phone));
          formData.append('snils', this.form.snils);
          formData.append('personal_number', this.form.personal_number);
          formData.append('driving_license_receiving', convertDate(this.form.licenseDate));

          if (is.not.null(this.form.avatar))
            formData.append('avatar', this.form.avatar, this.form.avatar.name);
          if (is.not.null(this.form.signature))
            formData.append('signature', this.form.signature, this.form.signature.name);
        } else {
          formData.append('import_file', this.form.file, this.form.file.name);
        }

        this.$store
          .dispatch(
            patientsActionTypes[this.isOnePatientTab ? 'createPatients' : 'importPatients'],
            formData
          )
          .then((result) => {
            this.$emit('close');
            this.success(result);
          })
          .catch(() => (this.localValidationErrors = this.validationErrors));
      }
    },
    exportReport() {
      exportCSVFile(this.successData.result);
    },
    backToForm() {
      this.closeSuccessModal();
      this.$emit('open');
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &::v-deep .app-datepicker .app-input {
    color: get($colors, black);
  }
}

.modal {
  &__patient-card {
    position: relative;
    @include font-size(get($text-sizes, small));
    padding: $spacer * 1.6;
    color: get($colors, black);
    background: get($gradients, blue-extra-light);
    border-radius: $border-radius-sm;

    &-status {
      position: absolute;
      top: $spacer;
      right: $spacer;
    }
  }
}
</style>
