<template lang="pug">
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
        app-title(:type="'large'" :id="'isAnchorForBtn'") Поверка устройств
      template
      .col-12
        NavDevice

  .profile-devices__content.mt-20.position-relative
    transition(name="translucent")
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      div(v-if="devices")
        template(v-if="devices && devices.length")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    type="button"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key && column.key !== 'attestation' }"
                    @click="sortingDevices(column.key, column.inverse)"
                  )
                    span.d-flex.align-items-center
                      span {{ column.caption }}
                      span.d-flex.ms-3
                        app-svg-icon(name="down-arrow")

            ul.profile-table__body
              li.profile-table__row(v-for="device in devices" :key="device.serial")
                section.profile-card
                  .row.align-items-md-center.gy-4.gy-md-0
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Серийный номер
                      span(v-if="device.serial") {{ device.serial }}
                      span(v-else) &#8212;
                    .col-2
                      .text-color--blue.mb-2.d-xl-none
                      span.text-color--blue-dark(v-if="device.device_verification_owner") {{device.device_verification_owner }}
                      span(v-else) &#8212;
                    .col-2
                      .text-color--blue.mb-2.d-xl-none
                      span(v-if="device.device_attestation_start") {{ toLocalDate(device.device_attestation_start) }}
                      span(v-else) &#8212;
                    .col-2
                      .text-color--blue.mb-2.d-xl-none
                      span(v-if="device.device_attestation_end") {{toLocalDate(device.device_attestation_end)}}
                      span(v-else) &#8212;
                    .col-3
                      .text-color--blue.mb-2.d-xl-none
                      span(v-if="device.device_attestation") {{device.device_attestation}}
                      span(v-else) &#8212;
                    .col-2.d-flex.justify-content-between.align-items-center
                      span.text-color--orange(v-if="device.attestation === 'attestation_ends'") Заканчивается
                      span.text-color--orange(v-if="device.attestation === 'not_attestation'") Не поверено
                      span.text-color--blue-dark(v-if="device.attestation === 'attestation'") Поверено

                      button(
                      type="button"
                      @click.prevent="editModal(device)"
                      class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                        app-svg-icon(name="edit")

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              app-svg-icon(name="wrong")
            span Нет устройств

  app-edit(
    :is-visible="isModalVisible.edit"
    @close="closeModal('edit')"
    :toEdit="toEdit"
    )
  // filter
  filter-check(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="closeFilter"
    @clear="clearFilter"
    @submit="submitFilter"
    @getCount="getCountFilter"
  )

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import variables from '@/helpers/variables';
import {declOfNum, blobToFile, toLocalDate} from '@/helpers/utils';
import devicesApi from '@/api/devices';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import FilterCheck from '@/components/profile/common/FilterCheck';
import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {modalsMixin} from '@/mixins/modals';
import {filterMixin} from '@/mixins/filter';

import NavDevice from '@/components/profile/common/NavDevice';

import AppEdit from '@/components/profile/admin/devices/AppDeviceCheckEditModal';
export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    FilterCheck,
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice,
    AppEdit,
  },
  mixins: [modalsMixin, filterMixin],
  data() {
    return {
      devices: {},
      filterString: '',
      v: variables,
      toEdit: {},
      declOfNum,
      isModalVisible: {
        create: false,
        edit: false,
      },
      filterOptions: {
        date: true,
        devices: true,
        methods: {
          getData: ['getDevices'],
          getCount: 'getCountDevices',
        },
      },
      sortKey: 'device_attestation_start',
      columns: [
        {
          class: 'col-3',
          caption: 'Серийный номер',
          key: 'serial',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Ответственный',
          key: 'device_verification_owner',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата поверки',
          key: 'device_attestation_start',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Действие до',
          key: 'device_attestation_end',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Номер свидетельства',
          key: 'device_attestation',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Поверка',
          key: 'attestation',
          inverse: false,
        },
      ],
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      error: (state) => state.devices.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices();
    },
  },
  async created() {
    this.getDevices();
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },

    async getDevices() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = '';
      if (this.sortKey === 'company_name') {
        order_by = `&order_by=${sortInverse}${this.sortKey}`;
      } else {
        order_by = `&device_order_by=${sortInverse}${this.sortKey}`;
      }
      let resultFilter = `?page=${this.currentPage - 1}${this.filterString}` + `${order_by}`;
      let res = await devicesApi.getDevicesNew(resultFilter);

      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.devices = res.data.items;
    },
    sortingDevices(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getDevices();
      }
    },
    getCountDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };
      this.$store.dispatch(devicesActionTypes.getCountDevices, data);
    },
    exportDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };
      this.$store
        .dispatch(devicesActionTypes.exportDevices, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    closeModal(slug) {
      this.isModalVisible[slug] = false;
    },
    editModal(data) {
      this.isModalVisible.edit = true;
      this.toEdit = data;
    },
    async submitFilter(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getDevices();
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  .profile-card {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    padding: 0;
  }
  &-table {
    &__row {
      position: relative;
      padding: 0rem 1.5625rem 0rem 1rem;
      background-color: #ffffff;
      font-size: 0.75rem;
    }
  }
}
</style>
