<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="close")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Замена фотографии для пользователя<br>{{ patient.full_name }}
            p.text-size--small.text-color--blue.mt-3 Фотография должна быть размером не менее 300*300 px, формата JPEG или PNG, на фото должно быть четко видно лицо пользователя без лишних деталей. Подставьте лицо приблизительно в рамки

          .row.justify-content-center
            .col-14.col-xl-10
              form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
                app-validation-errors(
                  v-if="localValidationErrors"
                  :validation-errors="localValidationErrors"
                  :classes="'mb-3'"
                )
                .row.align-items-center.flex-wrap.g-5
                  .col-14
                    .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                      input(
                        class="d-none"
                        type="file"
                        name="document"
                        @change="onFileSelected"
                        @click="v$.$touch()"
                        ref="fileInput"
                        accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                      )
                      div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                        span.flex-shrink-0.me-3
                          app-svg-icon(name="image")

                        ul.flex-grow-1.overflow-hidden
                          li.form__file-name.text-color--black {{ form.file.name }}
                          li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                        button(
                          type="button"
                          @click="clearFileInput"
                          class=['form__file-delete', 'flex-shrink-0 ms-3']
                        )
                          app-svg-icon(name="trash")

                      .text-color--blue.text-size--small(v-else) Загрузите новую<br>фотографию пользователя

                      button(
                        class=['form__file-button', 'd-flex justify-content-end align-items-center']
                        @click="$refs.fileInput.click()"
                      )
                        app-svg-icon(name="clip")

                    ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                      li {{ v.errors.required }}

                  .col-14
                    button(
                      type="submit"
                      @click="onSubmit"
                      :disabled="v$.$invalid"
                      class=['button', 'button--accent', 'form__button']
                    )
                      span.button__content Готово

    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.cropper" @close="closeCropperModal" :classes="'full-content'")
        template(v-slot:body)
          cropper(
            ref="cropper"
            class=['profile-avatar__cropper', 'mt-4']
            :class="{'is-loading': isSubmitting}"
            :src="cropperImg"
            :stencil-props="{aspectRatio: 1/1}"
            :stencil-component="$options.components.CircleStencil"
          )
          .row.align-items-center.justify-content-center.mt-4.g-4
            .col-auto
              button(
                type="button"
                class=['button button--blue']
                @click="onCropping"
              )
                span.button__content Сохранить

            .col-auto
              button(
                type="button"
                class=['button button--border-blue']
                @click="closeCropperModal"
              )
                span.button__content Отмена
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import {EXIF} from 'exif-js';
import {urlToFile} from '@/helpers/utils';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';

import {Cropper, CircleStencil} from 'vue-advanced-cropper';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppPatientChangeAvatarModal',
  components: {
    AppModal,
    AppTitle,
    AppSvgIcon,
    AppValidationErrors,
    Cropper,
    CircleStencil,
  },
  mixins: [modalsMixin],
  props: {
    patient: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      isModalVisible: {
        cropper: false,
      },
      form: {
        file: null,
      },
      uploadFile: '',
      cropperImg: '',
      isCropping: false,
      exifdata: {},
      localValidationErrors: null,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  methods: {
    close() {
      this.resetForm();
      this.$emit('close');
    },
    closeCropperModal() {
      this.closeModal('cropper');
      this.resetForm();
    },
    getMetaDataImage(file) {
      if (file) {
        EXIF.getData(file, () => {
          this.exifdata = file.exifdata;
        });
      }
    },
    onFileSelected({target}) {
      const reader = new FileReader();

      this.uploadFile = target.files[0];
      this.getMetaDataImage(this.uploadFile);

      reader.readAsDataURL(this.uploadFile);
      reader.onload = () => {
        this.cropperImg = reader.result;
        this.openModal('cropper');
      };
    },
    onCropping() {
      const {canvas} = this.$refs.cropper.getResult();

      this.croppedImg = canvas.toDataURL();
      this.isCropping = true;

      urlToFile(this.croppedImg, this.uploadFile.name, this.uploadFile.type).then((file) => {
        file.exifdata = this.exifdata;
        this.form.file = file;
        this.isCropping = false;
        this.closeModal('cropper');
      });
    },
    clearFileInput() {
      this.form.file = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = '';
    },
    resetForm() {
      this.v$.$reset();
      this.localValidationErrors = null;
      this.clearFileInput();
    },
    onSubmit() {
      const formData = new FormData();
      formData.append('avatar', this.form.file, this.form.file.name);
      formData.append('code', this.patient.code);

      this.$store
        .dispatch(patientsActionTypes.setPatientAvatar, formData)
        .then(() => {
          this.resetForm();
          this.$emit('success');
        })
        .catch(() => (this.localValidationErrors = this.validationErrors));
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &::v-deep .form__file-element {
    svg {
      width: $spacer * 2.4;
      height: $spacer * 2.4;
    }
  }
}
</style>
