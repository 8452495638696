<template lang="pug">
  .error-message(:class="classes") {{ errorMessage }}
</template>

<script>
export default {
  name: 'AppValidationErrors',
  props: {
    validationErrors: {
      type: String,
      required: true,
      default: '',
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    errorMessage() {
      let message;

      switch (this.validationErrors) {
        case 'user_or_pass_incorrect':
          message = 'Неверный e-mail или пароль';
          break;

        case 'user_not_found':
          message = 'Неверный e-mail или пароль';
          break;

        case 'not_approved':
          message = 'Дождитесь рассмотрения заявки';
          break;

        case 'Password is malformed':
          message =
            'Пароль должен быть не короче 6 символов и содержать цифры и буквы верхнего и нижнего регистра';
          break;

        case 'Operation not authorized':
          message = 'Старый пароль введен неверно';
          break;

        case 'request not found':
          message = 'Заявки с таким e-mail не существует';
          break;

        case 'not found':
          message = 'Заявка не найдена';
          break;

        case 'Request is not pending':
          message = 'Данная ссылка уже была использована ранее';
          break;

        case 'Such an email is already taken':
          message = 'Данный e-mail уже зарегистрирован';
          break;

        case 'No access to the patient.':
          message = 'Нет доступа к пациенту';
          break;

        case 'Such an email or phone is already taken':
          message = 'Данный e-mail или номер телефона уже зарегистрирован';
          break;

        case 'Email or phone must be filled.':
          message = 'Поле e-mail или номер телефона должно быть заполнено';
          break;

        case 'Such an serial number driving license is already taken':
          message = 'Данное водительское удостоверение уже зарегистрировано';
          break;

        case 'Such an INN number is already taken':
          message = 'Данный ИНН уже зарегистрирован';
          break;

        case 'Email is not registered':
          message = 'Данный e-mail не зарегистрирован';
          break;

        case 'device_exists':
          message = 'Заявка уже существует';
          break;

        case 'device_already_exists':
          message = 'Устройство уже существует';
          break;

        case 'Device not found':
          message = 'Устройство было удалено ранее';
          break;

        case 'device_double':
          message = 'Устройства дублируются';
          break;

        default:
          message = 'Внутренняя ошибка сервера, попробуйте позже';
      }

      return message;
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  color: get($colors, danger);
  @include font-size(get($text-sizes, small));
}
</style>
