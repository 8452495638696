<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center.g-0
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition(name="translucent")
            button(
              type="button"
              @click="exportExams(filters)"
              class=['link--accent']
            )
      .row.align-items-center.g-0
        .col-8.p-0
          nav-notices
        .col.col-md-auto.ms-md-auto
          button(
          type="button"
          class=['button button--border-blue']
          @click="openModal('loadDoc')"
          )
            span.button__content
            span ЗАГРУЗИТЬ ДОКУМЕНТ

  .profile-patients__content.mt-20.position-relative
    transition-group(name="translucent")
      app-loading(v-if="isLoading" key="loading")
      app-error-message(v-if="error" :message="error" key='error')
      div(v-if="incomingMedDocs && incomingMedDocs.length" key="hasOutgoingMedDocs")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center.g-4
              div(v-for="column in columns" :key="column.key" :class="column.class" )
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  @click="sortingPatients(column.key, column.inverse)"
                  class=['profile-table__sort-button']
                  :class="{'is-active': sortKey === column.key}"
                )
                  span.d-flex.align-items-center
                    span.text-overflow(v-if="column.caption") {{ column.caption }}
                    span.flex-shrink-0.d-flex.ms-3
                      app-svg-icon(name="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for='item in incomingMedDocs')
                client-incoming-notice(
                  :data="item"
                  :openShowModal="openShowModal"
                )
          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
      div(v-else-if="!isLoading" key="NoMedDocs")
        .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-incoming(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
  // create patients modal
  load-doc-modal(
    :is-visible="isModalVisible.loadDoc"
    @open="openModal('loadDoc')"
    @close="createDoc"
    @openGallery="openGallery"
    @success="getPatients()"
  )
  // create patients modal
  show-doc-modal(
    :is-visible="isShowDataVisible"
    :closeModal="closeShowModal"
    :data="currentDoc"
  )


</template>

<script>
import incomingMedDocs from '@/api/incomingMedDocs';
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import ClientIncomingNotice from '@/components/profile/client/patients/ClientIncomingNotice';

import AppFilter from '@/components/FilterPageNotices';
import AppFilterPageIncoming from '@/components/FilterPageIncoming';
import ClientNotice from '@/components/profile/client/patients/ClientPatientNotice';
import ModalNotice from '@/components/profile/common/patients/ModalNotice';
import NavNotices from '@/components/profile/common/NavNotices';
import {filterMixin} from '@/mixins/filter';
import {galleryMixin} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';

import LoadDocModal from '@/components/profile/client/docs/LoadDocModal';
import ShowDocModal from '@/components/profile/client/docs/ShowDocModal';

export default {
  name: 'PageNotices',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
    ClientNotice,
    ModalNotice,
    NavNotices,
    LoadDocModal,
    ClientIncomingNotice,
    ShowDocModal,
    AppFilterPageIncoming,
  },
  mixins: [filterMixin, galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isLoading: false,
      incomingMedDocs: {},
      isFilterOpen: false,
      isShowDataVisible: false,
      filterString: '',
      sortKey: 'id',
      currentDoc: {},
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortInverse: true,
      columns: [
        {
          class: 'col-auto',
          caption: '№',
          key: 'id',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Статус',
          key: 'document_status',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата и время',
          key: 'create_datetime',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата выдачи',
          key: 'issue_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Срок действия',
          key: 'expiration_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата и время подтверждения',
          key: 'verification_datetime',
          inverse: true,
        },
        {
          class: 'col-auto',
          caption: 'Просмотр',
          key: 'view',
          inverse: false,
        },
      ],
      results: [],
      isModalVisible: {
        notice: false,
        loadDoc: false,
      },
      successMessage: 'Успех!',
    };
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  watch: {
    currentPage() {
      this.getIncomingDocs();
    },
  },
  created() {
    this.getIncomingDocs();
  },
  methods: {
    closeShowModal() {
      this.isShowDataVisible = false;
    },
    openShowModal(data) {
      console.log(data);
      this.currentDoc = data;
      this.isShowDataVisible = true;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    async getIncomingDocs() {
      let sortInverse = '+';
      if (this.sortInverse) {
        sortInverse = '-';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `page=${this.currentPage - 1}&per_page=10${
        this.filterString
      }&incoming_documents_order_by=${order_by}`;

      this.isLoading = true;
      let res = await incomingMedDocs.getIncomingMedDocumentV2(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.incomingMedDocs = res.data.items;
      this.isLoading = false;

      console.log(this.incomingMedDocs);
    },
    async sortingPatients(sortKey, inverse) {
      if (sortKey === 'view') {
        return;
      }
      console.log(sortKey, inverse);
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getIncomingDocs();
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getIncomingDocs();
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(actionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    successChangePatient(slug) {
      this.successMessage = `Фотография успешно ${
        slug === 'changeAvatar' ? 'изменена' : 'удалена'
      }!`;

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');

      this.getPatients();
    },
    async createDoc() {
      console.log('123');
      let data = await incomingMedDocs.getIncomingMedDocument({});
      console.log(data);
      this.results = data.data.result;
      this.pagination.total = data.data.total;
      this.closeModal('loadDoc');
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
  },
};
</script>
