<template lang="pug">
.patient-exams-card
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showExams"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Последние осмотры пользователя
  // patient exams
  .position-relative
    //- app-loading(v-if="examsLoading")
    //- app-error-message(v-if="examsError" :message="examsError")

    transition(name="fade" mode="out-in")
    .patient-exams-card__wrapper
    .mt-15(v-if="showInfo && exams.length")
      section(class=['patient-exams', 'profile-card'])(v-if="showInfo && exams.length")
        .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
          .row
            .col
              span.mb-3.text-color--blue Владелец устройств:&ensp;
              span.text-color--blue-dark(v-if="data_detail.device_owner_full_name") {{ data_detail.device_owner_full_name }}
              span(v-else) &#8212;

        ul.patient-exams__list(id="patient-exams")
          li.patient-exams__list-el(v-for="exam in exams" :key="exam.exam_id")
            app-patient-exam-card(
              :parent-id="'patient-exams'"
              :classes="'d-none d-xl-block'"
              :data="exam"
              @openModal="openExamDetailModal"
              @openGallery="openGallery"
              :openCertificate="openCertificate"
            )

            app-exam-card-mobile(
              :data="exam"
              :type="'patient-detail'"
              :classes="'d-xl-none'"
              @openModal="openExamDetailMobile"
            )

          //- button(
          //-   v-if="offset < exams.length"
          //-   type="button"
          //-   @click="offset += pagination.limit"
          //-   class=['text-size--small link--accent', 'mt-4 me-4 text-color--blue-dark']
          //- ) Показать еще (+{{ (exams.length - offset) >= pagination.limit ? pagination.limit : exams.length - offset}})

          //- button(
          //-   v-if="offset > pagination.limit"
          //-   type="button"
          //-   @click="offset = pagination.limit"
          //-   class=['text-size--small link--accent', 'mt-4 text-color--blue-dark']
          //- ) Свернуть

      .mt-5.text-color--blue.text-size--small(v-else) Осмотров не найдено

</template>

<script>
import examsApi from '@/api/exams';
import patients from '@/api/patients';

import AppPatientExamCard from '@/components/profile/common/patients/PatientExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppCertificateModal from '@/components/profile/common/CertificateModal';

export default {
  name: 'AppPatientExams',
  components: {
    AppPatientExamCard,
    AppExamCardMobile,
    AppCertificateModal,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    openCertificate: {
      type: Function,
    }
  },
  data() {
    return {
      showInfo: false,
      exams: {},
      data_detail: {},
      pagination: {
        limit: 3,
        offset: 3,
      },
      offset: 3,
    };
  },
  created() {
    this.getExams();
  },
  methods: {
    async showExams() {
      this.showInfo = !this.showInfo;
    },
    async getExams() {
      let string = `${this.userId}?page=0&per_page=10&exams_order_by=-exam_datetime`;
      let data = await patients.getPatientExams(string);
      this.exams = data.data.items;
      this.getDetailExam();
    },
    async getDetailExam() {
      console.log(this.exams);
      let examDetailData = await examsApi.getExamDetail(this.exams[0].exams_id);
      this.data_detail = examDetailData.data;
      console.log(this.data_detail.device_owner_full_name);
    },
  },
};
</script>

<style lang="scss">
.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}
</style>
