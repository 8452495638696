<template lang="pug">
  .profile-dashboard__content
    .mb-5(v-if="notifications && notifications.length")
      transition-group(name="fade" tag="div" class="d-flex flex-column")
        app-notification(
          v-for="(notification, index) in notifications" :key="notification.id"
          :data="notification"
          @delete="deleteNotification(notification.id, index)"
        )

    // exams
    .position-relative.mb-5
      app-loading(v-if="examsLoading")
      app-error-message(v-if="examsError" :message="examsError")
    
      transition(name="translucent")
        app-dashboard-card(
          v-if="exams"
          :goTo="'exams'"
          :classes="`${exams && exams.result && exams.result.length === 0 ? 'is-empty' : ''}`"
        )
          template(v-slot:title)
            .d-flex.align-items-center.mb-4(v-if="exams.result && exams.result.length")
              span.flex-shrink-0.text-color--blue.me-3.d-xl-none
                app-svg-icon(name="smartwatch" view-box="0 0 17 24")
              span Последние данные с&nbsp;устройств
            
            .text-color--gray-light(v-else) Здесь будут отображаться последние данные с&nbsp;устройств
    
          template(v-slot:body)
            template(v-if="exams.result && exams.result.length")
              .profile-table.d-none.d-xl-block
                .profile-table__header
                  .row.align-items-center.g-4
                    div(v-for="column in columns.exams" :key="column.key" :class="column.class")
                      button(
                        v-if="column.caption || column.icon"
                        type="button"
                        @click="sortingExams(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            app-svg-icon(name="down-arrow")
      
      
                ul.profile-table__body(id="dashboard-exams")
                  li.profile-table__row(v-for="exam in exams.result.slice(0, 3)" :key="exam.id")
                    app-exam-card(
                      :data="exam"
                      :parent-id="'dashboard-exams'"
                      :columns="columns.exams"
                      @openModal="openExamDetailModal"
                      @openCertificate="openCertificate"
                    )
      
              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  :options="Object.assign({}, slider.common, slider.exams)"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="exam in exams.result.slice(0, 5)" :key="exam.id")
                    app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")
      
                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])
            
            
            .text-size--small.text-color--gray-light(v-else) Пока нет осмотров
          
          template(v-slot:button)
            template(v-if="exams && exams.result && exams.result.length") Все осмотры


    // patients
    .position-relative.mb-5
      app-loading(v-if="patientsLoading")
      app-error-message(v-if="patientsError" :message="patientsError")
    
      transition(name="translucent")
        app-dashboard-card(
          v-if="patients"
          :goTo="'patients'"
          :classes="`${patients && patients.result && patients.result.length === 0 ? 'is-empty' : ''}`"
        )
          template(v-slot:title)
            .d-flex.align-items-center.mb-4(v-if="patients.result && patients.result.length")
              span.flex-shrink-0.text-color--blue.me-3.d-xl-none
                app-svg-icon(name="users")
              span Последние добавленные пользователи
            
            .text-color--gray-light(v-else) Здесь будут отображаться последние добавленные пользователи
    
          template(v-slot:body)
            template(v-if="patients.result && patients.result.length")
              .profile-table.d-none.d-xl-block
                .profile-table__header
                  .row.align-items-center.g-4
                    div(v-for="column in columns.patients" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingPatients(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey.patients === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.d-flex.flex-shrink-0.ms-3
                            app-svg-icon(name="down-arrow")
      
                ul.profile-table__body(id="patient-exams")
                  li.profile-table__row(v-for="patient in patients.result.slice(0, 3)" :key="patient.code")
                    app-client-patient-card(
                      :data="patient"
                      @openModal="openExamDetailModal"
                      @openGallery="openGallery"
                      @openCertificate="openCertificate"
                      @delete="openDeletePatientModal"
                      @change="openChangePatientModal"
                      @changeAvatar="openChangeAvatarModal"
                      @changeSignature="openChangeSignatureModal"
                    )
      
      
              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  :options="Object.assign({}, slider.common, slider.patients)"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="patient in patients.result.slice(0, 3)" :key="patient.code")
                    router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block'])
                      app-admin-patient-card(:data="patient" @openModal="openExamDetailModal")
      
                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-patients-pagination'])

            
            .text-size--small.text-color--gray-light(v-else) Пока нет пользователей
          
          template(v-slot:button)
            template(v-if="patients && patients.result && patients.result.length") Все пользователи
    
    button(
      type="button"
      class=['button button--blue', 'mt-5 w-100', 'd-xl-none']
      @click="isModalVisible.createDevice = true"
    )
      span.button__content
        span.d-flex.flex-shrink-0.me-3
          app-svg-icon(name="plus-thin")
        span Добавить устройства


    // create device
    app-create-device-modal(
      :is-visible="isModalVisible.createDevice"
      @close="closeModal('createDevice')"
      @success="openModal('successDeviceCreated')"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
      @openEditModal="openModal"
      @openDeleteModal="openModal"
    )
    // change patient modal
    app-change-patient-modal(
      :patient="currentPatient"
      :is-visible="isModalVisible.changePatient"
      @close="closeModal('changePatient')"
      @success="getPatients"
    )
    // change avatar modal
    app-patient-change-avatar-modal(
      ref="changeAvatar"
      :patient="currentPatient"
      :is-visible="isModalVisible.changeAvatar"
      @success="successChangePatient('changeAvatar')"
      @close="closeModal('changeAvatar')"
    )
    // change signature modal
    app-patient-change-signature-modal(
      ref="changeSignature"
      :patient="currentPatient"
      :is-visible="isModalVisible.changeSignature"
      @success="successChangePatient('changeSignature')"
      @close="closeModal('changeSignature')"
    )
    // delete avatar modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить фотографию пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}
        
          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteAvatar')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять

            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteAvatar(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
        
          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
  
    // delete signature modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить подпись пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}
        
          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteSignature')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять

            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteSignature(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
        
          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
  
    // delete patient modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}
        
          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deletePatient')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять

            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePatient(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
        
          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.
  
    // success for patient modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ successMessage }}
            p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ currentPatient.full_name }}.
        
          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('success')"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад

    // success created devices modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successDeviceCreated" @close="closeModal('successDeviceCreated')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Успешно отправлено на рассмотрение
            p.text-size--small.text-color--blue.mt-3 Заявки на добавление устройств рассматриваются администраторами от 1 до 4 рабочих дней. Статус&nbsp;проверки вы можете отследить в личном кабинете.

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="$router.push({name: 'devices'})"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content В список устройств
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as notificationsActionTypes} from '@/store/modules/notifications';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppNotification from '@/components/common/Notification';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppValidationErrors from '@/components/common/ValidationErrors';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppDashboardCard from '@/components/profile/common/dashboard/DashboardCard';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppClientPatientCard from '@/components/profile/client/patients/ClientPatientCard';
import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';
import AppCreateDeviceModal from '@/components/profile/client/devices/CreateDeviceModal';

import AppChangePatientModal from '@/components/profile/common/patients/ChangePatientModal';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppPatientChangeSignatureModal from '@/components/profile/common/patients/PatientChangeSignatureModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

SwiperCore.use([Pagination]);

export default {
  name: 'AppClientDashboard',
  components: {
    AppTitle,
    AppSvgIcon,
    AppModal,
    AppGallery,
    AppNotification,
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppCertificateModal,
    AppDashboardCard,
    AppClientPatientCard,
    AppAdminPatientCard,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailModal,
    AppExamDetailMobile,
    AppCreateDeviceModal,
    AppChangePatientModal,
    AppPatientChangeAvatarModal,
    AppPatientChangeSignatureModal,
    Swiper,
    SwiperSlide,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin],
  data() {
    return {
      v: variables,
      isModalVisible: {
        changePatient: false,
        changeAvatar: false,
        changeSignature: false,
        deleteAvatar: false,
        deleteSignature: false,
        deletePatient: false,
        createDevice: false,
        successDeviceCreated: false,
      },
      sortKey: {
        exams: '',
        patients: '',
      },
      sortInverse: {
        exams: false,
        patients: false,
      },
      columns: {
        exams: [
          {
            class: 'col-4 col-xl-3 col-xxl-2',
            caption: 'Дата',
            key: 'exam_date_time',
            inverse: true,
          },
          {
            class: 'col-3 col-xxl-4',
            caption: 'Код и ФИО пользователя',
            key: 'patient_full_name',
            inverse: false,
          },
          {
            class: 'col-1',
            icon: 'pulse',
            key: 'bp_high',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'cardio',
            key: 'heart_rate',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'temp',
            key: 'body_temp',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'breathalyzer',
            key: 'alcohol',
            inverse: true,
          },
          {
            class: 'col-1',
            key: 'health_complaint',
          },
          {
            class: 'col-2 ms-auto',
            caption: 'Допуск',
            key: 'admission_to_work',
            inverse: false,
          },
        ],
        patients: [
          {
            class: 'col-3',
            caption: 'E-mail',
            key: 'email',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Телефон',
            key: 'phone',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Дата регистрации',
            key: 'creation_date',
            inverse: true,
          },
          {
            class: 'col-3',
            caption: 'ФИО пользователя',
            key: 'full_name',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Кол-во осмотров',
            key: 'exam_count',
            inverse: true,
          },
          {
            class: 'col-2',
            caption: 'Кол-во устройств',
            key: 'devices_count',
            inverse: true,
          },
        ],
      },
      slider: {
        common: {
          slidesPerView: 1,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
          },
        },
        exams: {
          pagination: {
            el: '.js-exams-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              const status = slide.querySelector('[data-status]');

              return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
            },
          },
        },
        patients: {
          pagination: {
            el: '.js-patients-pagination',
            dynamicBullets: true,
          },
        },
      },
      currentPatient: {},
      successMessage: 'Успешно',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      notifications: (state) => state.notifications.data,

      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsError: (state) => state.exams.error,

      patientsLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      patientsError: (state) => state.patients.error,
    }),
    settings() {
      return {
        exams: {
          filters: {
            users: {
              negative: false,
              payload: [this.currentUser.id],
            },
            patients: {
              negative: false,
              payload: [],
            },
          },
          sorting_params: {
            desc: this.sortInverse.exams,
            field: this.sortKey.exams,
          },
        },
        patients: {
          filters: {
            users: {
              negative: false,
              payload: [this.currentUser.id],
            },
            patients: {
              negative: false,
              payload: [],
            },
          },
          sorting_params: {
            desc: this.sortInverse.patients,
            field: this.sortKey.patients,
          },
        },
      };
    },
  },
  created() {
    this.getNotifications();
    this.getExams();
    this.getPatients();
  },
  methods: {
    getNotifications() {
      this.$store.dispatch(notificationsActionTypes.getNotifications);
    },
    deleteNotification(id, index) {
      this.$store
        .dispatch(notificationsActionTypes.deleteNotification, {id})
        .then(() => this.notifications.splice(index, 1));
    },
    getExams() {
      this.$store
        .dispatch(examsActionTypes.getExams, this.settings.exams)
        .then(() => this.reverseSorting('exams'));
    },
    getPatients() {
      this.$store
        .dispatch(patientsActionTypes.getPatients, this.settings.patients)
        .then(() => this.reverseSorting('patients'));
    },
    sortingExams(sortKey, inverse) {
      this.sortKey.exams = sortKey;
      this.sortInverse.exams = inverse;

      this.getExams();
    },
    sortingPatients(sortKey, inverse) {
      this.sortKey.patients = sortKey;
      this.sortInverse.patients = inverse;

      this.getPatients();
    },
    reverseSorting(slug) {
      if (is.not.empty(this.sortKey[slug])) {
        this.columns[slug].forEach((el) => {
          if (this.sortKey[slug] === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openDeletePatientModal(data) {
      this.currentPatient = data;
      this.openModal('deletePatient');
    },
    openChangePatientModal(data) {
      this.currentPatient = data;
      this.openModal('changePatient');
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    openChangeSignatureModal(data) {
      this.currentPatient = data;
      this.openModal('changeSignature');
    },
    deletePatient({code}) {
      this.$store
        .dispatch(patientsActionTypes.deletePatient, {
          id: code,
        })
        .then(() => {
          this.successMessage = 'Успешно удалён!';
          this.closeModal('deletePatient');
          this.openModal('success');
          this.getPatients();
        });
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    deleteSignature({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientSignature, formData).then(() => {
        this.successChangePatient('deleteSignature');
      });
    },
    successChangePatient(slug) {
      switch (slug) {
        case 'changeAvatar':
          this.successMessage = 'Фотография успешно изменена!';
          break;

        case 'changeSignature':
          this.successMessage = 'Подпись успешно изменена!';
          break;

        case 'deleteAvatar':
          this.successMessage = 'Фотография успешно удалена!';
          break;

        case 'deleteSignature':
          this.successMessage = 'Подпись успешно удалена!';
          break;
      }

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');

      this.getPatients();
    },
  },
};
</script>
