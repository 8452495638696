<template lang="pug">
.profile-exams__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-exams__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
        app-title(
          :type="'large'"
          :id="'isAnchorForBtn'"
          :classes="'profile__title text-center mt-4 mt-md-0'"
        ) Журнал осмотров
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
        .col-auto
          .d-flex.flex-column
            button(
              v-if="exams && exams.length"
              type="button"
              @click="exportExams()"
              class=['link--accent']
            )
              //- span.d-flex.align-items-center
                span.flex-shrink-0.me-3
                  app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}
        .col-auto
          .d-flex.flex-column
            button(
              v-if="exams && exams.length"
              type="button"
              @click="export266()"
              class=['link--accent']
            )
              span.d-flex.align-items-center
                span.flex-shrink-0.me-3
                  app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : ' Журналы МО по форме 266н .xlsx' }}
  .profile-exams__content.mt-20.position-relative
    transition(name="translucent")
      app-error-message(v-if="error" :message="error")
      div(v-if="exams")
        template(v-if="exams && exams.length")
          .profile-table.is-exams-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.g-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    @click="sortingExams(column.key)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span(v-if="column.caption") {{ column.caption }}
                      span.flex-shrink-0(v-else-if="column.icon")
                        app-svg-icon(:name="column.icon")
                      span.flex-shrink-0.d-flex.ms-3
                        app-svg-icon(name="down-arrow")
            ul.profile-table__body(v-if='exams' id="admin-exams")
              li.profile-table__row(v-for="exam in exams" :key="exam.id")
                app-exam-card(
                  :data="exam"
                  :parent-id="'admin-exams'"
                  :columns="columns"
                  :classes="'d-none d-xl-block'"
                  @openModal="openExamDetailModal"
                  @openCertificate="openCertificate"
                  @openGallery="openGallery"
                )
                app-exam-card-mobile(
                  :data="exam"
                  :classes="'d-xl-none'"
                  @openModal="openExamDetailMobile"
                )
          .d-flex.justify-content-center.align-items-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
          .exams_count.d-flex.flex-column.align-items-end.p-2
            span(v-if="pagination.total") Всего: {{ pagination.total }}
            span.mt-2(v-if="exams_per_page") На странице: {{ exams_per_page }}
        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              app-svg-icon(name="wrong")
            span Нет осмотров

  // filter
  app-filter-exams(
    ref="filter"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilter"
    @getDataFromFilter="sentDataFromFilter"
    @getDataToFilter="sentDataToFilter"
    @getComanynameFilter="sentCompanyNameFilter"
  )
  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )

</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import examsApi from '@/api/exams';

import variables from '@/helpers/variables';
import {blobToFile} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppGallery from '@/components/common/Gallery';

import AppFilterExams from '@/components/profile/common/FilterExams';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';
import {filterMixin} from '@/mixins/filter';

export default {
  name: 'AppAdminExams',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppGallery,
    AppErrorMessage,
    AppPagination,
    AppSelect,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailModal,
    AppExamDetailMobile,
    AppFilterExams,
  },
  mixins: [
    examDetailModalMixin,
    examDetailMobileMixin,
    certificateModalMixin,
    galleryMixin,
    filterMixin,
  ],
  data() {
    return {
      exams: {},
      filterString: '',
      resultFilterForLoad: '',
      isFilterOpen: false,
      dateFromFilter: '',
      dateToFilter: '',
      companyNameFilter: '',
      v: variables,
      exams_per_page: null,
      pagination: {
        limit: 5,
        total: 0,
      },
      sortKey: 'exam_number',
      company_name: null,
      filters: '',
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'exam_datetime',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Тип осмотора',
          key: 'exam_type_name',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-2 d-flex justify-content-end flex-row-reverse',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      stateCurrentUser: authGettersTypes.currentUser,
      error: (state) => state.exams.error,
    }),
    ...mapGetters({
      stateCurrentUser: authGettersTypes.currentUser,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    currentUser() {
      return Number(this.$route.query.user);
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    filterOptions() {
      return {
        users: {
          title: 'Владельцы устройств',
          placeholder: 'Введите название компании или ФИО владельца',
          initialValues: [this.currentUser],
        },
        patients: true,
        examParams: true,
        date: true,
        time: true,
        bodyParams: true,
        methods: {
          getData: ['getExams'],
        },
      };
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: this.currentUser ? [this.currentUser] : [],
          },
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getExams();
    },
    currentUser() {
      this.$route.query.user;
      this.getExams();
    },
  },
  async created() {
    this.getExams();
  },
  methods: {
    //exams
    async getExams() {
      this.company_name = this.$store.getters.COMPANY_NAME;
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      if (this.company_name) {
        resultString = `page=${this.currentPage - 1}&per_page=10&${
          this.filterString
        }&exams_order_by=${order_by}&company_name=${this.company_name}`;
      } else {
        resultString = `page=${this.currentPage - 1}&per_page=10${
          this.filterString
        }&exams_order_by=${order_by}`;
      }
      let res = await examsApi.getExamsOrderBy(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.exams_per_page = res.data.items.length;
      this.exams = res.data.items;
    },

    //filter
    openFilter() {
      this.isFilterOpen = true;
    },
    async submitFilter(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getExams();
    },
    sentDataFromFilter(dateFrom) {
      this.dateFromFilter = dateFrom['dateFromFilter'];
    },
    sentDataToFilter(dateTo) {
      this.dateToFilter = dateTo['dateToFilter'];
    },
    sentCompanyNameFilter(companyName) {
      this.companyNameFilter = companyName['companyName'];
    },
    close() {
      this.isFilterOpen = false;
    },

    //sorting
    sortingExams(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getExams();
    },

    //export reports
    async export266() {
      examsApi
        .exportExams266({
          datetime_begin: this.dateFromFilter,
          datetime_end: this.dateToFilter,
          company_name: this.companyNameFilter,
        })
        .then(() => {
          this.$toast.success('Заявка создана');
        })
        .catch(function (error) {
          if (error.request.status == '422' || error.request.statusText == 'Not Found') {
            console.log(
              'Проверьте правильность введенных данных: необходимо заполнить даты и название компании с учетом кавычек и регистра'
            );
          }
        });
    },
    async exportExams() {
      let filterStringNoSmbl = this.filterString.substring(1);
      if (this.filterString.length) {
        this.resultFilterForLoad =
          `${filterStringNoSmbl}` + `&exams_order_by=-${this.sortKey}&activate=true`;
      } else {
        this.resultFilterForLoad = `exams_order_by=-${this.sortKey}&activate=true`;
      }
      examsApi
        .exportExamsV2(this.resultFilterForLoad, {})
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'))
        .catch(function (error) {
          if (error.request.status == '303') {
            console.log(error);
          }
        });
    },

    //common
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
  },
};
</script>

<style lang="scss">
.profile-exams {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
  .exams_count {
    span {
      color: #989fb8;
      line-height: 1;
    }
  }
}
</style>
