<template lang="pug">
  .profile-avatar.d-flex.flex-column.align-items-center(:class="classes")
    figure.profile-avatar__img.position-relative
      template(v-if="src || localUrl")
        template(v-if="errorCount < 2")
          button(
            v-if="viewPhoto"
            class="stretched-link"
            type="button"
            @click.prevent="openGallery"
          )
          img(:src="avatarUrl" @error="avatarUrlOnError")

        template(v-else)
          template(v-if="type === 'patient'")
            button(
              class="stretched-link"
              type="button"
              @click.prevent="changeAvatar"
            )
            app-svg-icon(name="no-image")

          app-svg-icon(v-else name="no-image")

      template(v-else)
        template(v-if="type === 'patient'")
          button(
            class="stretched-link"
            type="button"
            @click.prevent="changeAvatar"
          )
          app-svg-icon(name="no-image")

        app-svg-icon(v-else name="no-image")


    template(v-if="edit")
      input(
        class="d-none"
        type="file"
        name="document"
        @change="onFileSelected"
        ref="fileInput"
        accept="image/*"
      )

      button(
        class=['profile-avatar__edit-button', 'd-flex align-items-center justify-content-center', 'mt-4']
        type="button"
        @click="$refs.fileInput.click()"
      )
        span.d-flex.flex-shrink-0
          app-svg-icon(name="edit")

      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.cropper" @close="closeCropperModal" :classes="'full-content'")
          template(v-slot:body)
            cropper(
              ref="cropper"
              class=['profile-avatar__cropper', 'mt-4']
              :class="{'is-loading': usersLoading}"
              :src="cropperImg"
              :stencil-props="{aspectRatio: 1/1}"
              :stencil-component="$options.components.CircleStencil"
            )
            .row.align-items-center.justify-content-center.mt-4.g-4
              .col-auto
                button(
                  type="button"
                  class=['button button--blue']
                  @click="onSubmit"
                  :disabled="usersLoading"
                )
                  span.button__content Сохранить

              .col-auto
                button(
                  type="button"
                  class=['button button--border-blue']
                  @click="closeCropperModal"
                )
                  span.button__content Отмена

</template>

<script>
import {mapState} from 'vuex';

import {eventBus} from '@/main';
import {insert, urlToFile} from '@/helpers/utils';
import {Cropper, CircleStencil} from 'vue-advanced-cropper';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppModal from '@/components/common/Modal';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAvatar',
  components: {
    AppSvgIcon,
    AppModal,
    Cropper,
    CircleStencil,
  },
  mixins: [modalsMixin],
  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    localUrl: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewPhoto: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imgKey: 0,
      cropperImg: '',
      uploadFile: '',
      isModalVisible: {
        cropper: false,
      },
      errorCount: 0,
      slug: 'changeAvatar',
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,

      usersLoading: (state) => state.users.isLoading,
      usersError: (state) => state.users.error,
    }),
    baseUrl() {
      if (!this.settings) return false;
      return this.settings.s3_host;
    },
    avatarUrl() {
      if (this.localUrl) return this.localUrl;
      else {
        const index = this.src.lastIndexOf('.');
        return this.errorCount > 0
          ? `${this.baseUrl}${this.src}`
          : `${index > -1 ? insert(this.src, index, '_thmb') : this.src}`;
      }
    },
  },
  methods: {
    avatarUrlOnError() {
      if (this.errorCount < 2) this.errorCount++;
    },
    closeCropperModal() {
      this.closeModal('cropper');
      this.resetForm();
    },
    onFileSelected({target}) {
      const reader = new FileReader();
      this.uploadFile = target.files[0];
      reader.readAsDataURL(this.uploadFile);
      reader.onload = () => {
        this.cropperImg = reader.result;
        this.openModal('cropper');
      };
    },
    onSubmit() {
      const {canvas} = this.$refs.cropper.getResult(),
        formData = new FormData(),
        base64File = canvas.toDataURL();

      urlToFile(base64File, this.uploadFile.name, this.uploadFile.type).then((file) => {
        formData.append('avatar', file, this.uploadFile.name);
        this.$emit('submit', {slug: this.slug, form: formData});
      });
    },
    resetForm() {
      this.$refs.fileInput.value = '';
    },
    openGallery() {
      const data = {
        type: 'gallery',
        preview: null,
        edit: this.type === 'patient' ? 'changeAvatar' : false,
        delete: this.type === 'patient' ? 'deleteAvatar' : false,
        sources: [
          {
            src: this.avatarUrl,
          },
        ],
      };

      this.$emit('openGallery', data);
      eventBus.$emit('openGallery', data);
    },
    changeAvatar() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
.profile-avatar {
  position: relative;

  &__img {
    overflow: hidden;
    width: $spacer * 3;
    height: $spacer * 3;
    border-radius: 50%;
    background-color: get($colors, blue-extra-light);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      fill: get($colors, blue-light);
      width: 100%;
      height: 100%;
    }
  }
  &__edit-button {
    @include transition();
    position: absolute;
    z-index: get($index, default);
    left: 0;
    bottom: 0;
    width: $spacer * 3.4;
    height: $spacer * 3.4;
    border-radius: 50%;
    background-color: get($colors, white);
    color: get($colors, blue-dark);
    box-shadow: get($shadows, default);
    transform: translateY(30%);

    svg {
      width: $spacer * 1.4;
      height: $spacer * 1.4;
    }
    @include interaction('mouse') {
      &:hover {
        background-color: get($colors, blue-dark);
        color: get($colors, white);
      }
    }
  }
  &__cropper {
    @include transition(opacity);
    width: 100%;
    height: $spacer * 30;
    border-radius: $border-radius-sm;
    overflow: hidden;

    &.is-loading {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  &.square {
    .profile-avatar {
      &__img {
        border-radius: 0;
      }
    }
  }
  &.extra-large {
    .profile-avatar {
      &__img {
        width: $spacer * 8;
        height: $spacer * 8;

        @include mediaToDown($md) {
          width: $spacer * 6;
          height: $spacer * 6;
        }
      }
    }
  }
  &.large {
    .profile-avatar {
      &__img {
        width: $spacer * 4;
        height: $spacer * 4;
      }
    }
  }
  &.middle {
    .profile-avatar {
      &__img {
        width: $spacer * 5.4;
        height: $spacer * 5.4;
      }
    }
  }
}
</style>
