<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить версию прошивки??

        app-validation-errors(
          v-if="validationErrors"
          :classes="'mb-3'"
          :validation-errors="validationErrors"
        )
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-8
            .row.align-items-center.g-4
              .col-7.col-md-auto
                button(
                  type="button"
                  @click="closeModal"
                  class=['button', 'button--danger', 'form__button']
                )
                  span.button__content Не удалять
              .col-7.col-md-auto
                button(
                  type="button"
                  @click="deleteDevice"
                  :disabled="isLoading"
                  class=['button', 'button--border-blue', 'form__button']
                )
                  span.button__content Да, удалить
  </template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import filesApi from '@/api/files';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppDeleteDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    deleteFirmvareId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      validationErrors: '',
      id: null,
    };
  },
  watch:{
    deleteFirmvareId(){
      this.id = this.data.deleteFirmvareId;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      error: (state) => state.devices.error,
    }),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
    getFile(hash) {
      filesApi.getFile(hash);
    },
    async deleteDevice() {
      const formData = new FormData();
      console.log(formData)
      formData.append('id', this.deleteFirmvareId);
      await this.$store.dispatch("actionDeleteFirmware", formData);
      await this.$store.dispatch("actionGetFirmware");
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
