<template lang="pug">
div
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showBlockHistory"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) История блокировок

  .profile-table(v-if="showInfo")
    .profile-table__header.d-none.d-xl-block(v-if="blockHistory.length")
      .row.align-items-center.g-4
        div(v-for="column in columns" :key="column.key" :class="column.class")
          button(
              type="button"
              @click="alert(123)"
              class=['profile-table__sort-button']
          )
          span.d-flex.align-items-center
                  span.text-overflow(:title="column.caption") {{ column.caption }}
                  span.d-flex.ms-3
                  app-svg-icon(name="down-arrow"
          )
        ul.profile-table__body
          li.profile-table__row(v-for="blockHistoryItem in blockHistory" :key="blockHistoryItem.id")
              section(
                  class=['user-card', 'user-card-history']
              )
                .row.align-items-center.g-4.gy-xl-0
                  .col-2
                      span {{ $moment(blockHistoryItem.start_datetime).format('DD.MM.YYYY HH:mm')}}
                  .col-2
                      span {{ $moment(blockHistoryItem.end_datetime).format('DD.MM.YYYY HH:mm')}}
    span(v-else).text-color--blue-dark Блокировки отсутствуют

</template>

<script>
import patients from '@/api/patients';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppPatientBlockHistory',
  components: {
    AppSvgIcon,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false,
      blockHistory: {},
      columns: [
        {
          num: 0,
          class: 'col-2',
          caption: 'Дата и время начала',
          key: 'start_datetime',
          inverse: false,
        },
        {
          num: 1,
          class: 'col-2',
          caption: 'Дата и время конца',
          key: 'end_datetime',
          inverse: false,
        },
      ],
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    showBlockHistory() {
      this.showInfo = !this.showInfo;
    },
    async getHistory() {
      let res = await patients.blockHistory(this.userId);
      this.blockHistory = res.data.items;
      console.log(this.blockHistory);
    },
  },
};
</script>

<style lang="scss">
.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}
</style>
