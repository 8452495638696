<template lang="pug">
 transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close" :classes="`modal-refuse`")
    template(v-slot:body)
      .row
        .col-14
          .text-center.pt-4
            app-title(:type="'large'") Изменить индивидуальные границы?
            p.text-color--blue.mt-3.text-uppercase Для пользователя {{ data.patient_full_name }}
        .col-14.mt-4.mb-3(v-if='toggle')
          li.profile-table__row(v-for="border of bordersData" :key="border.num")
            section(
                class=['user-card', 'user-card-history']
            )
              .row.align-items-center.g-4.gy-xl-0
                .col-1
                    div {{ border.num }}
                .col-4
                    div {{ border.name }}
                .col-5
                    span.presonal-border-text от
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.min_value"
                    )
                    span.presonal-border-text до
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.max_value"
                    )
                .col-3
                    date-picker(
                        prefix-class="app"
                        placeholder="от"
                        format="DD.MM.YYYY"
                        :editable="true"
                        v-model="border.end_date"
                        value-type="YYYY-MM-DD"
                    )
                    //data[border.id].max_val
        template(v-if='!toggle')
         .col-7
          .d-flex.justify-content-center.mt-4
             button(
               @click="rejectEditBorders"
               type="button"
               class=['button form__button button--border-blue']
             )
               span.button__content Нет
         .col-7
          .d-flex.justify-content-center.mt-4
             button(
               @click="toggle = true"
               type="button"
               class=['button button--accent w-100']
             )
               span.button__content Да
        template(v-else)
         .col-7
          .d-flex.justify-content-center.mt-4
             button(
               @click="rejectEditBorders"
               type="button"
               class=['button form__button button--border-blue']
             )
               span.button__content Отменить
         .col-7
          .d-flex.justify-content-center.mt-4
             button(
               @click="confirmEditBorders"
               type="button"
               class=['button button--accent w-100']
             )
               span.button__content Подтвердить
</template>

<script>
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';

import DatePicker from 'vue2-datepicker';

export default {
    name: 'handlerSelectModal',
    data() {
      return {
        bordersData: [
          {
            'id': 'border_bp_high',
            'num': 1,
            'name': 'Давление систолическое',
            'min_value': 0,
            'max_value': 0,
            'end_date': null
          },
          {
            'id': 'border_bp_low',
            'num': 2,
            'name': 'Давление диастолическое',
            'min_value': 0,
            'max_value': 0,
            'end_date': null
          },
          {
            'id': 'border_heart_rate',
            'num': 3,
            'name': 'ЧСС',
            'min_value': 0,
            'max_value': 0,
            'end_date': null
          },
          {
            'id': 'border_body_temp',
            'num': 4,
            'name': 'Температура',
            'min_value': 0,
            'max_value': 0,
            'end_date': null
          },
          {
            'id': 'border_alcohol',
            'num': 5,
            'name': 'Алкоголь',
            'min_value': 0,
            'max_value': 0,
            'end_date': null
          },
        ],
        toggle:false
      }
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        data: {
            required: true,
            type: Object,
        },
  },
  watch: {
    toggle(oldVal, newVal) {
      console.log(oldVal, newVal)
      if (newVal === true) {
        console.log('тут мы будем делать запрос за границами и подкладывать в bordersData');

      }
    }
  },
    components: {
        AppModal,
        AppTitle,
        AppSvgIcon,
        DatePicker,
    },
    methods: {
        close() {
        this.toggle = false;
          this.$emit('close');
        },
        rejectEditBorders() {
          this.toggle = false;
          this.$emit('reject');
        },
        confirmEditBorders() {
          console.log('123');
          this.toggle = false;
          // тут будем менять границы
          for (let i of this.bordersData) {
            console.log(i)
            this.data[i.id] = {}

            this.data[i.id]['border_type'] = i.id.replace('border_', '');
            this.data[i.id]['end_date'] = i.end_date;
            this.data[i.id]['min_value'] = i.min_value;
            this.data[i.id]['max_value'] = i.max_value;
          }
          this.$emit('confirm');
        },
    },
}
</script>
<style lang="scss">
.refuseCauseHeader{
  letter-spacing: 0.04em;
text-transform: uppercase;
font-size: 14px;
color: #000000;
}
  .refuseCause{
    font-size: 14px;
    color: #fff;
    background: #59658D;
border-radius: 4px;
padding: 4px 14px;
  }
  .modal-refuse{
    width: 771px;
  }

.block-history-title:hover {
    cursor: pointer;
}

.user-card-history {
    padding: 10px 0;
    color: #505e8e;
}

.personal-border {
  width: 80px !important;
}

.presonal-border-text {
  padding: 10px;
}

</style>
