<template lang="pug">
  .profile-sidebar__content.d-flex.flex-column
    .profile-sidebar__header.d-flex.align-items-center.align-items-xxl-start.flex-column
      .profile-sidebar__user.d-flex.align-items-center.mb-4(v-if="currentUser")
        router-link(:to="{name: 'settings'}" class=['d-block', 'me-4'])
          app-avatar(
            :classes="'large'"
            :src="currentUser.avatar"
          )
        ul.text-size--small
          li.mb-1
            span.text-color--gray-light Личный кабинет
            span.text-color--accent &ensp;admin
          li(v-if="currentUser.full_name").mb-1
            app-title(:routerLink="{name: 'settings'}") {{ currentUser.full_name }}
          li(v-else).mb-1
            app-title(:routerLink="{name: 'settings'}") Введите данные
          li(v-if="currentUser.email")
            span {{ currentUser.email }}
          li(v-else)
            span Введите email
      button(
        type="button"
        class=['profile-sidebar__button','button button--border-blue w-100']
        @click="isModalVisible.createDevice = true"
      )
        span.button__content
          span.d-flex.flex-shrink-0.me-3
            app-svg-icon(name="plus-thin")
          span Добавить устройства

    .d-flex.flex-column.align-items-center.flex-grow-1.pt-4.h-100.overflow-auto
      ul.profile-sidebar__menu.text-center
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'requests'}" :classes="`d-block profile-sidebar__menu-link ${isActive('requests') ? 'is-active': ''}`")
            span Заявки на регистрацию
            transition(name="translucent")
              span.text-color--accent.ms-2(v-if="requestsPending.length") (+{{ requestsPending.length }})

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'devices'}" :classes="`d-block profile-sidebar__menu-link ${isActive('devices') ? 'is-active': ''}`")
            span Устройства

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'exams'}" :classes="`d-block profile-sidebar__menu-link ${isActive('exams') ? 'is-active': ''}`")
            span Журнал осмотров

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'users'}" :classes="`d-block profile-sidebar__menu-link ${isActive('users') ? 'is-active': ''}`")
            span Клиенты

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'patients'}" :classes="`d-block profile-sidebar__menu-link ${isActive('patients') ? 'is-active': ''}`")
            span Пользователи

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'doctors'}" :classes="`d-block profile-sidebar__menu-link ${isActive('doctors') ? 'is-active': ''}`")
            span Медработники

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'chart'}" :classes="`d-block profile-sidebar__menu-link ${isActive('chart') ? 'is-active': ''}`")
            span Отчеты

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'incoming'}" :classes="`d-block profile-sidebar__menu-link ${isActive('med-docs') ? 'is-active': ''}`")
            span Медицинская документация

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'suspended-workers'}" :classes="`d-block profile-sidebar__menu-link ${isActive('suspended-workers') ? 'is-active': ''}`")
            span Отстраненные работники

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'risk-groups'}" :classes="`d-block profile-sidebar__menu-link ${isActive('risk-groups') ? 'is-active': ''}`")
            span Группы риска

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'system-setup'}" :classes="`d-block profile-sidebar__menu-link ${isActive('system-setup') ? 'is-active': ''}`")
            span Настройки системы

      app-create-device-modal(
        :is-visible="isModalVisible.createDevice"
        @close="closeModal('createDevice')"
        @success="openModal('successCreatedDevice')"
      )
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {
  actionTypes as authActionTypes,
  gettersTypes as authGettersTypes,
} from '@/store/modules/auth';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
import AppCreateDeviceModal from '@/components/profile/admin/devices/AppCreateDeviceModal';

import {routes} from '@/router/index.js';

export default {
  name: 'AppAdminSidebar',
  components: {
    AppAvatar,
    AppSvgIcon,
    AppTitle,
    AppCreateDeviceModal,
  },
  data() {
    return {
      v: variables.requests,
      isModalVisible: {
        createDevice: false,
      },
      paths: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      requests: (state) => state.requests.data,
      error: (state) => state.requests.error,
    }),
    requestsPending() {
      if (this.requests) {
        return this.requests.filter((el) => el.status_code === this.v.statuses.pending);
      } else return false;
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch(authActionTypes.logout).then(() => {
        this.$router.push({name: 'home'});
      });
    },
    closeModal(slug) {
      this.isModalVisible[slug] = false;
    },
    isActive(route) {
      this.paths = routes;
      return this.$route.path.includes(route);
    },
  },
};
</script>
