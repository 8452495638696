import {modalsMixin} from '@/mixins/modals';
import variables from '@/helpers/variables';

export const examDetailModalMixin = {
  mixins: [modalsMixin],
  data() {
    return {
      v: variables,
      isModalVisible: {
        examDetail: false,
      },
      currentExam: {
        data: {},
        type: '',
      },
    };
  },
  methods: {
    openExamDetailModal(type, data) {
      console.log(data);
      this.currentExam.type = type;
      this.currentExam.data = data;

      this.openModal('examDetail');
    },
  },
};
