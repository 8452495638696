<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") редактировать поверку устройства
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10
              .row.g-4.posRelative
                .col
                  div
                    input(
                      :class="['form__field form__field--animate', { 'is-error': v$.form.serial.$errors.length }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="form.serial"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      disabled
                    )
                    label.textFloat(for='id') Серийный номер устройства
                  .row.gx-4
                   .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate', { 'is-error': v$.form.attestation.$errors.length }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="form.attestation"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="ids"
                    )
                    label.textFloat(for='ids') Номер поверки
                   .col-7
                      .text-size--small.mb-2.mt-4 Дата поверки
                       label(:class="['form__label pt-2', { 'is-error': v$.form.dateStart.$errors.length }]")
                        date-picker(
                            prefix-class="app"
                            placeholder="Дата поверки"
                            format="DD.MM.YYYY"
                            lang="ru"
                            :editable="true"
                            v-model="form.dateStart"
                            :disabled-date="disableAfterToday"
                        )
                   .col-7
                     .text-size--small.mb-2.mt-4  Окончание срока поверки
                      label(:class="['form__label pt-2', { 'is-error': v$.form.dateEnd.$errors.length }]")
                        date-picker(
                              prefix-class="app"
                              placeholder="Окончание срока поверки"
                              format="DD.MM.YYYY"
                              lang="ru"
                              :editable="true"
                              v-model="form.dateEnd"
                              :disabled-date="disableExpiration"
                          )
            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Отправить
            .col-14.col-md-9
              p.text-color--blue.text-size--small.text-center Заявки на добавление устройств рассматриваются администраторами от 1 до 4 рабочих дней. Статус проверки вы можете отследить в личном кабинете

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
export default {
  name: 'ChangeDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    toEdit: {
      required: true,
      type: Object,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        devices: [
          {
            id: 0,
            value: '',
          },
        ],
        // file: null,
        serial: '',
        attestation: '',
        number: '',
        dateStart: '',
        dateEnd: '',
      },
      validationErrors: {
        message: '',
        info: null,
      },
      limit: 100,
    };
  },
  validations() {
    return {
      form: {
        serial: {
          required,
        },
        attestation: {
          required,
        },
        dateStart: {
          required,
        },
        dateEnd: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
  },
  watch: {
    toEdit: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.form.serial = this.toEdit.serial;
        this.form.attestation = this.toEdit.device_attestation;
        this.form.number = this.toEdit.device_attestation;
        this.form.dateStart = new Date(
          new Date(this.toEdit.device_attestation_start).setHours(0, 0, 0, 0)
        );
        this.form.dateEnd = new Date(
          new Date(this.toEdit.device_attestation_end).setHours(0, 0, 0, 0)
        );
      },
    },
  },
  methods: {
    closeModal() {
      //this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
      window.location.reload();
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();
        formData.append('device_id', this.form.serial);
        formData.append('device_attestation', this.form.attestation);
        formData.append('device_attestation_start', this.form.dateStart);
        formData.append('device_attestation_end', this.form.dateEnd);
        this.$store
          .dispatch(requestsActionTypes.createDeviceUpdateRequest, formData)
          .then(() => {
            this.closeModal();
            this.$toast.success('Заявка на обновление устройства отправлена');
            this.success();
          })
          .catch((result) => {
            if (this.error === 'device_exists') {
              this.validationErrors.message = this.error;
              this.validationErrors.info = result;
            } else this.validationErrors.message = result;
          });
      }
    },
    resetForm() {
      this.v$.$reset();

      this.validationErrors.message = '';
      this.validationErrors.info = null;

      this.form.devices = [{id: 0, value: ''}];
      this.clearFileInput();
    },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.form.dateEnd) {
        return date > today;
      } else if (this.form.dateEnd < today) {
        return date > this.form.dateEnd;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        return date < today;
      } else {
        return date < this.form.dateStart;
      }
    },
  },
};
</script>
<style lang="scss">
.outer-form {
  position: relative;
  border: 1px solid #989fb8;
  border-radius: 8px;
  padding: 12px;
  padding-top: 4;
}
.maw365 {
  max-width: 365px;
}
.form__field--no-border {
  border: none !important;
  padding-left: 0 !important;
  transition: 0.2s ease !important;
  border-bottom: 1px solid #dfe4f8 !important;
  border-radius: 0 !important;
}
.addCheck {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 999;
  font-size: 12px;
  cursor: pointer;
}
.form__label--float {
  position: relative;
}
.fl {
  position: absolute;
  //top: 10px;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(10px);
  transition: 0.2s ease-out;
}
.form__field--no-border:focus + .fl,
.form__field--no-border:not(:placeholder-shown) + .fl {
  transform: translateY(-10px) scale(0.85);
}
.form__field--no-border::placeholder,
.form__field--animate::placeholder {
  color: #fff !important;
}
.textFloat {
  position: absolute;
  //top: 10px;
  display: block;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(-28px);
  transition: 0.2s ease-out;
  padding-left: 20px;
  cursor: inherit;
}
.form__field--animate {
  height: auto !important;
  padding-top: 18px !important;
  padding-bottom: 2px !important;
  text-transform: uppercase;
  padding-left: 12px !important;
}
.form__field--animate:focus + .textFloat,
.form__field--animate:not(:placeholder-shown) + .textFloat {
  transform: translateY(-43px) translateX(7px) scale(0.65);
  margin-right: 20px;
}
.posRelative {
  position: relative;
}
</style>
