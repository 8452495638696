<template lang="pug">
  fragment
    app-loading(v-if="isLoading")
    .row.justify-content-center.g-4(v-else)
      .col-14
        .text-center
          app-title(
            :type="'large'"
            :classes="'mb-3'"
          ) {{ title }}
          p.text-color--blue.text-size--small {{ subtitle }}

      .col-14.col-xl-12.col-xxl-10
        app-validation-errors(
          v-if='errors'
          :classes="'mb-3'"
          :validation-errors='errors'
        )
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]")
          .row.flex-column.align-items-center.g-4
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.email.$errors.length }]"
                  type="email"
                  v-model="v$.email.$model"
                  placeholder="E-mail"
                )
              ul.form__error
                li(v-for="error of v$.email.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="error.$validator === 'email'") {{ v.errors.email }}

            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.password.$errors.length }]"
                  :type="showPass ? 'text' : 'password'"
                  v-model="v$.password.$model"
                  placeholder="Пароль"
                )
                span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                  app-svg-icon(v-if="showPass" :name="'eye-open'")
                  app-svg-icon(v-else :name="'eye-close'")

              ul.form__error
                li(v-for="error of v$.password.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Войти
            .col-auto
              router-link(:to="{name: 'restore'}" class=['text-center', 'text-color--blue-dark text-decoration--underline text-size--small']) Забыли пароль?
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as authActionTypes} from '@/store/modules/auth';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import useVuelidate from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';

import queryString from 'query-string';
import variables from '@/helpers/variables';

import AppLoading from '@/components/common/Loading';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import {Fragment} from 'vue-fragment';

export default {
  name: 'AppLogin',
  components: {
    AppLoading,
    AppValidationErrors,
    AppTitle,
    AppSvgIcon,
    Fragment,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      email: '',
      password: '',
      showPass: false,
      errors: '',
      title: 'Вход в личный кабинет',
      subtitle: 'Для входа в личный кабинет введите Ваш e-mail и пароль',
    };
  },
  validations() {
    return {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
      validationErrors: (state) => state.auth.validationErrors,

      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
    approveCode() {
      return queryString.parseUrl(this.$route.fullPath).query.code || null;
    },
  },
  beforeMount() {
    if (this.approveCode) this.approveEmail();
  },
  methods: {
   async onSubmit() {   
      if (!this.v$.$invalid) {
        // await this.$recaptchaLoaded()
        // const token = await this.$recaptcha('login')
        // console.log(token)
        this.$store
          .dispatch(authActionTypes.login, {
            email: this.email,
            password: this.password,
            // token:token
          })
          .then(() => this.$router.push({name: 'dashboard'}))
          .catch(() => (this.errors = this.validationErrors));
      }
    },
    approveEmail() {
      if (this.approveCode) {
        this.$store
          .dispatch(requestsActionTypes.approveRequest, {
            code: this.approveCode,
            body: {},
          })
          .then(() => {
            this.title = 'Войдите с помощью нового адреса';
            this.subtitle =
              'Вы подтвердили смену e-mail на портале, авторизуйтесь с помощью новых данных';
          })
          .catch(() => {
            this.errors = this.error;
          });
      }
    },
  },
};
</script>
