<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактирование пользователя<br>{{ patient.full_name }}

        .px-4
          form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .row.g-4
              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }, {'is-changed': form.isChanged.firstName}]"
                    type="text"
                    v-model="v$.form.firstName.$model"
                    @keyup="changeField('firstName')"
                    placeholder="Имя"
                  )
                ul.form__error(v-if="v$.form.firstName.$errors.length")
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }, {'is-changed': form.isChanged.lastName}]"
                    type="text"
                    v-model="v$.form.lastName.$model"
                    @keyup="changeField('lastName')"
                    placeholder="Фамилия"
                  )
                ul.form__error(v-if="v$.form.lastName.$errors.length")
                  li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', {'is-changed': form.isChanged.middleName}]"
                    type="text"
                    v-model="form.middleName"
                    @keyup="changeField('middleName')"
                    placeholder="Отчество"
                  )
              .col-14.col-md-7
                label.form__label 
                    input(
                         :class="['form__field', { 'is-error': v$.form.snils.$errors.length }, {'is-changed': form.isChanged.snils}]"
                        type="text"
                        v-mask="'###-###-###-##'"
                        placeholder="СНИЛС"
                        @keyup="changeField('snils')"
                        v-model="v$.form.snils.$model"
                        
                    )
                ul.form__error
                    li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.drivingLicense.$errors.length }, {'is-changed': form.isChanged.drivingLicense}]"
                    type="text"
                    v-mask="'## ## ######'"
                    v-model="v$.form.drivingLicense.$model"
                    @keyup="changeField('drivingLicense')"
                    placeholder="Серия и номер ВУ"
                  )
                ul.form__error(v-if="v$.form.drivingLicense.$errors.length")
                  li(v-for="error of v$.form.drivingLicense.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.drivingLicense.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}
              .col-14.col-md-7
                label(:class="['form__label',{'is-changed': form.isChanged.driving_license_receiving}]")
                    date-picker(
                        prefix-class="app"
                        placeholder="Дата выдачи"
                        format="DD.MM.YYYY"
                        v-model="v$.form.driving_license_receiving.$model"
                        :lang="datepicker.lang"
                        :editable="true"
                        :default-panel="datepicker.defaultValue.panel"
                        :default-value="defaultDate"
                        :disabled-date="disableAfterToday"
                        @change="changeField('driving_license_receiving')"
                    )
              
              .col-14.col-md-7(v-if="v$.form.birthdate")
                label(:class="['form__label', { 'is-error': v$.form.birthdate.$errors.length }, {'is-changed': form.isChanged.birthdate}]")
                  date-picker(
                    v-model="v$.form.birthdate.$model"
                    prefix-class="app"
                    placeholder="Дата рождения"
                    format="DD.MM.YYYY"
                    :lang="datepicker.lang"
                    :editable="true"
                    :default-panel="datepicker.defaultValue.panel"
                    :default-value="defaultDate"
                    :disabled-date="disableBeforeAdult"
                    @change="changeField('birthdate')"
                  )
                ul.form__error(v-if="v$.form.birthdate.$errors.length")
                  li(v-for="error of v$.form.birthdate.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                .row.align-items-center.g-4
                  .col-7
                    label(:class="['form__radio', { 'is-checked': form.gender === '1'}, {'is-changed': form.gender === '1' && form.isChanged.gender}]")
                      input(
                        type="radio"
                        name="gender"
                        value="1"
                        v-model="form.gender"
                        @change="changeField('gender')"
                      )
                      .d-flex.align-items-center
                        i.form__radio-icon.me-3.flex-shrink-0
                        span.form__radio-caption Мужской
                  .col-7
                    label(:class="['form__radio', { 'is-checked': form.gender === '2'}, {'is-changed': form.gender === '2' && form.isChanged.gender}]")
                      input(
                        type="radio"
                        name="gender"
                        value="2"
                        v-model="form.gender"
                        @change="changeField('gender')"
                      )
                      .d-flex.align-items-center
                        i.form__radio-icon.me-3.flex-shrink-0
                        span.form__radio-caption Женский
              
              .col-14.col-md-14
                label.form__label
                  input(
                    :class="['form__field']"
                    type="text"
                    v-model="v$.form.personal_number.$model"
                    @keyup="changeField('personal_number')"
                    placeholder="Табельный номер"
                  )
              .col-14.col-md-7(v-if="v$.form.email")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.email.$errors.length }, {'is-changed': form.isChanged.email}]"
                    type="email"
                    v-model="v$.form.email.$model"
                    @keyup="changeField('email')"
                    placeholder="E-mail"
                  )
                ul.form__error(v-if="v$.form.email.$errors.length")
                  li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'email'") {{ v.errors.email }}

              .col-14.col-md-7(v-if="v$.form.phone")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.phone.$errors.length }, {'is-changed': form.isChanged.phone}]"
                    type="tel"
                    v-model="v$.form.phone.$model"
                    v-mask="'+7 (###) ###-##-##'"
                    @keyup="changeField('phone')"
                    placeholder="Телефон"
                  )
                ul.form__error(v-if="v$.form.phone.$errors.length")
                  li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.phone.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.password.$errors.length }, {'is-changed': form.isChanged.password}]"
                    :type="isShowPassword ? 'text' : 'password'"
                    v-model="v$.form.password.$model"
                    @keyup="changeField('password')"
                    placeholder="Новый пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="isShowPassword = !isShowPassword")
                    app-svg-icon(v-if="isShowPassword" :name="'eye-open'")
                    app-svg-icon(v-else :name="'eye-close'")

                ul.form__error(v-if="v$.form.password.$errors.length")
                  li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                    template(v-if="v$.form.password.$model")
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                      template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                      template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                      template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                      template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                    :type="isShowPassword ? 'text' : 'password'"
                    v-model="v$.form.confirmPassword.$model"
                    placeholder="Повторите пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="isShowPassword = !isShowPassword")
                    app-svg-icon(v-if="isShowPassword" :name="'eye-open'")
                    app-svg-icon(v-else :name="'eye-close'")

                ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                  li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

              .col-14
                .row.align-items-end.g-4
                  template(v-if="isRFIDDeleted")
                    .col
                      .position-relative.d-flex.align-items-center.justify-content-center.text-color--danger
                        span.flex-shrink-0.d-flex.me-3
                          app-svg-icon(name="wrong")
                        span.text-size--small Карта удалена

                        button(
                          type="button"
                          @click.prevent="cancelRFIDInput"
                          class=['form__button--cancel', 'link--accent', 'text-decoration--underline text-size--small text-color--blue']
                        ) Отмена

                  template(v-else)
                    .col
                      .d-flex.mb-1(v-if="isRFIDChange")
                        span.text-size--extra-small.text-color--blue Введите новый номер карты

                        ul.form__error.mt-0.ms-auto(v-if="v$.form.RFID.$errors.length")
                          li(v-for="error of v$.form.RFID.$errors" :key="error.$uid")
                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                            template(v-if="error.$validator === 'alphaNum'") {{ v.errors.alphaNum }}

                      template(v-else)
                        span.text-size--extra-small.text-color--success(v-if="form.isChanged.RFID") Новый номер карты сохранен

                      label(
                        class=['form__label form__label--border-theme', 'd-flex align-items-center']
                        :class="[`justify-content-${isRFIDChange ? 'start' : 'center'}`, { 'is-error': v$.form.RFID.$errors.length }, {'is-changed': form.isChanged.RFID}]"
                      )
                        span.d-flex.flex-shrink-0.me-3(:class="{'text-color--blue': isRFIDChange || !form.RFID}")
                          app-svg-icon(name="card")

                        template(v-if="isRFIDChange")
                          span.me-2.text-uppercase.text-color--black Новая&nbsp;RFID&nbsp;Карта&nbsp;№
                          input(
                            :class="['form__field']"
                            type="text"
                            v-model="v$.form.RFID.$model"
                            placeholder="0123456789AaZz"
                          )
                        template(v-else)
                          template(v-if="form.RFID")
                            span.me-2.text-uppercase RFID&nbsp;Карта
                            span.text-family--medium №&nbsp;{{ form.RFID }}

                          span.text-uppercase.text-color--blue(v-else) нет RFID карты

                    .col-auto
                      button(
                        v-if="isRFIDChange"
                        type="button"
                        title="Изменить"
                        :disabled="v$.form.RFID.$invalid"
                        @click.prevent="changeRFIDInput"
                        class=['profile-card__button profile-card__button--confirm', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="checkmark")

                      button(
                        v-else
                        type="button"
                        @click.prevent="isRFIDChange = !isRFIDChange"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="edit")

                    .col-auto
                      button(
                        v-if="isRFIDChange"
                        type="button"
                        title="Отмена"
                        @click.prevent="clearRFIDInput"
                        class=['profile-card__button profile-card__button--cancel', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="close")

                      button(
                        v-else
                        type="button"
                        @click.prevent="deleteRFIDInput"
                        :disabled="!form.RFID"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="trash")


            .row.mt-5
              .col-14
                button(
                  type="submit"
                  @click="onSubmit"
                  :disabled="v$.$invalid || !isChanged"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Сохранить

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import {isEmptyObj, convertDate, convertPhone, removeSpaces} from '@/helpers/utils';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, requiredIf, sameAs, email, minLength, alphaNum} from '@vuelidate/validators';

import is from 'is_js';
import {mask} from 'vue-the-mask';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppLoading from '@/components/common/Loading';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppChangePatientModal',
  directives: {
    mask,
  },
  components: {
    AppLoading,
    AppValidationErrors,
    AppTitle,
    AppModal,
    AppSvgIcon,
    DatePicker,
  },
  props: {
    patient: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        isChanged: {
          firstName: false,
          lastName: false,
          middleName: false,
          drivingLicense: false,
          birthdate: false,
          gender: false,
          email: false,
          phone: false,
          password: false,
          RFID: false,
          snils:false,
          driving_license_receiving:false,
          personal_number: false
        },
        firstName: '',
        lastName: '',
        middleName: '',
        drivingLicense: '',
        birthdate: '',
        gender: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        RFID: '',
        snils:'',
        driving_license_receiving:'',
        personal_number: ''
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      isRFIDDeleted: false,
      isRFIDChange: false,
      isShowPassword: false,
      localValidationErrors: null,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
    defaultDate() {
      return new Date().setYear(this.datepicker.defaultValue.year);
    },
    initialValues() {
      if (isEmptyObj(this.patient)) return false;

      return {
        firstName: this.patient.first_name,
        middleName: this.patient.middle_name,
        lastName: this.patient.last_name,
        drivingLicense: this.patient.serial_number_driving_license,
        birthdate: this.patient.date_birth
          ? new Date(new Date(this.patient.date_birth).setHours(0, 0, 0, 0))
          : null,
        gender: this.patient.gender,
        email: this.patient.email,
        phone: this.patient.phone,
        RFID: removeSpaces(this.patient.rfid_id),
        password: null,
        snils: this.patient.snils,
        personal_number: this.patient.personal_number,
        driving_license_receiving: this.patient.date_birth
          ? new Date(new Date(this.patient.driving_license_receiving).setHours(0, 0, 0, 0))
          : null,
      };
    },
  },
  validations() {
    if (is.not.empty(this.form.password)) {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          drivingLicense: {
            required: requiredIf(() => Boolean(this.initialValues.drivingLicense)),
            minLength: minLength(12),
          },
          birthdate: {
            required: requiredIf(() => Boolean(this.initialValues.birthdate)),
          },
           driving_license_receiving: {
            required: requiredIf(() => Boolean(this.initialValues.driving_license_receiving)),
          },
          email: {
            required: requiredIf(() => Boolean(this.initialValues.email)),
            email,
          },
          personal_number: {
            required: requiredIf(() => Boolean(this.initialValues.personal_number)),
          },
          phone: {
            required: requiredIf(() => Boolean(this.initialValues.phone)),
            minLength: minLength(18),
          },
          password: {
            required,
          },
          confirmPassword: {
            required,
            sameAsPassword: sameAs(this.form.password),
          },
          RFID: {
            required: requiredIf(() => this.isRFIDChange),
            alphaNum,
          },
          snils: {
                        required,
                        validateSnils(snils) {    
                          snils = snils.replace(/-/g, '');
                           console.log('sniiil',snils)
                            var result = false;
                            if (typeof snils === 'number') {
                                snils = snils.toString();
                            } else if (typeof snils !== 'string') {
                                snils = '';
                            }
                            if (!snils.length) {
                                console.log('СНИЛС пуст')
                            } else if (/[^0-9]/.test(snils)) {
                                console.log('СНИЛС может состоять только из цифрт')
                                //error.message = 'СНИЛС может состоять только из цифр';
                            } else if (snils.length !== 11) {
                                console.log('СНИЛС может состоять только из 11 цифр')
                                //error.message = 'СНИЛС может состоять только из 11 цифр';
                            } else {
                                var sum = 0;
                                for (var i = 0; i < 9; i++) {
                                    sum += parseInt(snils[i]) * (9 - i);
                                }
                                var checkDigit = 0;
                                if (sum < 100) {
                                    checkDigit = sum;
                                } else if (sum > 101) {
                                    checkDigit = parseInt(sum % 101);
                                    if (checkDigit === 100) {
                                        checkDigit = 0;
                                    }
                                }
                                if (checkDigit === parseInt(snils.slice(-2))) {
                                    result = true;
                                } else {
                                    //error.code = 4;
                                    console.log('Неправильное контрольное число')
                                    //	error.message = 'Неправильное контрольное число';
                                }
                            }
                            console.log('true')
                            return result;
                        }
                    },
        },
      };
    } else {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          drivingLicense: {
            required: requiredIf(() => Boolean(this.initialValues.drivingLicense)),
            minLength: minLength(12),
          },
          birthdate: {
            required: requiredIf(() => Boolean(this.initialValues.birthdate)),
          },
           driving_license_receiving: {
            required: requiredIf(() => Boolean(this.initialValues.driving_license_receiving)),
          },
          email: {
            required: requiredIf(() => Boolean(this.initialValues.email)),
            email,
          },
          personal_number: {
            required: requiredIf(() => Boolean(this.initialValues.personal_number)),
          },
          phone: {
            required: requiredIf(() => Boolean(this.initialValues.phone)),
            minLength: minLength(18),
          },
          password: {},
          confirmPassword: {},
          RFID: {
            required: requiredIf(() => this.isRFIDChange),
            alphaNum,
          },
          snils: {
                        required,
                        validateSnils(snils) {    
                          snils = snils.replace(/-/g, '');
                           console.log('sniiil',snils)
                            var result = false;
                            if (typeof snils === 'number') {
                                snils = snils.toString();
                            } else if (typeof snils !== 'string') {
                                snils = '';
                            }
                            if (!snils.length) {
                                console.log('СНИЛС пуст')
                            } else if (/[^0-9]/.test(snils)) {
                                console.log('СНИЛС может состоять только из цифрт')
                                //error.message = 'СНИЛС может состоять только из цифр';
                            } else if (snils.length !== 11) {
                                console.log('СНИЛС может состоять только из 11 цифр')
                                //error.message = 'СНИЛС может состоять только из 11 цифр';
                            } else {
                                var sum = 0;
                                for (var i = 0; i < 9; i++) {
                                    sum += parseInt(snils[i]) * (9 - i);
                                }
                                var checkDigit = 0;
                                if (sum < 100) {
                                    checkDigit = sum;
                                } else if (sum > 101) {
                                    checkDigit = parseInt(sum % 101);
                                    if (checkDigit === 100) {
                                        checkDigit = 0;
                                    }
                                }
                                if (checkDigit === parseInt(snils.slice(-2))) {
                                    result = true;
                                } else {
                                    //error.code = 4;
                                    console.log('Неправильное контрольное число')
                                    //	error.message = 'Неправильное контрольное число';
                                }
                            }
                            console.log('true')
                            return result;
                        }
                    },
        },
      };
    }
  },
  updated() {
    this.setInitialValues();
  },
  methods: {
    disableBeforeAdult(date) {
          const today = new Date();

          today.setFullYear(today.getFullYear() -18);

          today.setHours(0, 0, 0, 0);

          return date > today;
      },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    setInitialValues() {
      if (!isEmptyObj(this.patient)) {
        for (let key in this.initialValues) {
          if (Object.prototype.hasOwnProperty.call(this.initialValues, key)) {
            this.form[key] = this.initialValues[key] || '';
          }
        }
      }
    },
    changeField(key) {
      let fieldValue = this.form[key];
      if (key === 'phone') fieldValue = convertPhone(fieldValue);

      this.form.isChanged[key] = fieldValue !== (this.initialValues[key] || '');
    },
    clearRFIDInput() {
      this.isRFIDChange = false;
      this.form.RFID = this.initialValues.RFID;
    },
    changeRFIDInput() {
      this.isRFIDChange = false;
      this.changeField('RFID');
    },
    deleteRFIDInput() {
      this.isRFIDDeleted = true;
      this.form.RFID = '';
      this.changeField('RFID');
    },
    cancelRFIDInput() {
      this.isRFIDDeleted = false;
      this.isRFIDChange = false;
      this.form.RFID = this.initialValues.RFID;
      this.changeField('RFID');
    },
    deleteRFID(callback) {
      this.$store
        .dispatch(patientsActionTypes.deletePatientRFID, {
          id: this.patient.code,
        })
        .then(() => {
          if (callback) callback();
        });
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();

        formData.append('patient_code', this.patient.code);
        formData.append('first_name', this.form.firstName);
        formData.append('middle_name', this.form.middleName);
        formData.append('last_name', this.form.lastName);
        formData.append('gender', this.form.gender);
        formData.append('date_birth', convertDate(this.form.birthdate) || '');
        formData.append('email', this.form.email);
        formData.append('phone', convertPhone(this.form.phone) || '');
        formData.append('serial_number_driving_license', this.form.drivingLicense);
        formData.append('rfid', this.form.RFID || '');
        formData.append('snils', this.form.snils);
        formData.append('personal_number', this.form.personal_number);
        formData.append('driving_license_receiving', convertDate(this.form.driving_license_receiving) || '');

        if (this.form.password) formData.append('password', this.form.password);

        this.$store
          .dispatch(patientsActionTypes.changePatient, formData)
          .then(() => {
            this.isRFIDDeleted ? this.deleteRFID(this.success) : this.success();
          })
          .catch(() => (this.localValidationErrors = this.validationErrors));
      }
    },
    resetForm() {
      this.v$.$reset();
      this.localValidationErrors = null;

      for (let key in this.form.isChanged) {
        if (Object.prototype.hasOwnProperty.call(this.form.isChanged, key)) {
          this.form.isChanged[key] = false;
        }
      }

      this.isRFIDDeleted = false;
      this.clearRFIDInput();

      this.form.password = '';
      this.form.confirmPassword = '';
    },
    close() {
      this.resetForm();
      this.$emit('close');
    },
    success() {
      this.resetForm();
      this.$emit('success');
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &::v-deep .app-datepicker .app-input {
    color: get($colors, black);
  }
  &__button {
    &--cancel {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .icon {
    &__card {
      width: $spacer * 3;
      height: $spacer * 2;
    }
  }
}
</style>
