import axios from '@/api/axios';

const getDevices = (payload) => axios.post('/api/v1/device', payload);

const getDevicesNew = (credentials) => axios.get(`/api/v2/devices${credentials}`);

const getDeviceBySerialId = (serial_id) => axios.get(`/api/v2/devices/${serial_id}`);

const filterDevices = (credentials) => axios.post('/api/v1/device/all', credentials);

const getCountDevices = (credentials) => axios.post('/api/v1/device/all/count', credentials);

const exportDevices = (credentials) =>
  axios.post('/api/v1/device/all/export', credentials, { responseType: 'blob' });

const exportNewDevices = (credentials) =>
  axios.post(`/api/v2/devices/export?${credentials}`, {}, {responseType: 'blob'});

const deleteDevice = (deviceId) => axios.delete(`/api/v1/device/${deviceId}`);

const deleteRejectedDevice = (requestId, deviceId) =>
  axios.delete(`/api/v1/device/rejected/${requestId}/${deviceId}`);

export default {
  getDevices,
  filterDevices,
  getCountDevices,
  exportDevices,
  exportNewDevices,
  deleteDevice,
  deleteRejectedDevice,
  getDevicesNew,
  getDeviceBySerialId,
};
