<template lang="pug">
div
  label.submit-form-comment
    textarea(
    :class="['form__field w-100 doctor-exam-detail-modal__text']"
    placeholder="Добавить комментарий"
    v-model="currentComment"
    )
    button(
    type="button"
    @click="createComment()"
    class=['send-comment']
    )
        span.send-comment-content >
  .comments-section
    ul
      li.comment-header(v-if="comments.length" v-for="comment in comments" :key="comment.id") {{ comment.user.fio_name }} {{ $moment(comment.created_at).format('HH:mm DD.MM.YYYY') }}
        p {{ comment.comment }}

</template>

<script>
import patientApi from '@/api/patients';

export default {
  name: 'ExamDetailComments',
  props: {
    patientCode: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      currentComment: '',
      comments: [],
      showBlock: false,
    };
  },
  async created() {
    await this.getComments();
  },
  watch: {
    async patientCode() {
      await this.getComments();
    },
  },
  methods: {
    async getComments() {
      let comments = await patientApi.getPatientComment(this.patientCode);
      this.comments = comments.data.items;
    },
    async createComment() {
      await patientApi.createPatientComment({
        patient_code: this.patientCode,
        comment: this.currentComment,
      });
      this.currentComment = '';
      await this.getComments();
    },
  },
};
</script>

<style lang="scss">
.comments {
  width: 60%;
  margin-top: 30px;
}

.doctor-exam-detail-modal__text {
  height: 36px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  resize: none;
  border: 1px solid #dfe4f8 !important;
  border-radius: 5px 0 0 5px !important;
  overflow: hidden;
  margin-right: -5px;
  &::placeholder {
    font-size: 12px;
  }
}

.comment-header {
  font-weight: 700;
  p {
    font-weight: normal;
    @include font-size(get($text-sizes, small));
    color: #59658d;
  }
}
.comments {
  width: 60%;
  margin-top: 30px;
}

.comment-header {
  font-weight: 600;
}

.submit-form-comment {
  display: flex;
}

.send-comment {
  font-size: 20px;
  line-height: 20px;
  background: #989fb8;
  border-radius: 10px;
  padding: 0px 15px 2px 15px;
  color: white;
  width: 36px;
  height: 36px;
}

.send-comment-content {
  font-size: 40px;
  margin-left: -7px;
}
.comments-section {
  margin-top: 20px;
  height: 280px;
  overflow: auto;
  border: 1px solid #dfe4f8;
  border-radius: 6px;
  padding: 10px;
  p {
    font-size: 12px;
  }
}
</style>
