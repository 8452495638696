<template lang="pug">
  .profile
    app-sidebar
    app-mobile-tab-bar

    simplebar(:id="v.dom.mainScroll")
      .container-fluid
        .d-flex.justify-content-end
          .profile__inner
            transition(name="fade" mode="out-in")
              app-mobile-header(v-if="$route.name === 'dashboard'")

            .profile__content
              transition(name="fade" mode="out-in")
                router-view

</template>

<script>
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';
//import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import variables from '@/helpers/variables';

import simplebar from 'simplebar-vue';
import AppSidebar from '@/components/profile/Sidebar';
import AppMobileTabBar from '@/components/profile/MobileTabBar';
import AppMobileHeader from '@/components/profile/MobileHeader';

export default {
  name: 'AppProfile',
  components: {
    AppSidebar,
    AppMobileTabBar,
    AppMobileHeader,
    simplebar,
  },
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
  },
  created() {
    if (this.userRole === 'admin') this.getRequests();
    if (this.userRole === 'doctor') this.getExamsPending();
  },
  methods: {
    getRequests() {
      this.$store.dispatch(requestsActionTypes.getRequests);
    },
    getExamsPending() {
      console.log('old get exams for doctor');
    },
  },
};
</script>

<style lang="scss">
.profile {
  width: 100%;
  height: 100%;

  &__title {
    @include mediaToDown($md) {
      border-top: $border-width solid get($colors, gray);
      padding-top: $spacer * 1.6;
    }
  }
  &__content {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 4;

    .icon {
      &__plus {
        width: $spacer * 1.2;
        height: $spacer * 1.2;
      }
      &__map-point,
      &__xls-doc,
      &__map-route {
        width: $spacer * 2;
        height: $spacer * 2;
        fill: get($colors, blue);
      }
      &__plus-thin {
        width: $spacer * 1.3;
        height: $spacer * 1.3;
      }
    }
    @include mediaToDown($xl) {
      padding-top: $spacer * 2;
    }
  }
  &__inner {
    width: 81%;

    @include mediaToDown($xxl) {
      width: 80%;
    }
    @include mediaToDown($sl) {
      width: 77.5%;
    }
    @include mediaToDown($xl) {
      width: 100%;
    }
  }
  .is-filterable {
    @include transition(width);
    width: 96%;

    &.filter-is-open {
      width: 78.8%;
    }
  }
  @include mediaToDown($xxl) {
    .is-filterable {
      width: 94%;

      &.filter-is-open {
        width: 94%;
      }
    }
  }
  @include mediaToDown($xl) {
    .is-filterable {
      width: 100%;

      &.filter-is-open {
        width: 100%;
      }
    }
  }
}
.profile {
  &-table {
    &__header {
      position: relative;
      z-index: get($index, default);
      @include font-size(get($text-sizes, small));
      padding: $spacer $spacer * 2.5 $spacer $spacer * 1.6;
      background-color: get($colors, white);
      color: get($colors, gray-hover);
      border-radius: $border-radius-sm $border-radius-sm 0 0;
      margin-bottom: $spacer * 0.2;
      box-shadow: get($shadows, default);
    }
    &__sort-button {
      @include transition();
      text-align: left;
      max-width: 100%;

      &.is-active {
        color: get($colors, turq-hover);
      }
      @include interaction('mouse') {
        &:hover {
          color: get($colors, turq-hover);
        }
      }
    }
    &__empty {
      padding: $spacer * 1.2;
      @include font-size(get($text-sizes, small));
      background-color: get($colors, white);
      color: get($colors, gray-hover);
      text-align: center;
      border-radius: 0 0 $border-radius-sm $border-radius-sm;
    }
    &__body {
    }
    &__row {
      & + .profile-table__row {
        margin-top: $border-width;
      }
      &:last-child {
        .profile-card {
          border-radius: 0 0 $border-radius-sm $border-radius-sm;
        }
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
        &:last-child {
          .profile-card {
            border-radius: $border-radius-sm;
          }
        }
        .profile-card {
          overflow: hidden;
          border-radius: $border-radius-sm;
          padding: $spacer * 1.6 $spacer * 2;
          box-shadow: get($shadows, default);
          background-color: get($colors, white);
        }
      }
      @include mediaToDown($md) {
        & + .profile-table__row {
          margin-top: $spacer * 1.6;
        }
        .profile-card {
          padding: $spacer * 1.6 $spacer * 1.2;
        }
      }
    }
    .icon {
      &__right-arrow,
      &__down-arrow {
        width: $spacer;
        height: $spacer;
        fill: get($colors, blue);

        @include mediaToDown($xl) {
          width: $spacer * 1.2;
          height: $spacer * 1.2;
        }
      }
      &__pulse,
      &__cardio,
      &__temp,
      &__breathalyzer {
        width: $spacer * 2.8;
        height: $spacer * 2.8;

        @include mediaToDown($xxl) {
          width: $spacer * 2;
          height: $spacer * 2;
        }
        @include mediaToDown($xl) {
          width: $spacer * 1.6;
          height: $spacer * 1.6;
        }
      }
    }
  }
  &-card {
    position: relative;
    padding: $spacer * 1.2 $spacer * 2.5 $spacer * 1.2 $spacer * 1.6;
    background-color: get($colors, white);
    @include font-size(get($text-sizes, small));

    &:before {
      @include transition();
      @include pseudo-wrapper();
      z-index: get($index, negative);
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-color: get($colors, white);
      border-radius: $border-radius-sm;
      opacity: 0;
    }
    @include interaction('mouse') {
      &:hover:not(.no-hover) {
        z-index: get($index, default);

        &:before {
          opacity: 1;
          transform: translate3d(-50%, -50%, 0) scale3d(1.01, 1.01, 1);
          box-shadow: get($shadows, default);
        }
      }
    }
    &.no-hover {
      &:before {
        display: none;
      }
    }
    &.is-show {
      z-index: get($index, default);

      &:before {
        transform: translate3d(-50%, -50%, 0) scale3d(1.01, 1.01, 1);
        box-shadow: get($shadows, default);
      }
    }
    &__button {
      @include transition();
      width: $spacer * 3.8;
      height: $spacer * 3.8;
      border-radius: $border-radius-sm;
      color: get($colors, blue-dark);
      border: $border-width solid get($colors, blue-dark);

      svg {
        width: $spacer * 1.8;
        height: $spacer * 2;
      }
      &[disabled] {
        pointer-events: none !important;
        opacity: 0.5 !important;
      }
      &--edit {
        @include interaction('mouse') {
          &:hover {
            border-color: get($colors, turq-hover);
            color: get($colors, turq-hover);
          }
        }
      }
      &--confirm {
        border-color: get($colors, success);
        color: get($colors, success);

        svg {
          width: $spacer * 1.4;
          height: $spacer * 1.4;
        }
        &[disabled] {
          opacity: 1 !important;
          border-color: get($colors, gray-hover);
          color: get($colors, gray-hover);
        }
      }
      &--cancel {
        border-color: get($colors, danger);
        color: get($colors, danger);

        svg {
          width: $spacer * 1.4;
          height: $spacer * 1.4;
        }
      }
      &--delete {
        @include interaction('mouse') {
          &:hover {
            border-color: get($colors, danger);
            color: get($colors, danger);
          }
        }
      }
      &--document {
        text-align: left;

        svg {
          fill: get($colors, blue);
          width: $spacer * 1.8;
          height: $spacer * 2;
          @include transition(fill);
        }
        @include interaction('mouse') {
          &:hover {
            svg {
              fill: get($colors, turq-hover);
            }
          }
        }
      }
    }
    &__user {
      &-avatar {
        overflow: hidden;
        width: $spacer * 3;
        height: $spacer * 3;
        border-radius: 50%;
        background-color: get($colors, blue-extra-light);
        background-size: cover;
        background-position: center center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        svg {
          fill: get($colors, blue-light);
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &-card-device {
    border-radius: $border-radius-sm;
    box-shadow: get($shadows, default);

    @include mediaToDown($xl) {
      overflow: hidden;
      padding: $spacer * 1.6 $spacer * 2;
    }
    @include mediaToDown($md) {
      padding: $spacer * 1.6 $spacer * 1.2;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $spacer * 5;
      height: $spacer * 5;
      background: get($gradients, blue-light);
      border-radius: 50%;

      svg {
        fill: get($colors, blue);
        width: $spacer * 2;
        height: $spacer * 2.5;

        rect {
          stroke: get($colors, blue);
        }
      }
    }
    &__message {
      svg {
        fill: get($colors, blue-extra-dark);
        width: $spacer * 2.5;
        height: $spacer * 2;
      }
      @include mediaToDown($xl) {
        width: $spacer * 3.6;
        height: $spacer * 3.8;
        border-radius: $border-radius-sm;
        border: $border-width solid get($colors, blue-dark);

        svg {
          width: $spacer * 2;
          height: $spacer * 1.6;
        }
      }
    }
    &__document-button {
      text-align: left;

      svg {
        fill: get($colors, blue);
        width: $spacer * 1.8;
        height: $spacer * 2;
        @include transition(fill);
      }
      @include interaction('mouse') {
        &:hover {
          svg {
            fill: get($colors, turq-hover);
          }
        }
      }
    }
  }
  &-modile-slider {
    position: relative;

    .profile-card {
      overflow: hidden;
      border-radius: $border-radius-sm;
      padding: $spacer * 1.6 $spacer * 1.2;
      box-shadow: get($shadows, default);

      .icon {
        &__right-arrow {
          width: $spacer;
          height: $spacer;
        }
      }
    }
    & > .swiper-container {
      overflow: visible;
    }
  }
}
</style>
