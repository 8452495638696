<template lang="pug">
.profile-devices
    component(:is="currentComponent")
</template>

<script>
import AppClientDevices from '@/components/profile/client/devices/ClientDevices';
import AppAdminDevices from '@/components/profile/admin/devices/AdminDevices';
import AppAdminDevicesCheck from '@/components/profile/admin/devices/AdminDevicesCheck';
import AppAdminDevicesControl from '@/components/profile/admin/devices/AdminDevicesControl';

export default {
    name: 'AppDevices',
    data() {
        return {
            roleMapping: {
                client: AppClientDevices,
                admin: AppAdminDevices,
            },
        };
    },
    computed: {
        currentComponent() {
            if (this.$store.state.auth.userRole=='admin' && this.$route.name == 'devices') {
                return AppAdminDevices
            } else if (this.$store.state.auth.userRole=='admin' && this.$route.name == 'devices-control') {
                return AppAdminDevicesControl
            } else if (this.$store.state.auth.userRole=='admin' && this.$route.name == 'devices-check') {
                return AppAdminDevicesCheck
            } else {
                return this.roleMapping[this.$store.state.auth.userRole];
            }

        },
    },
};
</script>

<style lang="scss">
.profile-devices {
    &__add-button {
        position: absolute;
        top: 50%;
        right: $spacer;
        width: $spacer * 3.8;
        height: $spacer * 3.8;
        transform: translateY(-50%);
        border-radius: $border-radius-sm;
    }

    &__create-button.button {
        @include mediaToDown($md) {
            position: fixed;
            left: 50%;
            min-width: $spacer * 37;
            transform: translateX(-50%);
            bottom: $mobile-tab-bar-height * 3;
            padding-top: $spacer * 2.4;
            padding-bottom: $spacer * 2.4;
        }

        @include mediaToDown($sm) {
            width: calc(100% - #{$grid-gutter-width});
            min-width: calc(100% - #{$grid-gutter-width});
        }
    }
}
</style>
