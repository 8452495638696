import patientsApi from '@/api/patients';

const state = {
  data: null,
  count: null,
  isLoading: false,
  isSubmitting: false,
  validationErrors: null,
  error: null,
};

export const mutationTypes = {
  createPatientsStart: '[patients] create start',
  createPatientsSuccess: '[patients] create success',
  createPatientsFailure: '[patients] create failure',

  setPatientAvatarStart: '[patients] set avatar start',
  setPatientAvatarSuccess: '[patients] set avatar success',
  setPatientAvatarFailure: '[patients] set avatar failure',

  setPatientSignatureStart: '[patients] set signature start',
  setPatientSignatureSuccess: '[patients] set signature success',
  setPatientSignatureFailure: '[patients] set signature failure',

  deletePatientAvatarStart: '[patients] delete avatar start',
  deletePatientAvatarSuccess: '[patients] delete avatar success',
  deletePatientAvatarFailure: '[patients] delete avatar failure',

  deletePatientSignatureStart: '[patients] delete signature start',
  deletePatientSignatureSuccess: '[patients] delete signature success',
  deletePatientSignatureFailure: '[patients] delete signature failure',

  getPatientsStart: '[patients] get data start',
  getPatientsSuccess: '[patients] get data success',
  getPatientsFailure: '[patients] get data failure',

  getCountPatientsStart: '[patients] get count start',
  getCountPatientsSuccess: '[patients] get count success',
  getCountPatientsFailure: '[patients] get count failure',

  importPatientsStart: '[patients] import start',
  importPatientsSuccess: '[patients] import success',
  importPatientsFailure: '[patients] import failure',

  changePatientStart: '[patients] change patient start',
  changePatientSuccess: '[patients] change patient success',
  changePatientFailure: '[patients] change patient failure',

  deletePatientRFIDStart: '[patients] delete RFID start',
  deletePatientRFIDSuccess: '[patients] delete RFID success',
  deletePatientRFIDFailure: '[patients] delete RFID failure',

  deletePatientStart: '[patients] delete start',
  deletePatientSuccess: '[patients] delete success',
  deletePatientFailure: '[patients] delete failure',
};

export const actionTypes = {
  createPatients: '[patients] create',
  setPatientAvatar: '[patients] set avatar',
  setPatientSignature: '[patients] set signature',
  deletePatientAvatar: '[patients] delete avatar',
  deletePatientSignature: '[patients] delete signature',
  getPatients: '[patients] get data',
  getCountPatients: '[patients] get count',
  importPatients: '[patients] import',
  changePatient: '[patients] change patient',
  deletePatientRFID: '[patients] delete RFID',
  deletePatient: '[patients] delete',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.createPatientsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createPatientsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createPatientsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setPatientAvatarStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setPatientAvatarSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setPatientAvatarFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setPatientSignatureStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setPatientSignatureSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setPatientSignatureFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePatientAvatarStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deletePatientAvatarSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deletePatientAvatarFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePatientSignatureStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deletePatientSignatureSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deletePatientSignatureFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getPatientsStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getPatientsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getPatientsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getCountPatientsStart](state) {
    state.count = null;
  },
  [mutationTypes.getCountPatientsSuccess](state, payload) {
    state.count = payload;
  },
  [mutationTypes.getCountPatientsFailure]() {},

  [mutationTypes.importPatientsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.importPatientsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.importPatientsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changePatientStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changePatientSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changePatientFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePatientRFIDStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deletePatientRFIDSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deletePatientRFIDFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.deletePatientStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deletePatientSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deletePatientFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.createPatients](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createPatientsStart);
      patientsApi
        .createPatients(credentials)
        .then((response) => {
          context.commit(mutationTypes.createPatientsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createPatientsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getPatients](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPatientsStart);
      patientsApi
        .getPatients(credentials)
        .then((response) => {
          context.commit(mutationTypes.getPatientsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPatientsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getCountPatients](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCountPatientsStart);
      patientsApi
        .getCountPatients(credentials)
        .then((response) => {
          context.commit(mutationTypes.getCountPatientsSuccess, response.data.total);
          resolve(response.data.total);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCountPatientsFailure);
          reject(result);
        });
    });
  },
  [actionTypes.setPatientAvatar](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setPatientAvatarStart);
      patientsApi
        .setPatientAvatar(credentials)
        .then((response) => {
          context.commit(mutationTypes.setPatientAvatarSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setPatientAvatarFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.setPatientSignature](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setPatientSignatureStart);
      patientsApi
        .setPatientSignature(credentials)
        .then((response) => {
          context.commit(mutationTypes.setPatientSignatureSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setPatientSignatureFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.deletePatientAvatar](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deletePatientAvatarStart);
      patientsApi
        .deletePatientAvatar(credentials)
        .then((response) => {
          context.commit(mutationTypes.deletePatientAvatarSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePatientAvatarFailure, result.response.data.message);
        });
    });
  },
  [actionTypes.deletePatientSignature](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deletePatientSignatureStart);
      patientsApi
        .deletePatientSignature(credentials)
        .then((response) => {
          context.commit(mutationTypes.deletePatientSignatureSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePatientSignatureFailure, result.response.data.message);
        });
    });
  },
  [actionTypes.importPatients](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.importPatientsStart);
      patientsApi
        .importPatients(credentials)
        .then((response) => {
          context.commit(mutationTypes.importPatientsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.importPatientsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.changePatient](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changePatientStart);
      patientsApi
        .changePatient(credentials)
        .then((response) => {
          context.commit(mutationTypes.changePatientSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changePatientFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.deletePatientRFID](context, {id}) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deletePatientRFIDStart);
      patientsApi
        .deletePatientRFID(id)
        .then((response) => {
          context.commit(mutationTypes.deletePatientRFIDSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePatientRFIDFailure, result.response.data.message);
        });
    });
  },
  [actionTypes.deletePatient](context, {id}) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deletePatientStart);
      patientsApi
        .deletePatient(id)
        .then((response) => {
          context.commit(mutationTypes.deletePatientSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePatientFailure, result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
