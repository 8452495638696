<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(
      v-if="isVisible"
      :classes="'close-outside'"
      @close="closeModal"
    )
      template(v-slot:body)
        ul.exam-card-modal
          li.exam-card-modal__el.text-color--black
            .row.flex-column.g-4
              .col
                .d-flex.align-items-center.text-color--blue.border-bottom.py-4.justify-content-between.bg-header
                  span.me-3.color-black {{data.exam_type_name}}
                  span.me-3 №{{data.exam_number}}
              .col-14
               .row
                .col-7
                 .text-color--blue.mb-2 Пользователь
                 .userData
                  .userData__img
                   .d-flex.align-items-center
                    app-avatar(
                      :src="data.patient_avatar"
                      :viewPhoto="true"
                      @openGallery="openGallery"
                      :classes="'large'"
                    )
                    ul.ms-20
                      li {{ data.email ? data.email : data.patient_login }}
                .col-7.d-flex.justify-content-end.mt-2(v-if="data.signature")
                 app-signature(
                  :sample="data.signature_sample"
                  :instance="data.signature"
                  :theme="'shadow'"
                  :classes="'midle'"
                  @openGallery="openGallery"
                 )
              .col
                <hr>
              .col
                .text-color--blue.mb-2 Сон
                span.orange(v-if="data.sleep_info=='no'") Не выспался
                span(v-if="data.sleep_info=='yes'") Выспался
                span(v-if="!data.sleep_info") -
              .col
                <hr>

              .col
                .d-flex.align-items-center.text-color--blue.no-round
                  span.me-4 {{ toLocalDate(data.exam_datetime) || data.exam_date }}
                  span.me-3 {{ toLocalTime(data.exam_datetime) || data.exam_time }}

                  app-status(:type="'exam'" :data="data" :line="false")

              //- .col(v-if="type !== 'patient-detail'")
              //-   span {{ data.patient_code }} {{ data.patient_full_name }}

              .col
                .row.align-items-center.g-4
                  .col-auto
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="pulse")
                      span(v-if="data.bp_high && data.bp_low")
                       span(:class="bpHighColor(data.bp_high)") {{ data.bp_high }} /
                       span(:class="bpLowColor(data.bp_low)") {{ data.bp_low }}
                      span(v-else) &#8212;

                  .col-auto
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="cardio")
                      span(v-if="data.heart_rate")
                       span {{data.heart_rate}}
                      span(v-else) &#8212;

                  .col-auto
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="temp")
                      span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
                      span(v-else) &#8212;

                  .col-auto
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="breathalyzer")
                      span(v-if="data.alcohol || data.alcohol === 0" :class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
                      span(v-else) &#8212;




              .col(v-if="data.health_complaint")
                hr
                .text-color--blue.mb-2 Жалоба на состояние здоровья
                span {{ data.health_complaint }}
              .col
               hr
               .airParamMob
                .airParamMob__half
                 span Температура помещения
                 span {{data.area_temp + 'C'}}
                .airParamMob__half
                 span Влажность воздуха
                 span {{data.area_humidity + '%'}}
          li.exam-card-modal__el.text-color--black.no-border
            .row.flex-column.g-4
              .col
                .text-color--blue.mb-2 Серийный номер устройстваdsd
                span {{ data.device_serial }}

              .col(v-if="currentUser.role === v.user.roles.admin && $route.name !== 'user-detail'")
                .text-color--blue.mb-2 Владелец устройства
                span {{ data.device_owner_full_name }}


          li.exam-card-modal__el.text-color--black.no-border.mt-0.pt-0
            .row.flex-column.g-4
              .col(v-if="type !== 'patient-detail'")
                //- .text-color--blue.mb-3 Логин пользователя
                //- .d-flex.align-items-center
                //-   app-avatar(:src="data.patient_avatar")
                //-   .ms-3 {{ data.patient_login }}

              .col
                .text-color--blue.mb-2 Координаты
                span(v-if="data.lat && data.long") {{ data.lat }}, {{ data.long }}
                span.text-color--blue(v-else) Нет

              .col
                .text-color--blue.mb-2 Состояние корпуса
                template(v-if="data.case_opened")
                  .d-flex.align-items-center.text-color--danger
                    span.me-2 Вскрыт
                    span.d-flex
                      app-svg-icon(name="attention")
                template(v-else)
                  span Не вскрыт

              .col
                .text-color--blue.mb-2 Статус
                span.text-color--success(v-if="data.exam_completed") Завершен
                span.text-color--blue(v-else) Не завершен
              .col
                .text-color--blue.mb-2 Контроль целостности
                span(v-if="data.device_integrity!=null") {{data.device_integrity?'Пройден':'Не пройден'}}
                span(v-else) &#8212;
              .col
                .text-color--blue.mb-2 Поверка
                span {{data.device_verified?'Поверено':'Не поверено'}}
              .col
                span.text-color--gray-light(v-if="data.ntp_sync") Синхронизировано
                span.text-color--accent(v-else) Не синхронизировано

          li.exam-card-modal__el.text-color--black
            .row.flex-column.g-4
              .col
                .text-color--blue.mb-2 Медработник
                span(v-if="data.doctor_fullname") {{ data.doctor_fullname }}
                span(v-else) Нет

              .col(v-if="data.number_kep")
                button(
                  type="button"
                  @click.prevent="openCertificate(data)"
                  class=['text-color--blue-dark text-decoration--underline link--accent']
                ) Подписано электронной подписью

          li.exam-card-modal__el
            .row.flex-column.g-4
              .col(v-if="type !== 'patient-detail'")
                router-link(
                  :to="{name: 'patient-detail', params: {id: data.patient_code}}"
                  class=['d-flex align-items-center', 'link--accent']
                )
                  //- span.me-3.flex-shrink-0
                  //-   app-svg-icon(name="search")
                  //- span Поиск по пользователю

              .col(v-if="currentUser.role === v.user.roles.admin && type !== 'user-detail'")
                router-link(
                  :to="{name: 'user-detail', params: {id: data.device_owner_id}}"
                  class=['d-flex align-items-center', 'link--accent']
                )
                  span.me-3.flex-shrink-0
                    app-svg-icon(name="search")
                  span Поиск по владельцу

              .col(v-if="data.has_video || data.exam_video")
                button(
                  type="button"
                  @click="openModal('video', data)"
                  class=['link--accent']
                )
                  span.d-flex.align-items-center
                    span.flex-shrink-0.me-3
                      app-svg-icon(name="video")
                    span Просмотреть видеозапись

              .col(v-if="data.lat && data.long")
                button(
                  type="button"
                  @click="type === 'map' ? showToMap(data) : openModal('map', data)"
                  class=['link--accent']
                )
                  span.d-flex.align-items-center
                    span.flex-shrink-0.me-3
                      app-svg-icon(name="map-point")
                    span Посмотреть на карте
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import variables from '@/helpers/variables';
import {toLocalDate, toLocalTime} from '@/helpers/utils';

import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
import AppSignature from '@/components/profile/common/Signature';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppExamDetailMobile',
  components: {
    AppStatus,
    AppAvatar,
    AppSignature,
    AppSvgIcon,
    AppModal,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: false,
      default: 'exams',
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.settings.isLoading,
      settings: (state) => state.settings.data,
      error: (state) => state.settings.error,
    }),
    baseUrl() {
      return this.settings.s3_host || '';
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    openModal(slug, data) {
      this.closeModal();
      this.$emit('openModal', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    showToMap(data) {
      this.closeModal();

      const mapContainer = document.querySelector('.ymap-container');
      if (mapContainer) mapContainer.scrollIntoView({behavior: 'smooth'});

      this.$emit('map', data);
    },
    closeModal() {
      this.$emit('close');
    },
    bpHighColor(key) {
      return {
        yellowText: (key > 140 && key < 160) || (key > 60 && key < 100),
        redText: key >= 160,
      };
    },
    bpLowColor(key) {
      return {
        yellowText: (key >= 90 && key <= 99) || key < 60,
        redText: key >= 100,
      };
    },
    alcoholColor(key) {
      return {
        yellowText: key > 0 && key <= 0.16,
        redText: key > 0.16,
      };
    },
    bpTemp(key) {
      return {
        yellowText: key > 37 && key <= 37.9,
        redText: key >= 38,
      };
    },
  },
};
</script>

<style lang="scss">
.exam-card-modal {
  @include font-size(get($text-sizes, small));

  &__el {
    & + .exam-card-modal__el {
      padding-top: $spacer * 1.6;
      margin-top: $spacer * 1.6;
      border-top: $border-width solid get($colors, gray-hover);
    }
    .icon {
      &__search,
      &__video,
      &__map-point,
      &__pulse,
      &__cardio,
      &__temp,
      &__breathalyzer {
        fill: get($colors, blue);
      }
      &__video {
        width: $spacer * 2;
      }
    }
  }
}
.border-bottom {
  border-bottom: 1px solid #c5cbe1;
}
.color-black {
  color: #000;
  font-weight: 400;
}
.no-round {
  svg {
    display: none;
  }
}
.bg-header {
  background: url('./../../../../assets/img/pins.svg') 0 40% no-repeat;
  padding-left: 42px;
}
hr {
  background-color: #c5cbe1 !important;
}
.airParamMob {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  font-size: 10px;

  margin-top: 15px;
  /* Basic/Blue_2 */

  color: #989fb8;
}
.airParamMob__half {
  width: 50%;
  background: #e7f0fe;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 13px;
  span {
    display: block;
  }
  &:first-child {
    border-right: 1px solid #fff;
  }
}
.no-border {
  border: none !important;
}
</style>
