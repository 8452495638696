<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
      .col-14
        AppNavDocs
        .NavChart.NavNoticesAdmin.mt-0.mb-0
            span.button(@click="selectDocType('health_certificate')" :class="{active: this.document_type == 'health_certificate'}") О здоровье
            span.tabs-nav__button.button(@click="selectDocType('injury_certificate')" :class="{active: this.document_type == 'injury_certificate'}") О травме
            span.tabs-nav__button.button(@click="selectDocType('hti_certificate')" :class="{active: this.document_type == 'hti_certificate'}") Результаты ХТИ
            span.tabs-nav__button.button(@click="selectDocType('medical_exam_conclusion')" :class="{active: this.document_type == 'medical_exam_conclusion'}") Медосмотры
            span.tabs-nav__button.button(@click="selectDocType('medical_exam_certificate')" :class="{active: this.document_type == 'medical_exam_certificate'}") Акт МО
            span.tabs-nav__button.button(@click="selectDocType('client_request')" :class="{active: this.document_type == 'client_request'}") Обращение клиента

  .profile-patients__content.mt-2.position-relative
    transition-group(name="translucent")
      app-loading(v-if="isLoading" key="loading")
      app-error-message(v-if="error" :message="error")
      div(key="oneadad")
        div(v-if="incomingMedDocs && incomingMedDocs.length" key="hasIncomingMedDocs")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.gx-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    @click="sortingPatients(column.key)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span.text-overflow(:title="column.caption") {{ column.caption }}
                      span.d-flex.ms-3
                        app-svg-icon(name="down-arrow")

            ul.profile-table__body#incoming
              li.profile-table__row.profile-table__row--notice(v-for='item in incomingMedDocs')
                app-admin-notice-incoming(
                  :data="item"
                  @open="openModal('handlerDoc')"
                  :changeDocStatus="changeDocStatus"
                )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        div(v-else-if="!isLoading" key="NoMedDocs")
          .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-incoming(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
</template>

<script>
import {mapState} from 'vuex';

import incomingMedDocs from '@/api/incomingMedDocs';

import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppFilter from '@/components/profile/admin/docs/FilterDocs';
import AppFilterPageIncoming from '@/components/FilterPageIncoming';
import AppAdminNoticeIncoming from '@/components/profile/admin/docs/AdminNoticeIncoming';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNoticesAdmin from '@/components/profile/common/NavNoticesAdmin';
import AppNavDocs from '@/components/common/NavDocsAdmin';
import {filterMixin} from '@/mixins/filter';

import {modalsMixin} from '@/mixins/modals';
import useVuelidate from '@vuelidate/core';
export default {
  name: 'Pageincoming',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
    // AppGallery,
    AppAdminNoticeIncoming,
    AppPatientChangeAvatarModal,
    NavNoticesAdmin,
    AppNavDocs,
    AppFilterPageIncoming,
  },
  mixins: [filterMixin, modalsMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isLoading: false,
      incomingMedDocs: {},
      form: {
        file: null,
      },
      v: variables,
      isFilterOpen: false,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getIncomingDocs'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 5,
        total: 0,
      },
      filterString: '',
      document_type: 'health_certificate',
      sortKey: 'id',
      columns: [
        {
          class: 'col-auto',
          caption: '№',
          key: 'id',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Статус',
          key: 'document_status',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата и время',
          key: 'create_datetime',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Организация ',
          key: 'company_name',
          inverse: false,
        },
        {
          class: 'col-3',
          caption: 'Фио пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата выдачи',
          key: 'issue_date',
          inverse: true,
        },
        {
          class: 'col-auto',
          caption: 'Срок действия',
          key: 'expiration_date',
          inverse: true,
        },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
        addPdn: false,
        handlerDoc: false,
        handlerSelect: false,
      },
      results: [],
      successMessage: 'Успех!',
      filter: {},
      filters: {
        document_type: 'health_certificate',
      },
    };
  },
  computed: {
    ...mapState({
      patients: (state) => state.patients.data,
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getIncomingDocs();
    },
  },
  created() {
    this.getIncomingDocs();
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    changeDocStatus() {
      this.getIncomingDocs();
    },
    selectDocType(newDocType) {
      this.document_type = newDocType;
      this.getIncomingDocs();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    async getIncomingDocs() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `page=${this.currentPage - 1}&per_page=10${
        this.filterString
      }&incoming_documents_order_by=${order_by}&document_type=${this.document_type}`;
      this.isLoading = true;
      let res = await incomingMedDocs.getIncomingMedDocumentV2(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.incomingMedDocs = res.data.items;
      this.isLoading = false;
    },
    sortingPatients(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getIncomingDocs();
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getIncomingDocs();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
  },
};
</script>
<style lang="scss">
// .tabs-nav__button {
//   min-width: 210px;
// }
.profile-table__row--notice {
  .accordion__button {
    top: 21px;
  }
}
</style>
