import axios from '@/api/axios';

const createPatients = (credentials) => axios.post('/api/v1/patient/create', credentials);

const setPatientAvatar = (credentials) => axios.post('/api/v1/patient/avatar', credentials);

const updatePatientAvatar = (code, credentials) => axios.put(`/api/v2/patient/edit/${code}/`, credentials);

const archivePatientAvatar = (code, credentials) => axios.delete(`/api/v2/patient/avatar/${code}`, credentials);

const deletePatientAvatar = (credentials) => axios.post('/api/v1/patient/avatar/delete', credentials);

const setPatientSignature = (credentials) => axios.post('/api/v1/patient/signature', credentials);

const deletePatientSignature = (credentials) =>
  axios.post('/api/v1/patient/signature/delete', credentials);

const getPatients = (credentials) => axios.post('/api/v1/patient', credentials);

const getPatientsShort = (credentials) => axios.get(`/api/v2/patient/short_list${credentials}&activate=true`);

const getPatientDetail = (code) => axios.get(`/api/v2/patient/detail/${code}`);

const getPatientAvgExamsResult = (code) => axios.get(`/api/v2/patient/avg_exams_result/${code}`);

const getPatientLockStatus = (code) => axios.get(`/api/v1/patient/lock_status/${code}`);

const getPatientBorders = (code) => axios.get(`/api/v2/personal_borders/${code}`);

const getPatientExams = (code) => axios.get(`/api/v2/exams/${code}`);

const getPatientsByUser = (user_id) => axios.get(`/api/v2/users/${user_id}/patients`);

const getCountPatients = (credentials) => axios.post('/api/v1/patient/count', credentials);

const importPatients = (credentials) => axios.post('/api/v1/patient/import_csv', credentials);

const changePatient = (credentials) => axios.post('/api/v1/patient/edit', credentials);

const deletePatientRFID = (id) => axios.post(`/api/v1/patient/rfid/delete/${id}`);

const deletePatient = (id) => axios.delete(`/api/v1/patient/${id}`);

const unlockPatient = (credentials) => axios.post('/api/v1/patient/unlocking', credentials);

const blockHistory = (patient_id) => axios.get(`/api/v2/patient_blocking/?code=${patient_id}&page=0&per_page=10`);

const updatePersonalBorders = (pat_code, credentials) => axios.post(`/api/v1/patient/edit/personal_borders/${pat_code}`, credentials);

const medDocsUser = (credentials) => axios.post(`/api/v1/incoming_med_document`, credentials);

const medDocsUserNew = (patient_code) => axios.get(`/api/v2/med_doc/incomming_documents/${patient_code}/?page=0&per_page=10`);

const createPatientComment = (credentials) => axios.post(`/api/v2/patient/comment`, credentials);

const getPatientComment = (patient_code) => axios.get(`/api/v2/patient/comment?patient_code=${patient_code}&page=0&limit=50`);

const getPatientsSuggestionList = (urlParams) => axios.get(`/api/v2/patient/suggestion`, { params: urlParams});

export default {
  createPatients,
  setPatientAvatar,
  setPatientSignature,
  deletePatientAvatar,
  getPatientAvgExamsResult,
  deletePatientSignature,
  getPatients,
  getCountPatients,
  importPatients,
  changePatient,
  deletePatientRFID,
  deletePatient,
  unlockPatient,
  blockHistory,
  updatePersonalBorders,
  medDocsUser,
  createPatientComment,
  getPatientComment,
  updatePatientAvatar,
  getPatientsShort,
  getPatientDetail,
  getPatientLockStatus,
  getPatientBorders,
  getPatientsByUser,
  getPatientExams,
  medDocsUserNew,
  getPatientsSuggestionList,
  archivePatientAvatar
};
