<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-12
            .align-items-center
              app-title(:type="'large'") Настройки системы. Настройки автобота

              .row.align-items-center.g-4.borders
                ul.profile-table__body
                  li.profile-table__row(v-for="border in bordersIds" :key="border.num")
                      section(
                          class=['user-card', 'user-card-history']
                        )
                        .row.align-items-center.g-4.gy-xl-0
                          .col-3
                              div {{ border.name }}
                          .col-3
                            span.presonal-border-text от
                            input(
                                :class="['personal-border', 'form__field']"
                                type="email"
                                placeholder="от"
                                v-model="resultBorders[border.id].min_value"
                            )
                            span.presonal-border-text до
                            input(
                                :class="['personal-border', 'form__field']"
                                type="email"
                                placeholder="до"
                                v-model="resultBorders[border.id].max_value"
                            )
                          .col-2(v-if="currentUser.role !== 'CLIENT'")
                            button(
                                type="button"
                                @click="sendBorder(resultBorders[border.id])"
                                class=['text-decoration--underline text-size--small link--accent']
                            ) Сохранить

            label.form__checkbox.d-flex.w-50.check
              input(
                type="checkbox"
                name="user-type"
                value="person"
                v-model="resultBorders['device_verified'].exists"
                v-on:change="setCheckbox(resultBorders['device_verified'])"

                )
              i.form__checkbox-icon.me-2
              span.form__checkbox-caption Статус поверки устройства

            label.form__checkbox.d-flex.w-50.check
              input(
                type="checkbox"
                name="user-type"
                value="person"
                v-model="resultBorders['case_opened'].exists"
                v-on:change="setCheckbox(resultBorders['case_opened'])"
                )
              i.form__checkbox-icon.me-2
              span.form__checkbox-caption Целостность корпуса ПАК

            label.form__checkbox.d-flex.w-50.check
              input(
                type="checkbox"
                name="user-type"
                value="person"
                v-model="resultBorders['device_integrity'].exists"
                v-on:change="setCheckbox(resultBorders['device_integrity'])"
                )
              i.form__checkbox-icon.me-2
              span.form__checkbox-caption Контроль целостности ПО

            label.form__checkbox.d-flex.w-50.check
              input(
                type="checkbox"
                name="user-type"
                value="person"
                v-model="resultBorders['video_object_name'].exists"
                v-on:change="setCheckbox(resultBorders['video_object_name'])"
                )
              i.form__checkbox-icon.me-2
              span.form__checkbox-caption Видео осмотра

</template>

<script>
import AppTitle from '@/components/common/Title';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

import settingsApi from '@/api/settings';

export default {
  name: 'SystemSetupAutobotSettings',
  components: {
    AppTitle,
  },
  data() {
    return {
      user: null,
      resultBorders: {},
      bordersIds: [
        {
          id: 'bp_high',
          num: 1,
          name: 'Давление систолическое',
        },
        {
          id: 'bp_low',
          num: 2,
          name: 'Давление диастолическое',
        },
        {
          id: 'heart_rate',
          num: 3,
          name: 'ЧСС',
        },
        {
          id: 'body_temp',
          num: 4,
          name: 'Температура',
        },
        {
          id: 'alcohol',
          num: 5,
          name: 'Алкоголь',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  async created() {
    let data = await settingsApi.getAutoBotSettings();
    this.resultBorders = data.data.items.reduce(
      (acc, elm) => ({
        ...acc,
        [elm.border_type]: {
          max_value: elm.max_value,
          min_value: elm.min_value,
          id: elm.id,
          exists: elm.exists,
        },
      }),
      {}
    );
    console.log(this.resultBorders);
  },
  methods: {
    async sendBorder(data) {
      console.log(data);
      let res = await settingsApi.setAutoBotSettings(data);
      console.log(res);
    },
    async setCheckbox(data) {
      console.log(data);
      let res = await settingsApi.setAutoBotSettings(data);
      console.log(res);
    },
  },
};
</script>

<style>
.borders {
  margin-top: 30px;
  margin-left: 30px;
}

.check {
  margin-top: 20px;
  font-size: 16px;
}
</style>
